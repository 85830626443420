import React, { useState } from "react";

import { icon } from "@fortawesome/fontawesome-svg-core";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { Box, Card, Divider, Grid, Tab } from "@mui/material";

import { Modal, ModalBody } from "react-bootstrap";
import Select from "react-select";
import { currencyFormatter } from "./common/utils/currencyFormatter";

const fulfilmentOptions = [
  { label: "Partial", value: "PARTIAL" },
  { label: "complete", value: "COMPLETE" },
];

const records = [
  {
    itemName: "GROWING COMPANIES PLAN",
    description: "white version",
    quantity: 1,
    unitPrice: 300,
    unit: "per month",
  },
  {
    itemName: "CONVERSION CAMPAIGN",
    description: "12 pack",
    quantity: 2,
    unitPrice: 1500,
    unit: "per month",
  },
  {
    itemName: "MONTHLY SUPPORT",
    description: "",
    quantity: 1,
    unitPrice: 120,
    unit: "per month",
  },
];

const InvoiceBillingHistory = ({ show, hideModal }) => {
  const [fulfillment, setFulfillment] = useState(fulfilmentOptions[0]);
  return (
    <Modal show={show} onHide={hideModal} size="xl" centered>
      <Modal.Header>
        <Modal.Title>Invoice/Billing History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="card border py-3">
                <h4 className="text-center px-3"> Invoice #0082999989</h4>
                <Divider />
                <div className="row px-3 py-2">
                  <div className="col-lg-6 col md-6 col-sm-6 col-12">
                    <h6 className="mb-0">Issue Date</h6>
                    <p>
                      <strong className="text-align-center">
                        12/12/2017
                        {/* <ArrowDropDownIcon /> */}
                      </strong>
                    </p>
                  </div>
                  <div className="col-lg-6 col md-6 col-sm-6 col-12">
                    <h6 className="mb-0">Due Date</h6>
                    <p>
                      <strong>
                        12/23/2017
                        {/* <ArrowDropDownIcon /> */}
                      </strong>
                    </p>
                  </div>
                </div>
                <Divider />
                <div className="px-3 py-2">
                  <h6 className="mb-0">Notes</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque officia deleniti earum laborum nihil enim unde error a nam ut
                    dicta voluptates, soluta aspernatur necessitatibus!
                  </p>
                </div>
                <Divider sx={{ marginBottom: 2 }} />
                <div className="row px-3 mb-2">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <h6 className="mb-0">Contact</h6>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <h6 className="mb-0">JOHN COOPER</h6>
                  </div>
                </div>
                <div className="row px-3 mb-2">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <h6 className="mb-0">Company</h6>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <h6 className="mb-0">AUXENTIOUS</h6>
                  </div>
                </div>
                <div className="row px-3 mb-2">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <h6 className="mb-0">23 Main Street Springfield, IL 62701 United States</h6>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className="card border px-3 py-2">
                <table class="table table-hover ">
                  <thead className="">
                    <tr>
                      <th scope="col">DESCRIPTION</th>
                      <th scope="col">QUANTITY</th>
                      <th scope="col">UNIT PRICE</th>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.length !== 0 &&
                      records.map((row) => (
                        <tr>
                          <th scope="row">
                            {row.itemName}
                            <p>{row.description}</p>
                          </th>
                          <td>{row.quantity}</td>
                          <td>{currencyFormatter.format(row?.unitPrice)}</td>
                          <td>{currencyFormatter.format(row?.unitPrice * row?.quantity)}</td>
                          <td>
                            <EditIcon />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Divider />
                <div className="d-flex align-items-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 font-size-16">Status</h6>
                      <h6 className="bg-success border-radius-10 text-white align-items-center mb-0 px-2 py-1 ml-2 font-size-16">
                        <CheckIcon className="mr-1" />
                        Sent
                      </h6>
                    </div>
                    <div className="d-flex align-items-center mx-4">
                      <h6 className="mb-0 font-size-16 mr-2">Fulfilment</h6>
                      <Select options={fulfilmentOptions} value={fulfillment} onChange={(e) => setFulfillment(e)} classNamePrefix="react-select " />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h6 className="font-size-16 font-weight-500 mb-0 mr-2">
                      Total Amount: <span className="text-success font-weight-600 mx-2">$1,800.00</span>
                    </h6>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceBillingHistory;
