import React, { useContext, useEffect, useState } from "react";

import { Box, Card, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OrderHistoryMaster from "./OrderHistoryMaster";
import ClaimHistoryMaster from "./ClaimHistoryMaster";
import OrderHistory from "./OrderHistory";
import Billings from "./Billings";
import Invoice from "./Invoice";
import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { SEARCH_PARTS_ORDER_GET } from "../services/CONSTANTS";
import { AuthContext } from "../context/AuthContext";
import CustomizedSnackbar from "../shared/CustomSnackBar";

const MyTransactions = () => {
  const { auth } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("quote");

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
            <div>
              <h3 className="mt-4 mb-3">My Transactions</h3>
              <h4 className="font-size-14 font-weight-500 mt-3">View all your transactions at one Place</h4>
            </div>
            {/* <div>
                        <button className="btn btn-primary"> + Raise a Claim</button>
                    </div> */}
          </div>
          <div className="card border px-3">
            <Box sx={{ width: "100%" }}>
              <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={(e, tabValue) => setActiveTab(tabValue)}>
                    <Tab label={`QUOTE HISTORY`} value="quote" />
                    <Tab label={`ORDER HISTORY`} value="order" />
                    <Tab label={`BILLINGS`} value="billings" />
                    <Tab label={`DELIVERIES`} value="invoices" />
                    <Tab label={`SERVICE HISTORY`} value="claim" />
                  </TabList>
                </Box>
                <TabPanel value={activeTab}>
                  {activeTab === "quote" && <OrderHistoryMaster auth={auth} handleSnack={handleSnack} />}
                  {activeTab === "order" && <OrderHistory auth={auth} handleSnack={handleSnack} />}
                  {activeTab === "billings" && <Billings />}
                  {activeTab === "invoices" && <Invoice />}
                  {activeTab === "claim" && <ClaimHistoryMaster />}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTransactions;
