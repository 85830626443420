import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import SearchIcon from "@mui/icons-material/Search";
import TabList from "@mui/lab/TabList";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import {
    Box,
    Grid,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tab,
    Tooltip,
    Card,
} from "@mui/material";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faWifi, faSearch } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";

const ViewCustomerDetails = ({ show, hideModal, data, column }) => {
    const [tabValue, setTabValue] = useState("Overview");
    const options = [
        { value: "All Time", label: "All Time" },

        { value: "1 month", label: "1 month" },
        { value: "3 months", label: "3 months" },
        { value: "6 months", label: "6 months" },
    ];

    const CustomerOverview = () => {
        return (
            <>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                        <h4 className="font-weight-600 mt-4 mb-0 ">
                            Customer Overview
                        </h4>
                        <Select
                            className=" text-primary"
                            defaultValue={options[0]}
                            options={options}
                        />
                    </div>
                </div>
                <div className="card border px-3 py-3 ">
                    <div className="d-flex row  my-4 pb-2">
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h6>Total Order Value</h6>
                                <div className="d-flex align-items-baseline">
                                    <div className="d-flex">
                                        <a
                                            href="#"
                                            className="mr-2 text-primary font-weight-500"
                                        >
                                            <AttachMoneyOutlinedIcon
                                                style={{
                                                    fontSize: "40px",
                                                    border: "2px solid #A020F0",
                                                    borderRadius: "50%",
                                                    // padding: "1px",
                                                    backgroundColor: "#fff",
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <h4
                                        className="text-primary mb-2  d-inline-flex align-items-center"
                                        style={{ fontSize: "20px" }}
                                    >
                                        $840,820
                                        <small
                                            class="text-muted ml-2"
                                            style={{ fontSize: "inherit" }}
                                        >
                                            .84
                                        </small>
                                    </h4>
                                </div>
                                <small className="text-muted d-block ml-4 mb-1 font-italic">
                                    All Time Total Order
                                </small>
                            </div>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h6>Completed Orders</h6>
                                <div className="d-flex align-items-baseline">
                                    <div className="d-flex">
                                        <a
                                            href="#"
                                            className="mr-2 text-green font-weight-500"
                                        >
                                            <CheckCircleOutlinedIcon
                                                style={{ fontSize: "40px" }}
                                            />
                                        </a>
                                    </div>

                                    <h4
                                        className="text-primary mb-2  d-inline-flex align-items-center"
                                        style={{ fontSize: "20px" }}
                                    >
                                        860
                                        <small
                                            class="text-muted ml-2"
                                            style={{ fontSize: "inherit" }}
                                        >
                                            Items
                                        </small>
                                    </h4>
                                </div>
                                <small className="text-muted d-block ml-4 mb-1 font-italic">
                                    All Time Total Completed
                                </small>
                            </div>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h6>Cancelled Orders</h6>
                                <div className="d-flex align-items-baseline">
                                    <div className="d-flex">
                                        <a
                                            href="#"
                                            className="mr-2 text-danger font-weight-500"
                                        >
                                            <CancelOutlinedIcon
                                                style={{ fontSize: "40px" }}
                                            />
                                        </a>
                                    </div>

                                    <h4
                                        className="text-primary mb-2  d-inline-flex align-items-center"
                                        style={{ fontSize: "20px" }}
                                    >
                                        16
                                        <small
                                            class="text-muted ml-2"
                                            style={{ fontSize: "inherit" }}
                                        >
                                            Items
                                        </small>
                                    </h4>
                                </div>
                                <small className="text-muted d-block ml-4 mb-1 font-italic">
                                    All Time Total Cancelled
                                </small>
                            </div>
                        </div>
                    </div>
                    <Divider />

                    <div className="row mt-5 ">
                        {/* General Data Column */}

                        <div className="col-md-6 ml-1">
                            <h5 className="ml-5">General Data</h5>
                            <div className="row my-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Customer Number
                                </div>
                                <div className="col-md-8 text-primary font-weight-bold ">
                                    : 32
                                </div>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Customer Name
                                </div>
                                <div className="col-md-8 text-primary font-weight-bold">
                                    : JOHN BAKER
                                </div>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Address
                                </div>
                                <div className="col-md-8 text-primary font-weight-bold">
                                    : 13th Street. 47 W 13th St, New York, USA
                                </div>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Customer Group
                                </div>
                                <div className="col-md-8 text-primary font-weight-bold">
                                    : Small Mining
                                </div>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Customer Segment
                                </div>
                                <div className="col-md-8 text-primary font-weight-bold">
                                    : Mining
                                </div>
                            </div>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        {/* Transaction Data Column */}
                        <div className="col-md-5 ml-1">
                            <h5 className="ml-5">Transaction Data</h5>
                            <div className="row my-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Customer Since
                                </div>
                                <div className="col-md-8 text-light font-weight-bold">
                                    : 23 May 2029
                                </div>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Loyalty ID
                                </div>
                                <span className="col-md-8 text-primary font-weight-bold">
                                    : S123890
                                </span>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Avg Orders/MO
                                </div>
                                <span className="col-md-8 text-primary font-weight-bold">
                                    : 22
                                </span>
                            </div>
                            <div className="row mb-2 ml-5">
                                <div className="col-md-4 font-weight-bold text-muted">
                                    Current LTV
                                </div>
                                <span className="col-md-8 font-weight-bold  text-primary">
                                    : $10,993
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const TabPanelContent = () => (
        <div
            className="d-flex justify-content-center align-items-center  "
            style={{
                height: 400,
                width: "100%",
                backgroundColor: "#fff",
                marginTop: 5,
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#872ff9",
                        color: "#fff",
                    },
                }}
                rows={data}
                columns={column}
                checkboxSelection
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={100}
            />
        </div>
    );

    return (
        <div>
            <Modal
                show={show}
                onHide={hideModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="customer-details-modal-title">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between m-3">
                            <h4 className="font-weight-600 mt-4 mb-0">
                                Customer Details
                            </h4>

                            <div className="d-flex justify-content-end">
                                <div
                                    class="input-group icons border-radius-10  "
                                    style={{
                                        overflow: "hidden",
                                        border: "1px solid #872ff9",
                                        position: "relative",
                                    }}
                                >
                                    <div class="input-group-prepend">
                                        <span
                                            class="input-group-text bg-transparent border-0 pr-0 "
                                            id="basic-addon1"
                                        >
                                            <SearchIcon />
                                        </span>
                                    </div>
                                    <input
                                        type="search"
                                        class="form-control search-form-control"
                                        aria-label="Search Dashboard"
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Box
                    sx={{
                        width: "100%",
                        typography: "body1",
                        flexGrow: 1,
                        margin: 2,
                    }}
                >
                    <TabContext value={tabValue}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                            }}
                        >
                            <TabList
                                className="custom-tabs-div"
                                onChange={(e, value) => setTabValue(value)}
                            >
                                <Tab label="OVERVIEW" value="Overview" />
                                <Tab
                                    label="ORDER HISTORY"
                                    value="Order History"
                                />
                                <Tab label="ACTIVITY" value="Activity" />
                                <Tab label="ATTACHMENT" value="Attachment" />
                            </TabList>
                        </Box>

                        <TabPanel value="Overview">
                            <CustomerOverview />
                        </TabPanel>
                        <TabPanel value="Order History">
                            <TabPanelContent data={data} column={column} />

                            <TabPanelContent />
                        </TabPanel>
                        <TabPanel value="Activity">
                            <TabPanelContent data={data} column={column} />
                        </TabPanel>
                        <TabPanel value="Attachment">
                            <TabPanelContent data={data} column={column} />
                        </TabPanel>
                    </TabContext>
                </Box>
                <div className="d-flex justify-content-end ">
                    <button
                        type="button"
                        style={{ margin: "20px" }}
                        className="btn text-white bg-primary "
                        onClick={hideModal}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ViewCustomerDetails;
