import React, { useCallback, useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import $ from "jquery";
import Select from "react-select";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";

import SearchComponent from "../components/SearchComponent";
import { ReadOnlyField } from "../common/ReadOnlyField";
import { partsShipmentObj, rmaResonOptions, rmaTypeOptions, shipmentRetunTypeOptions } from "../warrantyManagement/warrantyManagementConstants";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE, SPAREPART_SEARCH_Q_OPTIONS } from "../common/constants";
import { sparePartSearch } from "../../services/searchServices";
import SearchPartListModal from "../warrantyManagement/claimMaster/SearchPartListModal";
import { API_SUCCESS } from "../../services/ResponseCode";
import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../../services/ApiCaller";
import { SHIPMENT_HEADER_MASTER_URL, SHIPMENT_PARTS_MASTER_URL } from "../../services/CONSTANTS";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

const PartsReturnDetails = ({ show, hideModal, handleSnack, shipmentHeaderId, setShipmentHeaderId, requestCreation = true }) => {
  const [shipmentData, setShipmentData] = useState({
    ...partsShipmentObj,
  });
  const [partsSearchData, setPartsSearchData] = useState([]);
  const [partsRecordData, setPartsRecordData] = useState([]);
  const [shipmentTableData, setShipmentTableData] = useState([]);
  const [isShipmentSearch, setIsShipmentSearch] = useState(false);
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [warrantyReturnId, setWarrantyReturnId] = useState(null);

  const [viewOnlyTab, setViewOnlyTab] = useState({ shipmentViewOnly: false });

  const [shipmentSearchSelector, setShipmentSearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  const [partsSearchSelector, setPartsSearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  const [shipmentRowModesModal, setShipmentRowModesModal] = useState({});

  useEffect(() => {
    if (!requestCreation && shipmentHeaderId) {
      const rUrl = `${SHIPMENT_HEADER_MASTER_URL}/${shipmentHeaderId}`;
      // const rUrl = `${WARRANTY_RETURN_MASTER_URL}/${warrantyReturnId}`;
      callGetApi(null, rUrl, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // setShipmentHeaderId(responseData.shipmentHeaderId);
          if (responseData.warrantyReturnId) {
            // getWarrantyReturnDetails(responseData.warrantyReturnId);
          }

          setViewOnlyTab({
            // returnReqViewOnly: true,
            // partsViewOnly: true,
            shipmentViewOnly: true,
            // reportViewOnly: false,
          });

          // return type value set
          const _returnType = shipmentRetunTypeOptions.find((obj) => obj.value === responseData.returnType);

          // rma type value set
          const _rmaType = rmaTypeOptions.find((obj) => obj.value === responseData.rmaType);

          // rma type value set
          const _rmaReason = rmaResonOptions.find((obj) => obj.value === responseData.rmaReason);
          setShipmentData({
            ...responseData,
            returnType: _returnType || "",
            rmaType: _rmaType || "",
            rmaReason: _rmaReason || "",
          });
          shipmentHeaderId(responseData.shipmentHeaderId);
        }
      });
    }
  }, [warrantyReturnId, requestCreation]);

  // shipment header text change
  const handleShipmentextChange = (e) => {
    const { name, value } = e.target;
    setShipmentData({ ...shipmentData, [name]: value });
  };

  // shipment select & date change
  const handleShipmentSelectChange = (e, keyName) => {
    setShipmentData({ ...shipmentData, [keyName]: e });
  };

  // Consumable Search
  const handlePartsSearchClick = async (type, shipmentSearch = false) => {
    $(".scrollbar").css("display", "none");
    // console.log("handleQuerySearchClick", querySearchSelector);
    var searchStr = "";
    const tempSearchSelector = shipmentSearch ? [...shipmentSearchSelector] : [...partsSearchSelector];
    tempSearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await sparePartSearch(searchStr);
        // console.log("search Query Result :", res);
        setPartsSearchData(res);
        setIsShipmentSearch(shipmentSearch);
        setSearchResultOpen(true);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // Close SparePart search modal
  const handleSearchResClose = () => {
    setSearchResultOpen(false);
  };

  const shipmentColumns = [
    {
      field: "shipmentPartNumber",
      headerName: "Part Number",
      flex: 1,
    },
    {
      field: "shipmentPartDescription",
      headerName: "Part Descritpion",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Requested Quantity",
      flex: 1,
      editable: true,
    },
    {
      field: "analysis",
      headerName: "Analysis",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Known To be Faulty", value: "KNOWN_TO_BE_FAULTY" },
        { label: "Suspected", value: "SUSPECTED" },
        { label: "Without any fault", value: "WITHOUT_ANY_FAULT" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = shipmentRowModesModal[row.shipmentPartId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleShipmentSaveClick(row.shipmentPartId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleShipmentCancelClick(row.shipmentPartId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleShipmentRowEditClick(row.shipmentPartId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleShipmentDeleteClick(row.shipmentPartId)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleShipmentRowModesModelChange = (newRowModesModel) => {
    setShipmentRowModesModal(newRowModesModel);
  };

  const handleShipmentRowEditStart = (params, event) => {
    console.log(params);
    event.defaultMuiPrevented = true;
  };

  const handleShipmentRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleShipmentRowEditClick = (shipmentPartId) => () => {
    setShipmentRowModesModal({
      ...shipmentRowModesModal,
      [shipmentPartId]: { mode: GridRowModes.Edit },
    });
  };

  const handleShipmentSaveClick = (shipmentPartId) => () => {
    setShipmentRowModesModal({
      ...shipmentRowModesModal,
      [shipmentPartId]: { mode: GridRowModes.View },
    });
  };

  const handleShipmentDeleteClick = (shipmentPartId) => () => {
    callDeleteApi(null, `${SHIPMENT_PARTS_MASTER_URL}/${shipmentPartId}`, (response) => {
      if (response.status === API_SUCCESS) {
        setShipmentTableData(shipmentTableData.filter((row) => row.shipmentPartId !== shipmentPartId));
      }
    });
  };

  const handleShipmentCancelClick = (shipmentPartId) => () => {
    setShipmentRowModesModal({
      ...shipmentRowModesModal,
      [shipmentPartId]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = shipmentTableData.find((row) => row.shipmentPartId === shipmentPartId);
    if (editedRow.isNew) {
      setShipmentTableData(shipmentTableData.filter((row) => row.shipmentPartId !== shipmentPartId));
    }
  };

  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setPartsRowModesModel(newRowModesModel);
  // };

  const processShipmentRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: true };

        callPutApi(null, `${SHIPMENT_PARTS_MASTER_URL}/${newRow.shipmentPartId}`, updatedRow, (response) => {
          if (response.status === API_SUCCESS) {
            handleSnack("success", "Parts updated successfully");
            setShipmentTableData(
              shipmentTableData.map((row) => (row.shipmentPartId === updatedRow.shipmentPartId ? { ...updatedRow, isNew: undefined } : row))
            );
            resolve(response.data);
          } else {
            handleSnack("error", "Parts details could not be updated");
            resolve(oldRow);
          }
        });

        resolve(updatedRow);
      }),
    [shipmentTableData]
  );

  // go to the shipemnt report tab from shippment tab
  const handleAddUpdateShipmentDetails = () => {
    if (viewOnlyTab.shipmentViewOnly) {
      hideModal();
    } else {
      const rUrl = SHIPMENT_HEADER_MASTER_URL;
      const _shipmentParts = shipmentTableData.map((row) => row.shipmentPartId);
      const rObj = {
        ...shipmentData,
        warrantyReturnId: warrantyReturnId,
        returnType: shipmentData.returnType?.value || "INTRA_COMPANY",
        rmaType: shipmentData.rmaType?.value || "STANDARD",
        rmaReason: shipmentData.rmaReason?.value || "",
        shipmentParts:
          shipmentData["shipmentParts"] && shipmentData["shipmentParts"].length > 0
            ? [...shipmentData["shipmentParts"], ..._shipmentParts]
            : [..._shipmentParts],
      };
      if (shipmentHeaderId) {
        callPutApi(null, `${rUrl}/${shipmentHeaderId}`, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            handleSnack("success", "Shipment Details Updated successfully.");
            setViewOnlyTab({ ...viewOnlyTab, shipmentViewOnly: true });
            hideModal();
          } else {
            handleSnack("info", "Something went wrong.");
          }
        });
      } else {
        callPostApi(null, `${rUrl}`, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Shipment Details Created successfully.");
            setViewOnlyTab({ ...viewOnlyTab, shipmentViewOnly: true });
            setShipmentHeaderId(responseData.shipmentHeaderId);
            hideModal();
          } else {
            handleSnack("info", "Something went wrong.");
          }
        });
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5>Return Details</h5>
            {viewOnlyTab.shipmentViewOnly && (
              <button className="btn bg-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, shipmentViewOnly: false })}>
                Edit
              </button>
            )}
          </div>
          <div className="card border px-3 py-2">
            {!viewOnlyTab.shipmentViewOnly ? (
              <div className="row input-fields">
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RETURN NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="returnNumber"
                      disabled
                      placeholder="Auto generated"
                      value={shipmentData.returnNumber}
                      onChange={handleShipmentextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RETURN TYPE</label>
                    <Select
                      className="text-primary"
                      options={shipmentRetunTypeOptions}
                      onChange={(e) => handleShipmentSelectChange(e, `returnType`)}
                      value={shipmentData.returnType}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RMA TYPE</label>
                    <Select
                      className="text-primary"
                      options={rmaTypeOptions}
                      onChange={(e) => handleShipmentSelectChange(e, `rmaType`)}
                      value={shipmentData.rmaType}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RMA REASON</label>
                    <Select
                      className="text-primary"
                      options={rmaResonOptions}
                      onChange={(e) => handleShipmentSelectChange(e, `rmaReason`)}
                      value={shipmentData.rmaReason}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RMA NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="rmaNumber"
                      placeholder="RMA Number"
                      value={shipmentData.rmaNumber}
                      onChange={handleShipmentextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SHIPED ON</label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          // maxDate={new Date()}
                          closeOnSelect
                          value={shipmentData.shippedOn}
                          onChange={(e) => handleShipmentSelectChange(e, "shippedOn")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                style: FONT_STYLE,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">TRACKING NUMBER</label>
                    <input
                      type="number"
                      className="form-control border-radius-10 text-primary"
                      name="trackingNumber"
                      placeholder="Tracking Number"
                      value={shipmentData.trackingNumber}
                      onChange={handleShipmentextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SENDER LOCATION</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="senderLocation"
                      placeholder="Sender Location"
                      value={shipmentData.senderLocation}
                      onChange={handleShipmentextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RECEIVER LOCATION</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="returnNumber"
                      placeholder="Recevier Location"
                      value={shipmentData.receiverLocation}
                      onChange={handleShipmentextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RECEIVER ADDRESS</label>
                    <textarea
                      className="form-control border-radius-10 text-primary"
                      name="receiverAddress"
                      value={shipmentData.receiverAddress}
                      cols="30"
                      rows="2"
                      placeholder="Receiver Address..."
                      onChange={handleShipmentextChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mt-2">
                <ReadOnlyField label="RETURN NUMBER" value={shipmentData.returnNumber} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RETURN TYPE" value={shipmentData.returnType?.label} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RMA TYPE" value={shipmentData.rmaType?.label} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RMA REASON" value={shipmentData.rmaReason?.label} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RMA NUMBER" value={shipmentData.rmaNumber} className="col-md-3 col-sm-3" />
                <ReadOnlyField
                  label="SHIPED ON"
                  value={<Moment format="DD/MM/YYYY">{shipmentData.shippedOn}</Moment>}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField label="TRACKING NUMBER" value={shipmentData.trackingNumber} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="SENDER LOCATION" value={shipmentData.senderLocation} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RECEIVER LOCATION" value={shipmentData.receiverLocation} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="RECEIVER ADDRESS" value={shipmentData.receiverAddress} className="col-md-12 col-sm-12" />
              </div>
            )}
          </div>
          <div className="card border px-2 py-2">
            <div className="row align-items-center">
              <div className="col-8">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex mr-3 col-auto pl-0" style={{ whiteSpace: "pre" }}>
                    <h5 className="mr-2 mb-0 text-black">
                      <span>Shipment Items Table</span>
                    </h5>
                  </div>
                  <SearchComponent
                    querySearchSelector={shipmentSearchSelector}
                    setQuerySearchSelector={setShipmentSearchSelector}
                    // clearFilteredData={clearFilteredData}
                    handleSnack={handleSnack}
                    searchAPI={sparePartSearch}
                    searchClick={handlePartsSearchClick}
                    options={SPAREPART_SEARCH_Q_OPTIONS}
                    background={"white"}
                    type=""
                    buttonText="ADD PART"
                    isShipmentSearch={true}
                  />
                </div>
              </div>
            </div>
            <DataGrid
              sx={GRID_STYLE}
              getRowId={(row) => row.shipmentPartId}
              rows={shipmentTableData}
              autoHeight
              columns={shipmentColumns}
              editMode="row"
              rowModesModel={shipmentRowModesModal}
              onRowModesModelChange={handleShipmentRowModesModelChange}
              onRowEditStart={handleShipmentRowEditStart}
              onRowEditStop={handleShipmentRowEditStop}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={(error) => console.log(error)}
              processRowUpdate={processShipmentRowUpdate}
              pageSizeOptions={[5, 10, 50, 100]}
            />
          </div>

          <div className="row mt-2 mb-2" style={{ justifyContent: "right" }}>
            <button className="btn bg-primary text-white mx-3" onClick={handleAddUpdateShipmentDetails}>
              {viewOnlyTab.shipmentViewOnly ? "Close" : "Save & Close"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {searchResultOpen && (
        <SearchPartListModal
          show={searchResultOpen}
          hideModal={handleSearchResClose}
          masterData={partsSearchData}
          // claimOrderId={claimOrderId}
          // partsRecordData
          setRelatedPartsRecords={isShipmentSearch ? setShipmentTableData : setPartsRecordData}
          isShipmentSearch={isShipmentSearch}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default PartsReturnDetails;
