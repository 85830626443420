import React, { useEffect, useState } from "react";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { Divider, Grid } from "@mui/material";

import { Modal } from "react-bootstrap";

import ImageMagnifier from "./ImageMagnifier";
import { partsSearch } from "../../services/searchServices";
import { PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, PARTS_ADD_TO_CART_POST, PARTS_DELETE_PARTS_FROM_CART_DELETE } from "../../services/CONSTANTS";
import { callDeleteApi, callPostApi } from "../../services/ApiCaller";
import { API_SUCCESS } from "../../services/ResponseCode";
import Moment from "react-moment";

const PartCartItemDetails = ({
  show,
  hideModal,
  partItem = null,
  cartItems = null,
  setStaper,
  partsRecord = [],
  setPartsRecord,
  portfolioCarts,
  setMouseDownDirection,
  handleSnack,
  handleShowCartInfoModal,
}) => {
  const [rowData, setRowData] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (partItem) {
      getPartDetails(partItem);
    }
  }, [partItem]);

  const getPartDetails = async (partItem) => {
    partsSearch(`partNumber:${partItem.partNumber}`).then((result) => {
      if (result && result.length > 0) {
        setRowData({ ...result[0], ...partItem });
        const _images = result[0];
        let _selectedImg = "";
        if (partItem.sparepartImages && partItem.sparepartImages.length > 0) {
          _selectedImg = partItem["sparepartImages"].find((item) => item === _images?.sparepartImages[0]);
        }
        setSelectedImg(_selectedImg || "../assets/images/partDefaultImage.png");
        // setSelectedImg(
        //   _images?.sparepartImages && _images?.sparepartImages.length > 0
        //     ? _images?.sparepartImages[0] || "../assets/images/partDefaultImage.png"
        //     : ""
        // );
      }
    });
  };

  // update cart quantity
  const updateCartValue = async (value, itemrow) => {
    if (cartItems) {
      const partsCartRes = cartItems["parts"];
      let row = partsCartRes.find((obj) => obj?.partNumber === itemrow?.partNumber);

      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
        const rObj = {
          // partsCartId: cartItems?.cartId,
          cartId: cartItems?.cartId,
          partsQuantity: {
            [String(row["partsId"])]: value,
          },
        };

        if (value == 0) {
          let dltRUrl = `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${row["partsId"]}`;
          callDeleteApi(null, dltRUrl, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("info", `Part Number ${itemrow?.partNumber} removed from cart`);
              setRowData({ ...rowData, quantity: value });
              setStaper((pre) => pre + 1);
            }
          });
        } else {
          callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              setRowData({ ...rowData, quantity: value });
              handleSnack("success", `Part Number ${itemrow?.partNumber} updated into cart`);
              setStaper((pre) => pre + 1);
            }
          });
        }
      } else {
        let _partRecords = [...partsRecord];
        let row = _partRecords.find((obj) => obj?.id === itemrow?.id);
        const exists = !!row;
        if (exists) {
          row = { ...row, quantity: value };
        }
        setRowData(row);

        _partRecords = _partRecords.map((obj) => (obj?.id === row?.id ? row : obj));
        setCount(count + 1);
        setPartsRecord(_partRecords);
      }
    } else {
      let _partRecords = [...partsRecord];
      let row = _partRecords.find((obj) => obj?.id === itemrow?.id);
      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
      }
      setRowData(row);
      _partRecords = _partRecords.map((obj) => (obj?.id === row?.id ? row : obj));
      setCount(count + 1);
      setPartsRecord(_partRecords);
    }
  };

  // cart increase-decrease
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row.quantity + (1 || 1);
        break;
      case "down":
        next = row.quantity - (1 || 1) >= 0 ? row.quantity - (1 || 1) : 0;
        break;
      default:
        next = row.quantity;
        break;
    }
    updateCartValue(next, row);
    if (next !== 0) {
    }
  };

  // add part item into Cart
  const handleAddPartIntoCart = async (row) => {
    let rUrl = `${PARTS_ADD_TO_CART_POST}`;

    let rObj = {
      partsQuantity: {
        [String(row.id)]: row?.quantity,
      },
    };
    if (cartItems) {
      rObj["partsCartId"] = cartItems?.cartId;
    }

    // if (cartItems) {
    //   rUrl = `${rUrl}parts_cart_id=${cartItems?.cartId}&`;
    // }
    // rUrl = `${rUrl}ids=${row?.id}`;
    await callPostApi(null, `${rUrl}`, {}, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        handleSnack("success", "Part added into Cart successfully.");
        setStaper((pre) => pre + 1);
        handleShowCartInfoModal();
        // handleAddQuantityPart(responseData, row);
      }
    });
  };

  // change the quantity of added cart item
  const handleAddQuantityPart = (responseData, row) => {
    const cartParts = responseData["parts"];
    const highestPartsId = Math.max(...cartParts.map((part) => part.partsId));

    const rObj = {
      // partsCartId: responseData.cartId,
      cartId: responseData.cartId,
      partsQuantity: {
        [String(highestPartsId)]: row?.quantity,
      },
    };

    callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
      if (response.status === API_SUCCESS) {
        handleSnack("success", "Part added into Cart successfully.");
        setStaper((pre) => pre + 1);
        handleShowCartInfoModal();
      }
    });
  };

  // add item into cart
  const handleAddToCartItem = (row) => {
    if (!Number(row?.availability)) {
      handleSnack("info", `Part ${row.partNumber} is not available right now.`);
      return;
    }

    if (portfolioCarts.length !== 0) {
      handleSnack("info", "There are some services left in your cart");
      return;
    }

    if (row.quantity < 1) {
      handleSnack("info", "Quantity should be greater than 0");
      return;
    }

    if (cartItems) {
      const partCartItem = cartItems["parts"];
      let partExistsInCart = partCartItem.some((obj) => obj.partNumber === row.partNumber);
      if (partExistsInCart) {
        handleSnack("info", `Part ${row.partNumber} is already exits in your cart`);
        return;
      }
      handleAddPartIntoCart(row);
    }
  };

  return (
    <Modal show={show} onHide={hideModal} centered size="lg">
      <Modal.Body>
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-7 col-12">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                {rowData?.sparepartImages &&
                  rowData?.sparepartImages.length > 0 &&
                  rowData?.sparepartImages.map((imgsrc) => (
                    <div className="card border p-1 cursor" onClick={() => setSelectedImg(imgsrc)}>
                      <img src={imgsrc || "../assets/images/partDefaultImage.png"} alt="Part Image" />
                    </div>
                  ))}
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-12">
                <ImageMagnifier src={selectedImg} />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-12" style={{ borderLeft: "1px solid #cfcece" }}>
            <h4 className="mb-0">{rowData?.partNumber}</h4>
            <p className="mb-0">{rowData?.partDescription}</p>
            <div className="form-group mt-2">
              <label className="mb-0">Quantity</label>

              <div className="d-flex align-items-center">
                <div className="input-number">
                  <button
                    onClick={(e) => {
                      // handleButtonClick(e);
                      handleButtonChange("down", rowData);
                    }}
                    onMouseDown={() => setMouseDownDirection("down", rowData)}
                    onMouseOut={() => setMouseDownDirection(null)}
                    onMouseUp={() => setMouseDownDirection(null)}
                    disabled={rowData?.quantity === 0}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    step={1}
                    value={rowData?.quantity}
                    readOnly
                    // onChange={handleChange}
                  />
                  <button
                    onClick={(e) => {
                      // handleButtonClick(e);
                      handleButtonChange("up", rowData);
                    }}
                    onMouseDown={() => setMouseDownDirection("up", rowData)}
                    onMouseUp={() => setMouseDownDirection(null)}
                    onMouseOut={() => setMouseDownDirection(null)}
                    disabled={rowData?.quantity === rowData?.availableItems}
                  >
                    +
                  </button>
                </div>
                <button className="btn bg-primary text-white cursor py-1 mx-2" onClick={() => handleAddToCartItem(rowData)}>
                  <AddShoppingCartIcon />
                  {cartItems && cartItems["parts"].some((obj) => obj.partNumber === rowData?.partNumber) ? "Added into Cart" : "Add To Cart"}
                  {/* {!cartParts.some((obj) => obj?.id === rowData?.id) ? "Add To Cart" : "Added into Cart"} */}
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <p className={`${Number(rowData?.availability) ? "green-dot" : "red-dot"}`}></p>
                <p className="ml-1">{Number(rowData?.availability) ? "Available" : "Inaccessible"}</p>
              </div>
              <div className="d-flex align-items-center mx-2">
                <p>{rowData?.availability || 0}</p>
                <p className="ml-1">(In Stock)</p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p>Manufacturer : </p>
              <p className="ml-1">{rowData?.manufacturer}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p>Model : </p>
              <p className="ml-1">{rowData?.model}</p>
            </div>
            <div className="d-flex align-items-center mt-1">
              <p>Year of Manufacturer : </p>
              {/* <p className="ml-1">{rowData?.yearOfManufacture ? <Moment format="DD/MM/YYYY">{rowData.yearOfManufacture}</Moment> : "-"}</p> */}
              <p className="ml-1">{rowData?.yearOfManufacture || "-"}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PartCartItemDetails;
