import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { VisibilityOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Button, Menu, MenuItem, Tab } from "@mui/material";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import Moment from "react-moment";

import { GRID_STYLE } from "./common/constants";
import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { Get_Equipment_Datails_By_Id_GET } from "../services/CONSTANTS";
import LoadingProgress from "./common/Loader";
import { ReadOnlyField } from "./common/ReadOnlyField";
import PdfThumbnail from "./common/PdfThumbnail";

const categoryOptions = [
  { label: "Technical self-help", value: "TECHNICAL_SELF_HELP" },
  { label: "Spec Docs", value: "SPEC_DOCS" },
  { label: "Manuals", value: "MANUALS" },
  { label: "Training", value: "TRAINING" },
];

const technicalDocsTypeOptions = [
  { label: "Technical Advice and Service Procedures", value: "TECHNICAL_ADVICE_AND_SERVICE_PROCEDURES" },
  { label: "Preventative Maintenance Schedules", value: "PREVENTATIVE_MAINTENANCE_SCHEDULES" },
  { label: "Troubleshooting", value: "TROUBLESHOOTING" },
  { label: "Adjustments and Alignments", value: "ADJUSTMENTS_AND_ALIGNMENTS" },
];

const specDocsTypeOptions = [
  { label: "Machines Specs", value: "MACHINES_SPECS" },
  { label: "HMC", value: "HMC" },
  { label: "VMC", value: "VMC" },
  { label: "EDM", value: "EDM" },
  { label: "Retention Studs", value: "RETENTION_STUDS" },
  { label: "Stop Lock Orientation", value: "STOP_LOCK_ORIENTATION" },
  { label: "Other", value: "OTHERS" },
];

const manulesDocsTypeOptions = [
  { label: "Operation", value: "OPERATION" },
  { label: "Maintenance", value: "MAINTENANCE" },
  { label: "Troubleshooting", value: "TROUBLESHOOTING" },
  { label: "Instruction", value: "INSTRUCATION" },
  { label: "Preventive Maintenance", value: "PREVENTIVE_MAINTENANCE" },
  { label: "Maintenance Drawings", value: "MAINTENANCE_DRAWINGS" },
  { label: "Peripheral Devices", value: "PERIPHERAL_DEVICES" },
  { label: "Users", value: "USERS" },
  { label: "Parts", value: "PARTS" },
  { label: "Safety", value: "SAFETY" },
  { label: "Programming", value: "PROGRAMMING" },
];

const trainingDocsType = [
  { label: "Pocket Guides", value: "POCKET_GUIDES" },
  { label: "Videos", value: "VIDEOS" },
  { label: "Training History", value: "TRAINING_HISTORY" },
  { label: "Available Credits", value: "AVAILABEL_cREDITS" },
  { label: "Class Schedules", value: "CLASS_SCHEDULES" },
];

const fileMediaRecord = [
  { id: 1, documentName: "Report A", documentType: "PDF", documentDate: "2023-01-01", staff: "Alice", status: "Complete", region: "North" },
  { id: 2, documentName: "Report B", documentType: "Word", documentDate: "2023-02-15", staff: "Bob", status: "Incomplete", region: "South" },
  { id: 3, documentName: "Report C", documentType: "Excel", documentDate: "2023-03-20", staff: "Charlie", status: "Complete", region: "East" },
  { id: 4, documentName: "Report D", documentType: "PDF", documentDate: "2023-04-25", staff: "David", status: "Incomplete", region: "West" },
  { id: 5, documentName: "Report E", documentType: "Word", documentDate: "2023-05-30", staff: "Eve", status: "Complete", region: "North" },
];

const EquipmentDetailsModal = ({ show, hideModal, recordId }) => {
  const [mediaCategory, setMediaCategory] = useState("");
  const [mediaDocsType, setMediaDocsType] = useState("");
  const [filesCategory, setFilesCategory] = useState("");
  const [filesDocsType, setFilesDocsType] = useState("");

  const [recordData, setRecordData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("general");

  const [faqEl, setFAQEl] = React.useState(null);
  const [manualsE1, setManualsE1] = useState(null);
  const [taringDocsE1, setTaringDocsE1] = useState(null);
  const [specDocsE1, setSpecDocsE1] = useState(null);
  const [technicalSelfHelpE1, setTechnicalSelfHelpE1] = useState(null);

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      let rUrl = `${Get_Equipment_Datails_By_Id_GET}${recordId}`;
      callGetApi(
        null,
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData(responseData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // open the FAQ
  const handleClickFAQ = (event) => {
    setFAQEl(event.currentTarget);
  };

  // close the FAQ
  const handleCloseFAQ = (value) => {
    setFAQEl(null);
    if (value) {
      setActiveTab("files");
    }
  };

  // open the Manuals
  const handleClickManuals = (event) => {
    setManualsE1(event.currentTarget);
  };

  // close the Manuals
  const handleCloseanuals = (value) => {
    setManualsE1(null);
    if (value) {
      setActiveTab("files");
    }
  };

  // open the Training Docs
  const handleClickTrainingDocs = (event) => {
    setTaringDocsE1(event.currentTarget);
  };

  // close the Training Docs
  const handleCloseTrainingDocs = (value) => {
    setTaringDocsE1(null);
    if (value) {
      if (value === "videos") {
        setActiveTab("media");
      } else {
        setActiveTab("files");
      }
    }
  };

  // open the Spec Docs
  const handleClickSpecDocs = (event) => {
    setSpecDocsE1(event.currentTarget);
  };

  // close the Spec Docs
  const handleCloseSpecDocs = (value) => {
    setSpecDocsE1(null);
    if (value) {
      setActiveTab("files");
    }
  };

  // open the Technical Self Help
  const handleClickTechSelfHelp = (event) => {
    setTechnicalSelfHelpE1(event.currentTarget);
  };

  // close the Technical Self Help
  const handleCloseTechSelfHelp = (value) => {
    setTechnicalSelfHelpE1(null);
    if (value) {
      setActiveTab("files");
    }
  };

  // media category change
  const handleMediaCategoryChange = (e) => {
    setMediaCategory(e);
    setMediaDocsType("");
  };

  // files category change
  const handleFilesCategoryChange = (e) => {
    setFilesCategory(e);
    setFilesDocsType("");
  };

  // view general tab
  const viewGeneralPage = () => {
    return (
      <div className="row mt-0">
        <div className="col-md-7 mr-3">
          <div className="row input-fields mt-4">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-14 font-weight-500" htmlFor="countryInput">
                  PRODUCT NUMBER
                </label>
                <input
                  type="text"
                  className="form-control border-radius-10 font-size-13"
                  id="countryInput"
                  value={recordData?.equipmentNumber}
                  placeholder="Product Name"
                  aria-describedby="productnamehelp"
                  readOnly
                />
                <div id="productnamehelp" class="form-text font-size-13 ml-1">
                  Give your Product a short and clear name, 60-60 characters is recommended length for search engines.
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-14 font-weight-500" htmlFor="chargeCodeInput">
                  DESCRIPTION
                </label>
                <textarea
                  name="Description"
                  cols="30"
                  rows="5"
                  onChange={() => {}}
                  value={recordData?.description}
                  placeholder="Description"
                  className="form-control border-radius-10 font-size-13 text-primary"
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-14 font-weight-500" htmlFor="chargeCodeInput">
                  FLEET NO / UNIT NO
                </label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  placeholder="Product name"
                  aria-label="Product name"
                  value={recordData?.stockNumber}
                  style={{ borderRadius: "0 10px 10px 0" }}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group w-100">
                <label className="text-light-dark font-size-14 font-weight-500">SUPPORT DOCUMENTS</label>
              </div>
              <div className="d-flex justify-content-around">
                <div className="card border justify-content-center align-items-center mx-1" style={{ width: "20%" }}>
                  <div>
                    <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickFAQ}>
                      FAQ <KeyboardArrowDownIcon className="font-size-32" sx={{ fontSize: "26px" }} />
                    </Button>
                    <Menu id="simple-menu" anchorEl={faqEl} keepMounted open={Boolean(faqEl)} onClose={() => handleCloseFAQ(null)}>
                      <MenuItem onClick={() => handleCloseFAQ("genral")}>General</MenuItem>
                      <MenuItem onClick={() => handleCloseFAQ("lifeCycleServices")}>Life Cycle Services</MenuItem>
                      <MenuItem onClick={() => handleCloseFAQ("training")}>Training</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="card border justify-content-center align-items-center mx-1" style={{ width: "20%" }}>
                  <div>
                    <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickManuals}>
                      Manuals <KeyboardArrowDownIcon className="font-size-32" sx={{ fontSize: "26px" }} />
                    </Button>
                    <Menu id="simple-menu" anchorEl={manualsE1} keepMounted open={Boolean(manualsE1)} onClose={() => handleCloseanuals(null)}>
                      <MenuItem onClick={() => handleCloseanuals("operation")}>Operation</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("maintenance")}>Maintenance</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("troubleshooting")}>Troubleshooting</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("instruction")}>Instruction</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("preventiveMaintenance")}>Preventive Maintenance</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("maintenanceDrawings")}>Maintenance Drawings</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("PeripheralDevices")}>Peripheral Devices</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("users")}>Users</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("parts")}>Parts</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("safety")}>Safety</MenuItem>
                      <MenuItem onClick={() => handleCloseanuals("programming")}>Programming</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="card border justify-content-center align-items-center mx-1" style={{ width: "20%" }}>
                  <div>
                    <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickTrainingDocs}>
                      Training Docs <KeyboardArrowDownIcon className="font-size-32" sx={{ fontSize: "26px" }} />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={taringDocsE1}
                      keepMounted
                      open={Boolean(taringDocsE1)}
                      onClose={() => handleCloseTrainingDocs(null)}
                    >
                      <MenuItem onClick={() => handleCloseTrainingDocs("pocketGuides")}>Pocket Guides</MenuItem>
                      <MenuItem onClick={() => handleCloseTrainingDocs("videos")}>Videos</MenuItem>
                      <MenuItem onClick={() => handleCloseTrainingDocs("training")}>Training History</MenuItem>
                      <MenuItem onClick={() => handleCloseTrainingDocs("availableCredits")}>Available Credits</MenuItem>
                      <MenuItem onClick={() => handleCloseTrainingDocs("classSchedules")}>Class Schedules</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="card border justify-content-center align-items-center mx-1" style={{ width: "20%" }}>
                  <div>
                    <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickSpecDocs}>
                      Spec Docs <KeyboardArrowDownIcon className="font-size-32" sx={{ fontSize: "26px" }} />
                    </Button>
                    <Menu id="simple-menu" anchorEl={specDocsE1} keepMounted open={Boolean(specDocsE1)} onClose={() => handleCloseSpecDocs(null)}>
                      <MenuItem onClick={() => handleCloseSpecDocs("machineSpecs")}>Machine Specs</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("hmc")}>HMC</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("vmc")}>VMC</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("edm")}>EDM</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("retentionStuds")}>Retention Studs</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("stopLockOrientation")}>Stop Lock Orientation</MenuItem>
                      <MenuItem onClick={() => handleCloseSpecDocs("others")}>Others</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="card border justify-content-center align-items-center mx-1" style={{ width: "20%" }}>
                  <div>
                    <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickTechSelfHelp}>
                      Technical Self Help <KeyboardArrowDownIcon className="font-size-32" sx={{ fontSize: "26px" }} />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={technicalSelfHelpE1}
                      keepMounted
                      open={Boolean(technicalSelfHelpE1)}
                      onClose={() => handleCloseTechSelfHelp(null)}
                    >
                      <MenuItem onClick={() => handleCloseTechSelfHelp("genral")}>Technical Advice & Service Procedures</MenuItem>
                      <MenuItem onClick={() => handleCloseTechSelfHelp("lifeCycleServices")}>Preventive Maintenance Schedules</MenuItem>
                      <MenuItem onClick={() => handleCloseTechSelfHelp("training")}>Troubleshooting</MenuItem>
                      <MenuItem onClick={() => handleCloseTechSelfHelp("training")}>Adjustments & Alignments</MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ml-4">
          <div className="row input-fields mt-4">
            <div className="form-group w-100" style={{ height: "auto" }}>
              <label className="text-light-dark font-size-14 font-weight-500" htmlFor="countryInput">
                THUMBNAIL
              </label>
              <div className="card border p-2">
                <img
                  src={
                    recordData?.equipmentImage ||
                    `../assets/images/${
                      recordId && recordId === 18570 ? "equipment_18570.png" : recordId === 18571 ? "equipment_18571.png" : "partCartImage.png"
                    }`
                  }
                  alt="jcb"
                  className=" img-fluid w-100"
                  style={{ height: "300px", objectFit: "contain" }}
                />
                {/* <img src="../assets/images/chain-excavator.png" alt="jcb" className=" img-fluid w-100" /> */}
              </div>
            </div>
            <div className="form-group w-100  mt-2">
              <label className="text-light-dark font-size-14 font-weight-500" htmlFor="countryInput">
                LIVE PREVIEW LINK
              </label>
              <input type="text" className="form-control border-radius-10" id="countryInput" placeholder="Live Preview Link" />
            </div>
            <div className="form-group w-100">
              <label className="text-light-dark font-size-14 font-weight-500" htmlFor="countryInput">
                VIDEO EMBED
              </label>
              <input type="text" className="form-control border-radius-10" id="countryInput" placeholder="Video Embed" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // fiels and media tab table columns
  const filesMediaColumns = [
    { field: "documentName", headerName: "Document Name", flex: 1 },
    { field: "documentType", headerName: "Document Type", flex: 1 },
    { field: "documentDate", headerName: "Document Date", flex: 1 },
    { field: "staff", headerName: "Staff", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <div className={`font-weight-600 ${params.value == "Complete" ? "text-success" : "text-danger"}`}>{params.value}</div>,
    },
    { field: "region", headerName: "Region", flex: 1 },
    { field: "urlLink", headerName: "URL Link", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <VisibilityOutlined />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // view media tab
  const viewMediaPage = () => {
    return (
      <>
        <div className="mt-3">
          {/* <div className="row">
            <div className="col-md-9">
              <div
                class="input-group icons border-radius-10 border "
                style={{
                  overflow: "hidden",
                  // border: "1px solid #872ff9",
                  position: "relative",
                }}
              >
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                    <SearchIcon />
                  </span>
                </div>
                <input type="search" class="form-control search-form-control" aria-label="Search Dashboard" placeholder="Search" />
              </div>
            </div>
            <div className="col-md-3">
              <button className="btn border mr-2">
                <FilterListIcon /> Filter
              </button>
              <button className="btn border">
                <AttachFileIcon /> Attatchment
              </button>
            </div>
          </div>
          <div className="my-2">
            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  ALL CATEGORIES
                </label>
                <Select value={mediaCategory} onChange={handleMediaCategoryChange} options={categoryOptions} placeholder="All Categories" />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  DOCUMENT TYPE
                </label>
                <Select
                  onChange={(e) => setMediaDocsType(e)}
                  value={mediaDocsType}
                  options={
                    mediaCategory?.value === "TECHNICAL_SELF_HELP"
                      ? technicalDocsTypeOptions
                      : mediaCategory?.value === "SPEC_DOCS"
                      ? specDocsTypeOptions
                      : mediaCategory?.value === "MANUALS"
                      ? manulesDocsTypeOptions
                      : mediaCategory?.value === "TRAINING"
                      ? trainingDocsType
                      : []
                  }
                  placeholder="Document Type"
                />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  DOCUMENT DATE
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Document Date"
                />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  STAFF
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Staff"
                />
              </div>
              <div className="form-group w-100">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  REGION
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Region"
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="card border p-3">
                <VideoLibraryIcon />
                <p className="mb-0 mt-2 font-wight-600 font-size-16">Training Video</p>
                <p>X+Q</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="card border p-3">
                <VideoLibraryIcon />
                <p className="mb-0 mt-2 font-wight-600 font-size-16">Troubleshooting Video</p>
                <p>X+Q</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="card border p-3">
                <VideoLibraryIcon />
                <p className="mb-0 mt-2 font-wight-600 font-size-16">Maintenance Video</p>
                <p>X+Q</p>
              </div>
            </div>
          </div>
        </div>
        <div classname="video-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a href="https://www.youtube.com/embed/r0AfQD8hHOk&t=1s" target="_blank" rel="noopener noreferrer">
            <iframe
              width={560}
              height={315}
              src="https://www.youtube.com/embed/r0AfQD8hHOk"
              frameBorder={0}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </a>
        </div>
        {/* <div className="">
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid sx={GRID_STYLE} rows={fileMediaRecord} columns={filesMediaColumns} pageSize={5} rowsPerPageOptions={[5]} />
          </Box>
        </div> */}
      </>
    );
  };

  // view files tab
  const viewFilesPage = () => {
    return (
      <>
        <div className="mt-3">
          {/* <div className="row my-3">
            <div className="col-md-9">
              <div
                class="input-group icons border-radius-10 border "
                style={{
                  overflow: "hidden",
                  // border: "1px solid #872ff9",
                  position: "relative",
                }}
              >
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                    <SearchIcon />
                  </span>
                </div>
                <input type="search" class="form-control search-form-control" aria-label="Search Dashboard" placeholder="Search" />
              </div>
            </div>
            <div className="col-md-3">
              <button className="btn border mr-2">
                <FilterListIcon /> Filter
              </button>
              <button className="btn border">
                <AttachFileIcon /> Attatchment
              </button>
            </div>
          </div>
          <div className="my-2">
            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  ALL CATEGORIES
                </label>
                <Select value={filesCategory} onChange={handleFilesCategoryChange} options={categoryOptions} placeholder="All Categories" />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  DOCUMENT TYPE
                </label>
                <Select
                  onChange={(e) => setFilesDocsType(e)}
                  value={filesDocsType}
                  options={
                    filesCategory?.value === "TECHNICAL_SELF_HELP"
                      ? technicalDocsTypeOptions
                      : filesCategory?.value === "SPEC_DOCS"
                      ? specDocsTypeOptions
                      : filesCategory?.value === "MANUALS"
                      ? manulesDocsTypeOptions
                      : filesCategory?.value === "TRAINING"
                      ? trainingDocsType
                      : []
                  }
                  placeholder="Document Type"
                />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  DOCUMENT DATE
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Document Date"
                />
              </div>
              <div className="form-group w-100 mr-2">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  STAFF
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Staff"
                />
              </div>
              <div className="form-group w-100">
                <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                  REGION
                </label>
                <Select
                  // defaultValue={sparePartsPriceType}
                  // onChange={handlePriceChangeParts}
                  // options={partspriceoptions}
                  placeholder="Region"
                />
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-around align-items-center">
            <div className="card border px-3 py-2 mx-2 text-start align-items-start" style={{ width: "20%" }}>
              <div>
                <DescriptionIcon />
              </div>
              <div>
                <span className="font-size-16 font-weight-500 cursor" onClick={handleClickFAQ}>
                  FAQ
                </span>
                {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickFAQ}>
                  FAQ
                </Button> */}
                <Menu id="simple-menu" anchorEl={faqEl} keepMounted open={Boolean(faqEl)} onClose={() => handleCloseFAQ(null)}>
                  <MenuItem onClick={() => handleCloseFAQ("genral")}>General</MenuItem>
                  <MenuItem onClick={() => handleCloseFAQ("lifeCycleServices")}>Life Cycle Services</MenuItem>
                  <MenuItem onClick={() => handleCloseFAQ("training")}>Training</MenuItem>
                </Menu>
              </div>
              <p>X+Q</p>
            </div>
            <div className="card border px-3 py-2 mx-2 text-start align-items-start" style={{ width: "20%" }}>
              <div>
                <DescriptionIcon />
              </div>
              <div>
                <span className="font-size-16 font-weight-500 cursor" onClick={handleClickManuals}>
                  Manuals
                </span>
                {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickManuals}>
                  Manuals
                </Button> */}
                <Menu id="simple-menu" anchorEl={manualsE1} keepMounted open={Boolean(manualsE1)} onClose={() => handleCloseanuals(null)}>
                  <MenuItem onClick={() => handleCloseanuals("operation")}>Operation</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("maintenance")}>Maintenance</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("troubleshooting")}>Troubleshooting</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("instruction")}>Instruction</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("preventiveMaintenance")}>Preventive Maintenance</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("maintenanceDrawings")}>Maintenance Drawings</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("PeripheralDevices")}>Peripheral Devices</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("users")}>Users</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("parts")}>Parts</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("safety")}>Safety</MenuItem>
                  <MenuItem onClick={() => handleCloseanuals("programming")}>Programming</MenuItem>
                </Menu>
              </div>
              <p>X+P</p>
            </div>
            <div className="card border px-3 py-2 mx-2 text-start align-items-start" style={{ width: "20%" }}>
              <div>
                <DescriptionIcon />
              </div>
              <div className="justify-content-start">
                <span className="font-size-16 font-weight-500 cursor" onClick={handleClickTrainingDocs}>
                  Training Docs
                </span>
                {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickTrainingDocs}>
                  Training Docs
                </Button> */}
                <Menu id="simple-menu" anchorEl={taringDocsE1} keepMounted open={Boolean(taringDocsE1)} onClose={() => handleCloseTrainingDocs(null)}>
                  <MenuItem onClick={() => handleCloseTrainingDocs("pocketGuides")}>Pocket Guides</MenuItem>
                  <MenuItem onClick={() => handleCloseTrainingDocs("videos")}>Videos</MenuItem>
                  <MenuItem onClick={() => handleCloseTrainingDocs("training")}>Training History</MenuItem>
                  <MenuItem onClick={() => handleCloseTrainingDocs("availableCredits")}>Available Credits</MenuItem>
                  <MenuItem onClick={() => handleCloseTrainingDocs("classSchedules")}>Class Schedules</MenuItem>
                </Menu>
              </div>
              <p>X+W</p>
            </div>
            <div className="card border px-3 py-2 mx-2 text-start align-items-start" style={{ width: "20%" }}>
              <div>
                <DescriptionIcon />
              </div>
              <div>
                <span className="font-size-16 font-weight-500 cursor" onClick={handleClickSpecDocs}>
                  Spec Docs
                </span>
                {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickSpecDocs}>
                  Spec Docs
                </Button> */}
                <Menu id="simple-menu" anchorEl={specDocsE1} keepMounted open={Boolean(specDocsE1)} onClose={() => handleCloseSpecDocs(null)}>
                  <MenuItem onClick={() => handleCloseSpecDocs("machineSpecs")}>Machine Specs</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("hmc")}>HMC</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("vmc")}>VMC</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("edm")}>EDM</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("retentionStuds")}>Retention Studs</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("stopLockOrientation")}>Stop Lock Orientation</MenuItem>
                  <MenuItem onClick={() => handleCloseSpecDocs("others")}>Others</MenuItem>
                </Menu>
              </div>
              <p>X+P</p>
            </div>
            <div className="card border px-3 py-2 mx-2 text-start align-items-start" style={{ width: "20%" }}>
              <div>
                <DescriptionIcon />
              </div>
              <div className="d-block">
                <span className="font-size-16 font-weight-500 cursor" onClick={handleClickTechSelfHelp}>
                  Technical Self Help
                </span>
                {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickTechSelfHelp}>
                  Technical Self Help
                </Button> */}
                <Menu
                  id="simple-menu"
                  anchorEl={technicalSelfHelpE1}
                  keepMounted
                  open={Boolean(technicalSelfHelpE1)}
                  onClose={() => handleCloseTechSelfHelp(null)}
                >
                  <MenuItem onClick={() => handleCloseTechSelfHelp("genral")}>Technical Advice & Service Procedures</MenuItem>
                  <MenuItem onClick={() => handleCloseTechSelfHelp("lifeCycleServices")}>Preventive Maintenance Schedules</MenuItem>
                  <MenuItem onClick={() => handleCloseTechSelfHelp("training")}>Troubleshooting</MenuItem>
                  <MenuItem onClick={() => handleCloseTechSelfHelp("training")}>Adjustments & Alignments</MenuItem>
                </Menu>
                <p>X+W</p>
              </div>
            </div>
          </div>
        </div>

        <PdfThumbnail pdfUrl={"./assets/images/CaterpillarTechnicianGuide.pdf"} />
        {/* <div className="">
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid sx={GRID_STYLE} rows={fileMediaRecord} columns={filesMediaColumns} pageSize={5} rowsPerPageOptions={[5]} />
          </Box>
        </div> */}
      </>
    );
  };

  const viewSettingsPage = () => {
    return (
      <div className="mt-4">
        <div className="card border px-3">
          <div className="row mt-2">
            <ReadOnlyField label="EQUIPMENT NUMBER" value={recordData?.equipmentNumber} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="SERIAL NUMBER" value={recordData?.makerSerialNumber} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="DESCRIPTION" value={recordData?.description} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="MODEL" value={recordData?.model} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="MAKE" value={recordData?.maker} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="FAMILY" value={recordData?.market} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="PREFIX" value={recordData?.modelPrefix} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="WARRANTY TYPE" value={recordData?.warrantyType || "STANDARD"} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="WARRANTY STATUS" value={recordData?.warrantyStatus} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="SMU" value={recordData?.sensorId} className="col-md-4 col-sm-4" />
            <ReadOnlyField
              label="PURCHASE DATE"
              value={recordData?.purchaseDate ? <Moment format="DD/MM/YYYY">{recordData?.purchaseDate}</Moment> : "NA"}
              className="col-md-4 col-sm-4"
            />
            <ReadOnlyField label="PLACE OF PURCHASE" value={recordData?.placeOfPurchase} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="FLEET NO" value={recordData?.stockNumber} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="YEAR OF MANUFACTURE" value={recordData?.yearOfManufacture} className="col-md-4 col-sm-4" />
          </div>
        </div>
      </div>
    );
  };

  // save change
  const handleSaveChange = () => {
    if (activeTab === "general") {
      setActiveTab("media");
    } else if (activeTab === "media") {
      setActiveTab("files");
    } else if (activeTab === "files") {
      setActiveTab("details");
    } else if (activeTab === "details") {
      hideModal();
    }
  };

  return (
    <div>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body style={{ backgroundColor: "#f6f6f6" }}>
          {loading ? (
            <LoadingProgress />
          ) : (
            <>
              <h4 className="mb-3 px-2">Equipment Details</h4>
              <Box className="" sx={{ width: "100%", typography: "body1", paddingX: 1.2 }}>
                <TabContext value={activeTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList className="" onChange={(e, value) => setActiveTab(value)}>
                      <Tab label="GENERAL" value="general" className="heading-tabs" />
                      <Tab label="MEDIA " value="media" className="heading-tabs" />
                      <Tab label="FILES " value="files" className="heading-tabs" />
                      <Tab label="DETAILS " value="details" className="heading-tabs" />
                    </TabList>
                  </Box>
                  <TabPanel value={activeTab} sx={{ paddingTop: 0, paddingX: 0 }}>
                    {activeTab === "general" && viewGeneralPage()}
                    {activeTab === "media" && viewMediaPage()}
                    {activeTab === "files" && viewFilesPage()}
                    {activeTab === "details" && viewSettingsPage()}
                  </TabPanel>
                </TabContext>
              </Box>
              <div className="d-flex justify-content-end">
                <button className="btn border-primary text-primary mx-2" onClick={() => hideModal(!show)}>
                  Cancel
                </button>
                <button className="btn bg-primary text-white" onClick={handleSaveChange}>
                  {activeTab === "details" ? "Close" : "Next"}
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EquipmentDetailsModal;
