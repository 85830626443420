import React, { useEffect, useState } from "react";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Grid } from "@mui/material";

import { Modal } from "react-bootstrap";

import ImageMagnifier from "../../carts/ImageMagnifier";
import { partsSearch } from "../../../services/searchServices";
import Moment from "react-moment";
import { PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, PARTS_DELETE_PARTS_FROM_CART_DELETE } from "../../../services/CONSTANTS";
import { callDeleteApi, callPostApi } from "../../../services/ApiCaller";
import { API_SUCCESS } from "../../../services/ResponseCode";

const PartItemDetails = ({ show, hideModal, partItem, cartItems, setCartItems, recordId, setMouseDownDirection, handleSnack }) => {
  const [rowData, setRowData] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");

  useEffect(() => {
    if (partItem) {
      getPartDetails(partItem);
    }
  }, [partItem]);

  const getPartDetails = async (partItem) => {
    partsSearch(`partNumber:${partItem.partNumber}`).then((result) => {
      if (result && result.length > 0) {
        setRowData({ ...result[0], ...partItem });
        const _images = result[0];
        let _selectedImg = "";
        if (partItem.sparepartImages && partItem.sparepartImages.length > 0) {
          _selectedImg = partItem["sparepartImages"].find((item) => item === _images?.sparepartImages[0]);
        }
        setSelectedImg(_selectedImg || "../assets/images/partDefaultImage.png");

        // const _cartImage = partItem.sparepartImages && partItem.sparepartImages.length > 0 && partItem.sparepartImages[0];
        // setSelectedImg(
        //   _images?.sparepartImages && _images?.sparepartImages.length > 0
        //     ? _images?.sparepartImages[0]
        //       ? _images?.sparepartImages[0]
        //       : "../assets/images/partDefaultImage.png"
        //     : ""
        // );
      }
    });
  };

  // cart increase-decrease
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row.quantity + (1 || 1);
        break;
      case "down":
        next = row.quantity - (1 || 1) >= 0 ? row.quantity - (1 || 1) : 0;
        break;
      default:
        next = row.quantity;
        break;
    }
    updateCartValue(next, row);
    if (next !== 0) {
    }
  };

  // update cart quantity
  const updateCartValue = async (value, itemrow) => {
    if (cartItems) {
      const partsCartRes = cartItems["parts"];
      let row = partsCartRes.find((obj) => obj?.partNumber === itemrow?.partNumber);
      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
        const rObj = {
          // partsCartId: cartItems?.cartId,
          cartId: cartItems?.cartId,
          partsQuantity: {
            [String(row["partsId"])]: value,
          },
        };

        if (value == 0) {
          let dltRUrl = `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${row["partsId"]}`;
          callDeleteApi(null, dltRUrl, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("info", `Part Number ${itemrow?.partNumber} removed from cart`);
              let _partsCartRes = partsCartRes.filter((obj) => obj?.partsId !== row?.partsId);
              setCartItems({ ...cartItems, parts: [..._partsCartRes] });
            }
          });
        } else {
          callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              setRowData({ ...rowData, quantity: value });
              let _partsCartRes = partsCartRes.map((obj) => (obj?.partsId === row?.partsId ? row : obj));
              setCartItems({ ...cartItems, parts: [..._partsCartRes] });
            }
          });
        }
      }
    }
  };

  return (
    <Modal show={show} onHide={hideModal} centered size="lg">
      <Modal.Body className="d-flex flex-column" style={{ height: "100%" }}>
        <div className="row flex-grow-1 mb-0">
          <div className="col-lg-7 col-md-7 col-sm-7 col-12 mb-0">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                {rowData?.sparepartImages &&
                  rowData?.sparepartImages.length > 0 &&
                  rowData?.sparepartImages.map((imgsrc) => (
                    <div className="card border p-1 cursor" onClick={() => setSelectedImg(imgsrc)}>
                      <img src={imgsrc ? imgsrc : "../assets/images/partDefaultImage.png"} alt="Part Image" />
                    </div>
                  ))}
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-12">
                <ImageMagnifier src={selectedImg} />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-12 mb-0" style={{ borderLeft: "1px solid #cfcece" }}>
            <h4 className="mb-0 px-2">{rowData?.partNumber}</h4>
            <p className="mb-0 px-2">{rowData?.partDescription}</p>
            <div className="form-group mt-2 px-2">
              <label className="mb-0">Quantity</label>
              <div className="d-flex align-items-center">
                <div className="input-number">
                  <button
                    onClick={(e) => handleButtonChange("down", rowData)}
                    onMouseDown={() => setMouseDownDirection("down", rowData)}
                    onMouseOut={() => setMouseDownDirection(null)}
                    onMouseUp={() => setMouseDownDirection(null)}
                    disabled={rowData?.quantity === 0}
                  >
                    -
                  </button>
                  <input type="number" step={1} value={rowData?.quantity} readOnly />
                  <button
                    onClick={(e) => handleButtonChange("up", rowData)}
                    onMouseDown={() => setMouseDownDirection("up", rowData)}
                    onMouseUp={() => setMouseDownDirection(null)}
                    onMouseOut={() => setMouseDownDirection(null)}
                    // disabled={rowData?.quantity === 15}
                    disabled={Number(rowData?.availability) ? rowData?.quantity === Number(rowData?.availability) : true}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center px-2">
              <div className="d-flex align-items-center">
                <p className={`${Number(rowData?.availability) ? "green-dot" : "red-dot"}`}></p>
                <p className="ml-1">{Number(rowData?.availability) ? "Available" : "Inaccessible"}</p>
              </div>
              <div className="d-flex align-items-center mx-2">
                <p>{Number(rowData?.availability)}</p>
                <p className="ml-1">(In Stock)</p>
              </div>
            </div>
            <div className="d-flex align-items-center px-2 mb-1">
              <p className="mb-0">Manufacturer : </p>
              <p className="ml-1 mb-0">{rowData?.manufacturer}</p>
            </div>
            <div className="d-flex align-items-center px-2 mb-1">
              <p className="mb-0">Model : </p>
              <p className="ml-1 mb-0">{rowData?.model}</p>
            </div>
            <div className="d-flex align-items-center px-2 mb-1">
              <p className="mb-0">Year of Manufacturer : </p>
              {/* <p className="ml-1 mb-0">{rowData?.yearOfManufacture ? <Moment format="DD/MM/YYYY">{rowData.yearOfManufacture}</Moment> : "-"}</p> */}
              <p className="ml-1 mb-0">{rowData?.yearOfManufacture || "-"}</p>
            </div>

            <Modal.Footer>
              <button className="btn border-primary text-primary mb-0" onClick={hideModal}>
                <KeyboardBackspaceIcon /> Back
              </button>
            </Modal.Footer>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PartItemDetails;
