import React from "react";

import { Modal } from "react-bootstrap";

const RepairRequest = ({ show, hideModal }) => {
  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5>Request For Repair</h5>
        <div className="card border px-3">
          <div className="row mt-2 input-fields">
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REASON FOR REQUEST</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  id="requestReason"
                  name="requestReason"
                  placeholder="Reason for Request"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION (SHORT DESCRIPTION)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  id="shortDescription"
                  name="shortDescription"
                  placeholder="Short Description"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BRIEF DESCRIPTION (LONG DESCRIPTION)</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  id="shortDescription"
                  name="shortDescription"
                  placeholder="Brief Description"
                  cols={30}
                  rows={4}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">USER NAME</label>
                <input type="text" className="form-control border-radius-10 text-primary" id="userName" name="userName" placeholder="User Name" />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">EMAIL (if not same in profile)</label>
                <input type="text" className="form-control border-radius-10 text-primary" id="email" name="email" placeholder="Email Address" />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CONTACT (if not same in profile)</label>
                <input type="text" className="form-control border-radius-10 text-primary" id="contact" name="contact" placeholder="Contact" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-0" style={{ justifyContent: "right" }}>
          <button className="btn btn-primary text-white mx-2" onClick={hideModal}>
            Save & Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RepairRequest;
