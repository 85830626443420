import React, { useEffect, useRef } from "react";
// import { pdfjs } from 'react-pdf';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry"; // Ensure the worker is included

const PdfThumbnail = ({ pdfUrl }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadPdf = async () => {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.0 });

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      //   canvas.height = viewport.height;
      //   canvas.width = viewport.width;

      // Desired dimensions
      const desiredHeight = 250;
      const desiredWidth = 200;

      // Calculate the scale factor
      const scale = Math.min(desiredWidth / viewport.width, desiredHeight / viewport.height);
      const scaledViewport = page.getViewport({ scale });

      canvas.height = desiredHeight;
      canvas.width = desiredWidth;

      // Calculate the offset to center the page within the canvas
      const offsetX = (desiredWidth - scaledViewport.width) / 2;
      const offsetY = (desiredHeight - scaledViewport.height) / 2;

      context.setTransform(1, 0, 0, 1, offsetX, offsetY); // Set the translation
      context.clearRect(0, 0, desiredWidth, desiredHeight); // Clear the canvas

      //   const renderContext = {
      //     canvasContext: context,
      //     viewport: viewport,
      //   };
      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      await page.render(renderContext);
    };

    loadPdf();
  }, [pdfUrl]);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
        <canvas
          ref={canvasRef}
          style={{
            border: "1px solid #aaa",
            backgroundColor: "#fff",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            display: "block",
            margin: "auto",
            borderRadius: "4px",
            opacity: 0.7,
          }}
        />
        <img
          src={"./assets/images/pdfIcon.png"} // PDF icon image
          alt="PDF Icon"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the icon
            width: "40px", // Adjust size as needed
            height: "40px",
            // opacity: 4,
          }}
        />
      </a>
    </div>
  );
};

export default PdfThumbnail;
