import React, { useEffect, useState } from "react";

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Tooltip, Divider } from "@mui/material";

import { Modal } from "react-bootstrap";
import Moment from "react-moment";

import { notesList, warrantyBasisOptions, warrantyCategoryOptions, warrantyStatusOptions, warrantyUnitOptions } from "./WarrantyContstants";
import { isEmpty } from "./common/textUtilities";
import { getFormatDateTime } from "./common/dateUtilities";
import { fetchWarranty } from "../services/EquipmentService";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";
import { ReadOnlyField } from "./common/ReadOnlyField";
import { Get_Equipment_Datails_By_Id_GET, SEARCH_CUSTOMER, WARRANTY_INSTALLER_MASTER_URL, Warranty_Yearly_GetById_GET } from "../services/CONSTANTS";
import {
  customerRequestObj,
  equipmentRequestObj,
  installerRequestObj,
  installerTypeOptions,
  warrantyNotesList,
  warrantyTypeOptions,
  yearlyWarrantyObj,
} from "./warrantyManagement/warrantyManagementConstants";
import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import LoadingProgress from "./common/Loader";

const WarrantyOverView = ({ show, hideModal, recordId, warranty, handleSnack }) => {
  const [tabValue, setTabValue] = useState("overview");
  const [loading, setLoading] = useState(false);
  const [warrantyRecord, setWarrantyRecord] = useState({
    ...warranty,
  });

  const [customerRecord, setCustomerRecord] = useState({
    ...customerRequestObj,
  });

  const [installerRecord, setInstallerRecord] = useState({
    ...installerRequestObj,
  });

  const [yearlyWarrantyRecord, setYearlyWarrantyRecord] = useState({
    ...yearlyWarrantyObj,
  });

  const [equipmentRecordData, setEquipmentRecordData] = useState({
    ...equipmentRequestObj,
  });

  const [showClaimDetailsModal, setShowClaimDetailsModal] = useState(false);
  const [showClaimAddEditModal, setShowClaimAddEditModal] = useState(false);

  const handleShowClaimAddEditModal = () => {
    setShowClaimAddEditModal(!showClaimAddEditModal);
    // setShowClaimBackModal(!showClaimAddEditModal);
  };
  useEffect(() => {
    if (recordId && recordId !== null) {
      setLoading(true);
      fetchWarranty(recordId)
        .then((warranty) => {
          const { installerDetails, customerDetails, ...responseData } = warranty;

          // get category key value pairs
          const _category = warrantyCategoryOptions.find((obj) => obj.value === responseData.category);

          // get basis key value pairs
          const _basis = warrantyBasisOptions.find((obj) => obj.value === responseData.basis);

          // get unit key value pairs
          const _unit = warrantyUnitOptions.find((obj) => obj.value === responseData.unit);

          // get status key value pairs
          const _warrantyStatus = warrantyStatusOptions.find((obj) => obj.value === responseData.warrantyStatus);

          // set Overview record
          setWarrantyRecord({
            ...responseData,
            category: _category || "",
            basis: _basis || "",
            unit: _unit || "",
            warrantyStatus: _warrantyStatus || "",
          });

          // get equipment details
          if (responseData.equipmentId) {
            getEquipmentDetails(responseData.equipmentId);
          }

          if (responseData.installerId) {
            getInstallerDetails(responseData.installerId);
          }

          if (responseData.yearlyWarrantyIds.length !== 0) {
            getYearlyWarrantyDetails(responseData["yearlyWarrantyIds"][0]);
          }

          // setCustomerRecord({ ...customerDetails });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [recordId]);

  // get equipment details
  const getEquipmentDetails = (id) => {
    // const rUrl = `${WARRANTY_EQUIPMENT_MASTER_URL}/${id}`;
    const rUrl = `${Get_Equipment_Datails_By_Id_GET}${id}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        setEquipmentRecordData({ ...responseData });

        if (responseData.currentClient) {
          getCustomerDetails(responseData.currentClient, "customerId");
        } else if (responseData.customer) {
          getCustomerDetails(responseData.customer, "fullName");
        }
      }
    });
  };

  // get customer details
  const getCustomerDetails = (id, fieldName) => {
    const rUrl = SEARCH_CUSTOMER(`${fieldName}:${id}`);
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setCustomerRecord({
          customerId: responseData[0].customerId,
          customerName: responseData[0].fullName,
          email: responseData[0].email,
          address: responseData[0].addressDTO?.fullAddress,
          city: responseData[0].addressDTO?.district,
          state: responseData[0].addressDTO?.regionOrState,
          country: responseData[0].addressDTO?.country,
          zipCode: responseData[0].addressDTO?.zipCode,
          phoneNumber: responseData[0]?.phoneNumber,
        });
      }
    });
  };

  // get warranty installer details
  const getInstallerDetails = (installerId) => {
    const rUrl = `${WARRANTY_INSTALLER_MASTER_URL}/${installerId}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        // set installer record data
        const _installerType = installerTypeOptions.find((obj) => obj.value === responseData.installerType);
        setInstallerRecord({
          ...responseData,
          installerType: _installerType || "",
        });
      }
    });
  };

  // get yearly warranty details
  const getYearlyWarrantyDetails = (yearId) => {
    const rUrl = `${Warranty_Yearly_GetById_GET}/${yearId}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        // get basis key value pairs
        const _basis = warrantyBasisOptions.find((obj) => obj.value === responseData.basis);

        const _warrantyType = warrantyTypeOptions.find((obj) => obj.value === responseData.warrantyType);
        setYearlyWarrantyRecord({
          ...responseData,
          warrantyType: _warrantyType,
          basis: _basis,
        });
      }
    });
  };

  // Overview
  const viewOverviewTabData = () => {
    return (
      <>
        {showClaimAddEditModal && (
          <WarrantyClaimAddUpdate
            show={showClaimAddEditModal}
            hideModal={handleShowClaimAddEditModal}
            warranty={warranty}
            handleSnack={handleSnack}
            warrantyRecord={{ ...warrantyRecord, ...yearlyWarrantyRecord }}
            customerRecord={customerRecord}
            installerRecord={installerRecord}
          />
        )}
        {/* <div className="row">
          <div className="col-md-10">
            <h5>WARRANTY DETAILS</h5>
          </div>
          <div className="col-md-2 justify-content-end mb-2 float-end ">
            <button className="btn btn-primary float-end " onClick={handleShowClaimAddEditModal}>
              Raise Claim
            </button>
          </div>
        </div> */}
        {/* <Divider /> */}
        <div className="row mt-3 mb-0">
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 border-50">
            <div className="card border px-5">
              <img src="../../assets/images/spare-parts.png" alt="spare-parts" className="card-img-top width-75 img-fluid" />
            </div>
            <div className="card-body">
              <h6 className="card-title">Notes</h6>
              <p class="card-text">
                Get to know about our warranty offerings for each product by contacting your OEM / authorized dealer. The applicable Gainwell Limited
                Warranty and coverage details may vary depending on the product type, application, and location. Additional coverage options may be
                available for attachments, Certified Rebuild, Used and Certified Used Equipment. Limitations may apply.
              </p>
              <p>
                Registering your warranty ensures that you receive important information about potential recalls and helps us process claims quickly.
                Your OEM / dealer will usually register your warranty.
              </p>
              <h6>Need to register your engine or OEM Solutions product warranty? Register your product today.</h6>
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 border-50">

            <div className="card-body">
              <h6 className="card-title">WARRANTY AND COVERAGE INFORMATION</h6>
              <p class="card-text">
                Get to know about our warranty offerings for each product by contacting your OEM / authorized dealer. The applicable Gainwell Limited
                Warranty and coverage details may vary depending on the product type, application, and location. Additional coverage options may be
                available for attachments, Certified Rebuild, Used and Certified Used Equipment. Limitations may apply.
              </p>
              <p>
                Registering your warranty ensures that you receive important information about potential recalls and helps us process claims quickly.
                Your OEM / dealer will usually register your warranty.
              </p>
              <h6>Need to register your engine or OEM Solutions product warranty? Register your product today.</h6>
            </div>
          </div> */}
          <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 equipment-master-chart mt-custom">
            <div className="">
              <div className="bg-white p-3 border-radius-10 ">
                <div className="d-flex align-items-center justify-content-between equipment-pagination">
                  <h5 className="font-weight-600 mb-0 text-uppercase">
                    {/* CARGADOR RUEDAS/PORTAH. INTEGR - 992K */}
                    {isEmpty(warrantyRecord.title) ? "NA" : warrantyRecord.title}
                  </h5>
                  <button className="btn btn-primary float-end " onClick={handleShowClaimAddEditModal}>
                    Raise Claim
                  </button>
                </div>
              </div>

              <div className="card border px-3 py-3">
                <div className="bg-white p-3 border-radius-10 overflow-hidden">
                  <div className="row align-items-end">
                    <ReadOnlyField label="WARRANTY ID" value={warrantyRecord?.warrantyId} className="col-lg-3 col-md-3 col-sm-6 col-12" />
                    <ReadOnlyField label="CATEGORY" value={yearlyWarrantyRecord.warrantyType?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
                    <ReadOnlyField label="BASIS" value={yearlyWarrantyRecord.basis?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
                    <ReadOnlyField label="UNIT" value={warrantyRecord.unit?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
                    <ReadOnlyField
                      label="WARRANTY START DATE"
                      value={
                        yearlyWarrantyRecord.warrantyStartDate ? <Moment format="DD/MM/YYYY">{yearlyWarrantyRecord.warrantyStartDate}</Moment> : "NA"
                      }
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="WARRANTY END DATE"
                      value={
                        yearlyWarrantyRecord.warrantyEndDate ? <Moment format="DD/MM/YYYY">{yearlyWarrantyRecord.warrantyEndDate}</Moment> : "NA"
                      }
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="DATE OF INSTALL"
                      value={warrantyRecord.dateOfInstall ? <Moment format="DD/MM/YYYY">{warrantyRecord.dateOfInstall}</Moment> : "NA"}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="WARRANTY START USAGE"
                      value={yearlyWarrantyRecord.warrantyStartUsage}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="WARRANTY END USAGE"
                      value={yearlyWarrantyRecord.warrantyEndUsage}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="MODEL NUMBER"
                      value={warrantyRecord.modelNumber}
                      // value={equipmentRecordData.model}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="MAKE"
                      value={warrantyRecord.make?.label}
                      // value={equipmentRecordData.maker}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="FAMILY"
                      value={warrantyRecord.family?.label}
                      // value={equipmentRecordData.make}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="MACHINE/COMPONENT"
                      value={warrantyRecord.machine?.label}
                      // value={warrantyRecord.machine ? "MACHINE" : "COMPONENT"}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="MACHINE SERIAL NUMBER"
                      value={warrantyRecord.serialNumber}
                      // value={warrantyRecord.machineSerialNumber}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField label="COMPONENT CODE" value={warrantyRecord.componentCode} className="col-lg-3 col-md-3 col-sm-6 col-12" />
                    <ReadOnlyField
                      label="SERIAL NUMBER"
                      value={warrantyRecord.serialNumber}
                      // value={equipmentRecordData.makerSerialNumber}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="WARRANTY STATUS"
                      value={warrantyRecord.warrantyStatus?.label}
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="WARRANTY CERTIFICATE"
                      value={
                        <>
                          <img
                            className="mx-1 cursor"
                            src="../../assets/images/fileUploadIcon.png"
                            alt="File Upload icon"
                            // onClick={() => setTabValue("files")}
                          />
                          {isEmpty(warrantyRecord.warrantyCertificate) ? "No" : "Yes"}
                        </>
                      }
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    <ReadOnlyField
                      label="PROOF OF INSTALL"
                      value={
                        <>
                          <img
                            className="mx-1 cursor"
                            src="../../assets/images/fileUploadIcon.png"
                            alt="File Upload icon"
                            // onClick={() => setTabValue("files")}
                          />
                          {/* <InsertPhotoIcon /> */}
                          {isEmpty(warrantyRecord.proofOfInstall) ? "No" : "Yes"}
                        </>
                      }
                      className="col-lg-3 col-md-3 col-sm-6 col-12"
                    />
                    {/* <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty Id</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">18</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Category</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">STANDARD</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Basis</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">TIME</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Unit</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">MONTHS</h6>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty Start Date</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">12/01/2024</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty End Date</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">11/01/2026</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty Start Usage</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">NA</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty End Usage</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">NA</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Model Number</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">992K</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Make</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">CATERPILLER</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Machine/Component</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">MACHINE</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Component Code</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">NA</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Serial Number</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">ZMX00507</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Date of Install</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">12/01/2024</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty Certificate</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">NA</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Proof of Install</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">NO</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">Warranty Status</p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">IN WARRANTY</h6>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row align-items-end">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <h5 style={{ fontWeight: "bold" }}>Disclaimer</h5>
                    <div className="card border px-3">
                      <ul className="py-2">
                        <li className="text-light-dark font-size-17 font-weight-500 mb-2">Warranty Agreement</li>
                        <ol className="list-group-numbered" type="a">
                          {notesList.map((notes, i) => (
                            <li key={`notes-${i}`} className="text-light-dark font-size-17 font-weight-500 mb-2">
                              {`${i + 1}. ${notes.title}`}
                              <ul>
                                {isEmpty(notes.subTitle) &&
                                  notes.contentList.map((content, j) => (
                                    <li key={`notes-content-${i}-${j}`} className="text-light-dark font-size-14 font-weight-500 mb-2">
                                      {content}
                                    </li>
                                  ))}
                                {!isEmpty(notes.subTitle) && (
                                  <>
                                    <li className="text-light-dark font-size-14 font-weight-500 mb-2">{notes.subTitle}</li>
                                    <ol className="pl-3" type="a" style={{ textTransform: "lowercase" }}>
                                      {notes.contentList.map((content, j) => (
                                        <li
                                          key={`notes-content-with-subTitle-${i}-${j}`}
                                          className="text-light-dark font-size-14 font-weight-500 mb-2"
                                        >
                                          {content}
                                        </li>
                                      ))}
                                    </ol>
                                  </>
                                )}
                              </ul>
                            </li>
                          ))}
                        </ol>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "right" }}>
          <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => setTabValue("details")}>
            Next
          </button>
        </div>
      </>
    );
  };

  // details tab content
  const viewDetailsTabData = () => {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-weight-bold fw-bold mb-0">Item Details</h5>
          {/* <button
            className="btn btn-primary float-end "
            onClick={() =>
              setViewOnlyTab({
                ...viewOnlyTab,
                detailsViewOnly: !viewOnlyTab.detailsViewOnly,
              })
            }
          >
            Edit
          </button> */}
        </div>
        <div className="card border mb-3 mt-2 px-3 py-3">
          {/* {viewOnlyTab.detailsViewOnly ? ( */}
          <div className="row align-items-end">
            <ReadOnlyField label="WARRANTY ID" value={warrantyRecord.warrantyId} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField label="CATEGORY" value={yearlyWarrantyRecord.warrantyType?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField label="BASIS" value={yearlyWarrantyRecord.basis?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField label="UNIT" value={warrantyRecord.unit?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField
              label="WARRANTY START DATE"
              value={yearlyWarrantyRecord.warrantyStartDate ? <Moment format="DD/MM/YYYY">{yearlyWarrantyRecord.warrantyStartDate}</Moment> : "NA"}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField
              label="WARRANTY END DATE"
              value={yearlyWarrantyRecord.warrantyEndDate ? <Moment format="DD/MM/YYYY">{yearlyWarrantyRecord.warrantyEndDate}</Moment> : "NA"}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField
              label="WARRANTY START USAGE"
              value={yearlyWarrantyRecord.warrantyStartUsage}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField label="WARRANTY END USAGE" value={yearlyWarrantyRecord.warrantyEndUsage} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField label="COMPONENT CODE" value={warrantyRecord.componentCode} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField
              label="SERIAL NUMBER"
              // value={warrantyRecord.serialNumber}
              value={equipmentRecordData.makerSerialNumber}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField
              label="DATE OF INSTALL"
              value={warrantyRecord.dateOfInstall ? <Moment format="DD/MM/YYYY">{warrantyRecord.dateOfInstall}</Moment> : "NA"}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField
              label="WARRANTY CERTIFICATE"
              value={
                <>
                  <img
                    className="mx-1 cursor"
                    src="../../assets/images/fileUploadIcon.png"
                    alt="File Upload icon"
                    onClick={() => setTabValue("files")}
                  />
                  {isEmpty(warrantyRecord.warrantyCertificate) ? "No" : "Yes"}
                </>
              }
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField
              label="PROOF OF INSTALL"
              value={
                <>
                  <img
                    className="mx-1 cursor"
                    src="../../assets/images/fileUploadIcon.png"
                    alt="File Upload icon"
                    onClick={() => setTabValue("files")}
                  />
                  {isEmpty(warrantyRecord.proofOfInstall) ? "No" : "Yes"}
                  {/* <InsertPhotoIcon /> */}
                </>
              }
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField label="WARRANTY STATUS" value={warrantyRecord.warrantyStatus?.label} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField
              label="DATE OF SALE"
              value={warrantyRecord.dateOfSale ? <Moment format="DD/MM/YYYY">{warrantyRecord.dateOfSale}</Moment> : "NA"}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
            <ReadOnlyField label="REPLACEMENT" value={warrantyRecord.replacement ? "YES" : "NO"} className="col-lg-3 col-md-3 col-sm-6 col-12" />
            <ReadOnlyField
              label="MANUFACTURE DATE"
              value={warrantyRecord.manufactureDate ? <Moment format="DD/MM/YYYY">{warrantyRecord.manufactureDate}</Moment> : "NA"}
              className="col-lg-3 col-md-3 col-sm-6 col-12"
            />
          </div>
          {/* ) : (
            <div className="row input-fields">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY ID</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={warrantyRecord.warrantyId}
                    disabled
                    name="warrantyId"
                    placeholder="Warranty Id"
                    onChange={handleWarrantyRecordChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CATEGORY</label>
                  <Select
                    className="text-primary"
                    options={warrantyCategoryOptions}
                    onChange={(e) =>
                      setYearlyWarrantyRecord({
                        ...yearlyWarrantyRecord,
                        warrantyType: e,
                      })
                    }
                    value={yearlyWarrantyRecord.warrantyType}
                    // onChange={(e) =>
                    //   setWarrantyRecord({
                    //     ...warrantyRecord,
                    //     category: e,
                    //   })
                    // }
                    // value={warrantyRecord.category}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">BASIS</label>
                  <Select
                    className="text-primary"
                    options={warrantyBasisOptions}
                    onChange={(e) =>
                      setYearlyWarrantyRecord({
                        ...yearlyWarrantyRecord,
                        basis: e,
                      })
                    }
                    value={yearlyWarrantyRecord.basis}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">UNIT</label>
                  <Select
                    className="text-primary"
                    options={warrantyUnitOptions}
                    onChange={(e) =>
                      setWarrantyRecord({
                        ...warrantyRecord,
                        unit: e,
                      })
                    }
                    value={warrantyRecord.unit}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY START DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={yearlyWarrantyRecord.warrantyStartDate}
                        onChange={(e) =>
                          setYearlyWarrantyRecord({
                            ...yearlyWarrantyRecord,
                            warrantyStartDate: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY END DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={yearlyWarrantyRecord.warrantyEndDate}
                        onChange={(e) =>
                          setYearlyWarrantyRecord({
                            ...yearlyWarrantyRecord,
                            warrantyEndDate: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY START USAGE</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    value={yearlyWarrantyRecord.warrantyStartUsage}
                    name="warrantyStartUsage"
                    placeholder="Start Usage"
                    onChange={handleYearluWarrantyRecordChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY END USAGE</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    value={yearlyWarrantyRecord.warrantyEndUsage}
                    name="warrantyEndUsage"
                    placeholder="End Usage"
                    onChange={handleYearluWarrantyRecordChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">COMPONENT CODE</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    value={warrantyRecord.componentCode}
                    name="componentCode"
                    placeholder="Component Code"
                    onChange={handleWarrantyRecordChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={warrantyRecord.serialNumber}
                    name="serialNumber"
                    placeholder="Serial Number"
                    onChange={handleWarrantyRecordChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">DATE OF INSTALL</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        maxDate={new Date()}
                        closeOnSelect
                        value={warrantyRecord.dateOfInstall}
                        onChange={(e) =>
                          setWarrantyRecord({
                            ...warrantyRecord,
                            dateOfInstall: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY CERTIFICATE</label>
                  <div
                    className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                    onClick={handleShowFileUploadModal}
                  >
                    <h6 className="text-primary m-0 font-size-16 font-weight-500">
                      {isEmpty(warrantyRecord.warrantyCertificate) ? "Upload File" : "view"}
                    </h6>
                    <img
                      className="mx-1 cursor"
                      src="../../assets/images/fileUploadIcon.png"
                      alt="File Upload icon"
                      // onClick={handleShowFileUploadModal}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PROOF OF INSTALL</label>
                  <div
                    className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                    onClick={handleShowFileUploadModal}
                  >
                    <h6 className="text-primary m-0 font-size-16 font-weight-500">
                      {isEmpty(warrantyRecord.proofOfInstall) ? "Upload File" : warrantyRecord.proofOfInstall}
                    </h6>
                    <img
                      className="mx-1 cursor"
                      src="../../assets/images/fileUploadIcon.png"
                      alt="File Upload icon"
                      // onClick={handleShowFileUploadModal}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY STATUS</label>
                  <Select
                    className="text-primary"
                    options={warrantyStatusOptions}
                    onChange={(e) =>
                      setWarrantyRecord({
                        ...warrantyRecord,
                        warrantyStatus: e,
                      })
                    }
                    value={warrantyRecord.warrantyStatus}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginLeft: 0,
                      }}
                      control={
                        <Switch
                          checked={warrantyRecord.replacement}
                          onChange={(e) =>
                            setWarrantyRecord({
                              ...warrantyRecord,
                              replacement: e.target.checked,
                            })
                          }
                        />
                      }
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURE DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        maxDate={new Date()}
                        closeOnSelect
                        value={warrantyRecord.manufactureDate}
                        onChange={(e) =>
                          setWarrantyRecord({
                            ...warrantyRecord,
                            manufactureDate: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <h5 className="mb-1">Installer</h5>
        <div className="card border mb-3 px-3 py-3">
          {/* {viewOnlyTab.detailsViewOnly ? (
            <> */}
          <div className="row align-items-end">
            <ReadOnlyField label="INSTALLER COMPANY NAME" value={installerRecord.companyName} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INSTALLER ADDRESS" value={installerRecord.address} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="CITY" value={installerRecord.city} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="STATE" value={installerRecord.state} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="ZIP CODE" value={installerRecord.zipCode} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="CONTACT EMAIL" value={installerRecord.email} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PHONE NUMBER" value={installerRecord.phoneNumber} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INSTALL TYPE" value={installerRecord.installerType?.label} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="EQUIPMENT INFO" value={installerRecord.equipmentInfo} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="DISTRIBUTOR/WHOLESALER" value={installerRecord.distributor} className="col-md-3 col-sm-3" />
          </div>
          {/* </>
          ) : (
            <>
              <div className="row input-fields">
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">INSTALLER COMPANY NAME</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.companyName}
                      name="companyName"
                      placeholder="Company Name"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">INSTALLER ADDRESS</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.address}
                      name="address"
                      placeholder="Address"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CITY</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.city}
                      name="city"
                      placeholder="City"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.state}
                      name="state"
                      placeholder="State"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">ZIP CODE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.zipCode}
                      name="zipCode"
                      placeholder="Zip Code"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CONTACT EMAIl</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.email}
                      name="email"
                      placeholder="Email"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">PHONE NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.phoneNumber}
                      name="phoneNumber"
                      placeholder="Phone Number"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">INSTALL TYPE</label>
                    <Select
                      className="text-primary"
                      options={installerTypeOptions}
                      onChange={(e) =>
                        setInstallerRecord({
                          ...installerRecord,
                          installerType: e,
                        })
                      }
                      value={installerRecord.installerType}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">EQUIPMENT INFO</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.equipmentInfo}
                      name="equipmentInfo"
                      placeholder="Equipment Info"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">DISTRIBUTOR/WHOLESALER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={installerRecord.distributor}
                      name="distributor"
                      placeholder="Distributor/Wholesaler"
                      onChange={handleInstallerFieldsChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}
        </div>
        <h5 className="mb-1">End Customer</h5>
        <div className="card border mb-3 px-3 py-3">
          {/* {viewOnlyTab.detailsViewOnly ? (
            <> */}
          <div className="row align-items-end">
            <ReadOnlyField label="CUSTOMER ID" value={customerRecord.customerId} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="CUSTOMER NAME" value={customerRecord.customerName} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="ADDRESS" value={customerRecord.address} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="CITY" value={customerRecord.city} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="STATE" value={customerRecord.state} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="ZIP CODE" value={customerRecord.zipCode} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="CONTACT EMAIL" value={customerRecord.email} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PHONE NUMBER" value={customerRecord.phoneNumber} className="col-md-3 col-sm-3" />
          </div>
          {/* </>
          ) : (
            <>
              <div className="row mt-2 input-fields">
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.customerId}
                      name="customerId"
                      placeholder="Customer Id"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.customerName}
                      name="customerName"
                      placeholder="Customer Name"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.address}
                      name="address"
                      placeholder="Address"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CITY</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.city}
                      name="city"
                      placeholder="City"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.state}
                      name="state"
                      placeholder="State"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">ZIP CODE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.zipCode}
                      name="zipCode"
                      placeholder="Zip Code"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CONTACT ENAIL</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.email}
                      name="email"
                      placeholder="Email"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">PHONE NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={customerRecord.phoneNumber}
                      name="phoneNumber"
                      placeholder="Phone Number"
                      onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}
        </div>
        <h5 className="font-weight-bold fw-bold mb-0">Warranty Agreement</h5>
        <div className="card border mb-2 mt-2 px-3">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <ul className="py-2">
                <ol className="list-group-numbered" type="a">
                  {warrantyNotesList.map((notes, i) => (
                    <li key={`notes-${i}`} className="text-light-dark font-size-17 font-weight-500 mb-2">
                      {`${i + 1}. ${notes.title}`}
                      <ul>
                        {isEmpty(notes.subTitle) &&
                          notes.contentList.map((content, j) => (
                            <li key={`notes-content-${i}-${j}`} className="text-light-dark font-size-12 font-weight-500 mb-2">
                              {content}
                            </li>
                          ))}
                        {!isEmpty(notes.subTitle) && (
                          <>
                            <li className="text-light-dark font-size-12 font-weight-500 mb-2">{notes.subTitle}</li>
                            <ol
                              className="pl-3"
                              type="a"
                              style={{
                                textTransform: "lowercase",
                              }}
                            >
                              {notes.contentList.map((content, j) => (
                                <li key={`notes-content-with-subTitle-${i}-${j}`} className="text-light-dark font-size-12 font-weight-500 mb-2">
                                  {content}
                                </li>
                              ))}
                            </ol>
                          </>
                        )}
                      </ul>
                    </li>
                  ))}
                </ol>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="row" style={{ justifyContent: "right" }}>
          <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={handleSaveWarrantyOverviewDetails}>
            {viewOnlyTab.detailsViewOnly ? "Next" : "Save & Next"}
          </button>
        </div> */}
        <div className="row" style={{ justifyContent: "right" }}>
          <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={hideModal}>
            Close
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          {loading ? (
            <LoadingProgress />
          ) : (
            <Box sx={{ typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    className="custom-tabs-div"
                    aria-label="lab API tabs example"
                    onChange={(e, newTabValue) => setTabValue(newTabValue)}
                    centered
                  >
                    <Tab label={`Overview`} value="overview" />
                    <Tab label={`Details`} value="details" />
                  </TabList>
                </Box>
                <TabPanel value={tabValue}>
                  {tabValue === "overview" && viewOverviewTabData()}
                  {tabValue === "details" && viewDetailsTabData()}
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WarrantyOverView;
