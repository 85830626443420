import React, { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import { TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FormGroup, Switch } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, Divider, Tab } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import { Modal } from "react-bootstrap";
import Moment from "react-moment";

import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { DATA_SVC_COMPONENT_DATA_BY_ID_GET, DATA_SVC_PARTS } from "../services/CONSTANTS";
import { ReadOnlyField } from "./common/ReadOnlyField";
import { GRID_STYLE } from "./common/constants";
import LoadingProgress from "./common/Loader";
import UploadFilesModal from "./warrantyManagement/UploadFilesModal";
import EqupComponentPartsAdd from "./EqupComponentPartsAdd";
import { fetchPartsByIds } from "../services/EquipmentService";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

const EqupComponentOverviewModal = ({ show, hideModal, componentRow, handleSnack }) => {
  const [activeTab, setActiveTab] = useState("generalDetails");
  const [showgeneralDetailsModal, setShowGeneralDetailsModal] = useState(false);

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [uploadFileImage, setUploadFileImage] = useState("general");

  const [openAddPartModal, setOpenAddPartModal] = useState(false);

  const [editGeneralDtl, setEditGeneralDtl] = useState(false);
  const [componentRecord, setComponentRecord] = useState(null);

  const [loading, setLoading] = useState(false);
  const [partLists, setPartLists] = useState([]);
  const [partsLoading, setPartsLoading] = useState(false);
  const [updatePartsTable, setUpdatePartsTable] = useState(0);

  useEffect(() => {
    if (componentRow) {
      setLoading(true);
      const rUrl = `${DATA_SVC_COMPONENT_DATA_BY_ID_GET}${componentRow.id}`;
      callGetApi(
        null,
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            setLoading(false);
            const responseData = response.data;
            // if (responseData.partIds && responseData.partIds.length > 0) {
            //   setPartsLoading(true);
            //   getComponeItems(responseData.partIds);
            // }

            fetchPartsDetails(responseData["sparePartsQuantities"]);
            setComponentRecord({
              ...responseData,
              manufacturer: responseData?.manufacturer || "Makino",
            });
          } else {
            handleSnack("error", "Error fetching component data");
            setLoading(false);
          }
        },
        (error) => {
          handleSnack("error", "Error fetching component data");
          setLoading(false);
        }
      );
    }
  }, [componentRow]);

  const fetchPartsDetails = useCallback(
    async (partIds) => {
      try {
        if (partIds && Object.keys(partIds).length > 0) {
          setPartsLoading(true);
          let partIdsUrl = Object.keys(partIds)
            .map((key) => `ids=${key}`)
            .join("&");
          const partRes = await fetchPartsByIds(partIdsUrl);

          partRes.forEach((part) => {
            const id = part.id;
            if (partIds[id]) {
              part.quantity = partIds[id];
              // part.required = partIds[id];
              // part.availableItems = Math.floor(Math.random() * 5) + 10;
            }
          });
          setPartLists(partRes);
          setPartsLoading(false);
        }
      } catch (error) {
        setPartsLoading(false);
        console.error("Error fetching Parts:", error);
      } finally {
        setPartsLoading(false);
      }
    },
    [updatePartsTable]
  );

  // get the component items list
  const getComponeItems = useCallback(
    async (partIds) => {
      setPartsLoading(true);

      let rUrl = `${DATA_SVC_PARTS}by-ids?` + partIds.map((item) => `ids=${item}`).join("&");
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setPartLists(responseData);
            setPartsLoading(false);
          } else {
            setPartLists([]);
            setPartsLoading(false);
          }
        },
        (error) => {
          setPartLists([]);
          setPartsLoading(false);
        }
      );
      // const _partsList = await fetchSubComponents(partIds);
      // setPartLists(_partsList);
      // setPartsLoading(false);
    },
    [updatePartsTable]
  );

  const assemblyColumns = [
    {
      field: "partNumber",
      headerName: "Part #",
      flex: 1,
      // renderCell: ({row}) => params.row.partNumber,
    },
    {
      field: "partName",
      headerName: "Part Name",
      flex: 1,
      renderCell: ({ row }) => row.partDescription,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: ({ row }) => row.quantity || "1",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => params.row.status || "Closed",
    },
    {
      field: "mandatory",
      headerName: "M/O",
      flex: 1,
      renderCell: (params) => params.row.mandatory || "Mandatory",
    },
  ];

  // change the input text value
  const handleGenDtlsTextChange = (e) => {
    const { name, value } = e.target;
    setComponentRecord({ ...componentRecord, [name]: value });
  };

  // general tab
  const viewGeneralDetailsTab = () => {
    return (
      <>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Details</h4>
            <div className="cursor" onClick={() => setEditGeneralDtl(!editGeneralDtl)}>
              <Tooltip title="Edit">
                <ModeEditOutlineOutlinedIcon />
              </Tooltip>
            </div>
          </div>
          <div className="card border px-3">
            {editGeneralDtl ? (
              <div className="row mt-2 input-fields">
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">COMPONENT CODE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="componentCode"
                      value={componentRecord?.componentCode}
                      disabled
                      placeholder="Component Code"
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">COMPONENT DESCRIPTION</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="description"
                      value={componentRecord?.description}
                      placeholder="Component Description"
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="serialNumber"
                      value={componentRecord?.serialNumber}
                      placeholder="Serial Number"
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="model"
                      value={componentRecord?.model}
                      placeholder="Model"
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="manufacturer"
                      value={componentRecord?.manufacturer}
                      placeholder="Manufacturer"
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="reference"
                      placeholder="Reference"
                      value={componentRecord?.reference}
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER NAME</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="supplierName"
                      placeholder="Supplier Name"
                      value={componentRecord?.supplierName}
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">FEATURE CODE</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="featureCode"
                      placeholder="Feature Code"
                      value={componentRecord?.featureCode}
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">FEATURE DESCRIPTION</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="featureDescription"
                      placeholder="Feature Description"
                      value={componentRecord?.featureDescription}
                      onChange={handleGenDtlsTextChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">WARRANTY START DATE</label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          closeOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">WARRANTY END DATE</label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          closeOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                //   style: FONT_STYLE,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">IN WARRANTY</label>
                    <input type="text" className="form-control border-radius-10 text-primary" name="customerId" placeholder="Active" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row mt-2 ">
                  <ReadOnlyField className="col-md-4 col-sm-4" label="COMPONENT CODE" value={componentRecord?.componentCode} />
                  <ReadOnlyField className="col-md-4 col-sm-4" label="REFERENCE" value={componentRecord?.reference} />
                  <ReadOnlyField className="col-md-4 col-sm-4" label="SUPPLIER NAME" value={componentRecord?.supplierName} />
                  <ReadOnlyField className="col-md-4 col-sm-4" label="FEATURE CODE" value={componentRecord?.featureCode} />
                  <ReadOnlyField className="col-md-4 col-sm-4" label="FEATURE DESCRIPTION" value={componentRecord?.featureDescription} />
                  <ReadOnlyField
                    className="col-md-4 col-sm-4"
                    label="WARRANTY START DATE"
                    value={componentRecord?.warrantyStartDate ? <Moment format="DD/MM/YYYY">{componentRecord?.warrantyStartDate}</Moment> : "NA"}
                  />
                  <ReadOnlyField
                    className="col-md-4 col-sm-4"
                    label="WARRANTY END DATE"
                    value={componentRecord?.warrantyEndDate ? <Moment format="DD/MM/YYYY">{componentRecord?.warrantyEndDate}</Moment> : "NA"}
                  />
                  <ReadOnlyField className="col-md-4 col-sm-4" label="IN WARRANTY" value={componentRecord?.warranty ? "ACTIVE" : "INACTIVE"} />
                </div>
              </>
            )}
          </div>
          <h5 className="mt-4">Map</h5>
          <div className="card border">
            <div className="d-flex justify-content-center">
              <img
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLUYCe0w33j9Ct2Nc-iDztMwxieArUhDHZaA&s"
                src="../assets/images/Compressor_component_code.png"
                alt="jcb"
                className="img-fluid w-100 m-3 bg-white"
                style={{ height: "300px", objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  // assembly tab
  const viewAssemblyTab = () => {
    return (
      <>
        <div>
          <div className="d-flex justify-content-between mb-2">
            <h4>Assembly</h4>
            <div className="cursor" onClick={() => setOpenAddPartModal(true)}>
              Add Part <AddIcon />
            </div>
          </div>
          <div
            style={{
              maxHeight: 420,
              width: "100%",
              overflowY: "auto",
            }}
          >
            <DataGrid
              loading={partsLoading}
              rows={partLists}
              autoHeight
              columns={assemblyColumns}
              disableSelectionOnClick
              getRowId={(row) => row.id}
              pageSize={5}
              // rowsPerPageOptions={[5,10, 20, 50]}
              sx={GRID_STYLE}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" centered>
        <Modal.Header>
          <Modal.Title>Component Overview</Modal.Title>
        </Modal.Header>
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <Modal.Body>
              <div className="card border">
                <div className="row">
                  <div className="col-md-4 col-sm-4 py-4 border-right">
                    <div className="d-flex  px-3">
                      <div className="text-primary">
                        <WarningAmberIcon className="mr-2" />
                        <span>Changes made here impact other things</span>
                      </div>
                      {/* <div className="cursor px-2 py-1 bg-primary text-white rounded-circle">
                        <ModeEditOutlineOutlinedIcon />
                      </div> */}
                    </div>
                    <div className="d-flex align-items-center mb-2 px-3">
                      <img
                        // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLUYCe0w33j9Ct2Nc-iDztMwxieArUhDHZaA&s"
                        src="../assets/images/Compressor_component_code.png"
                        alt="jcb"
                        className=" img-fluid w-25 m-2 bg-white"
                        style={{ height: "90px", objectFit: "contain" }}
                      />
                      {/* <img src="../assets/images/spare-parts-sm.png" alt="jcb" className=" img-fluid" /> */}
                      <div className="mx-2 mt-1">
                        <h6>{componentRecord?.componentCode || "NA"}</h6>
                        <h6>{componentRecord?.description || "NA"}</h6>
                      </div>
                    </div>
                    <div className=" my-3 px-3">
                      <span className="font-size-14 font-weight-500 border-primary text-primary px-2">Active</span>
                      {/* <span className="font-size-14 font-weight-500 mx-4">Available : 20</span> */}
                    </div>
                    <Divider />
                    <div className="my-4 px-3">
                      <h5>Manufacturer Details</h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Serial Number</div>
                        <div>{componentRecord?.serialNumber || "NA"}</div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Manufacturer</div>
                        <div>{componentRecord?.manufacturer || "NA"}</div>
                      </div>
                    </div>
                    <Divider />
                    <div className="my-4 px-3">
                      <h5>Attatchments</h5>
                      <div className="card border text-black mb-2">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div className="d-flex align-items-center">
                            <DescriptionIcon className="mr-1" /> CAD File.pdf
                          </div>
                          <div className="cursor">
                            <ArrowForwardIosIcon />
                          </div>
                        </div>
                      </div>
                      <div className="card border text-black">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div className="d-flex align-items-center">
                            <DescriptionIcon className="mr-1" /> Software Executable.pdf
                          </div>
                          <div className="cursor">
                            <ArrowForwardIosIcon />
                          </div>
                        </div>
                      </div>
                      <div>
                        <button className="btn bg-primary w-100 text-white" onClick={() => setShowFileUploadModal(true)}>
                          <CloudUploadIcon
                            sx={{
                              fontSize: "32px",
                              marginTop: "-5px",
                            }}
                            className="mr-2"
                          />{" "}
                          <span className="font-size-16">Upload New</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 col-sm-8">
                    <TabContext value={activeTab}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                      >
                        <TabList onChange={(e, tabValue) => setActiveTab(tabValue)}>
                          <Tab label={`GENERAL DETAILS`} value="generalDetails" />
                          <Tab label={`ASSEMBLY`} value="assembly" />
                        </TabList>
                      </Box>
                      <TabPanel value={activeTab}>
                        {activeTab === "generalDetails" && viewGeneralDetailsTab()}
                        {activeTab === "assembly" && viewAssemblyTab()}
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="d-flex justify-content-end mr-3 mb-3">
              <btn className="btn btn-primary" onClick={hideModal}>
                Close
              </btn>
            </div>
          </>
        )}
      </Modal>

      {showFileUploadModal && (
        <UploadFilesModal show={showFileUploadModal} hideModal={() => setShowFileUploadModal(false)} fileTypesArr={fileTypes} />
      )}

      {openAddPartModal && (
        <EqupComponentPartsAdd
          show={openAddPartModal}
          hideModal={() => setOpenAddPartModal(false)}
          handleSnack={handleSnack}
          partLists={partLists}
          setPartLists={setPartLists}
          componentRecord={componentRecord}
          setPartsLoading={setPartsLoading}
          setUpdatePartsTable={setUpdatePartsTable}
          setComponentRecord={setComponentRecord}
        />
      )}
    </>
  );
};

export default EqupComponentOverviewModal;
