import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faWifi, faSearch } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Divider from '@mui/material/Divider';
import SelectFilter from "react-select";
import CachedIcon from "@mui/icons-material/Cached";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import {  Tooltip, Typography } from "@mui/material";

import Select from "react-select";

import { Box, Grid, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tooltip, Card } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
// import CustomerDetails from "./customer/CustomerDetails";
import ViewCustomerDetails from "./ViewCustomerDetails";
import { currencyFormatter } from "./common/utils/currencyFormatter";

const ForAdmin = () => {
  const [showModal, setShowModal] = useState(false);

  const [tabValue, setTabValue] = useState("Overview");

  const handleOpenCustomerDetailsModal = (row) => {
    setShowModal(true);
  };
  // const handleOperator = (e, id) => {
  //     let tempArray = [...querySearchSelector];
  //     let obj = tempArray[id];
  //     obj.selectOperator = e;
  //     tempArray[id] = obj;
  //     setQuerySearchSelector([...tempArray]);
  //   };
  //   const handleSearchCategory = (e, id) => {
  //     let tempArray = [...querySearchSelector];
  //     let obj = tempArray[id];
  //     obj.selectCategory = e;
  //     obj.inputSearch = "";
  //     tempArray[id] = obj;
  //     setQuerySearchSelector([...tempArray]);
  //   };

  const handleClose = () => {
    setShowModal(false);

    setTabValue("Overview");
  };

  const options = [
    { value: "All Time", label: "All Time" },

    { value: "1 month", label: "1 month" },
    { value: "3 months", label: "3 months" },
    { value: "6 months", label: "6 months" },
  ];

  const data = [
    {
      id: 1,
      name: "JOHN BAKER",
      country: "USA",
      plan: "Basic Plan",
      billingdate: "Dec 2 2023",
      amount: 10,
      users: "10 Users",
    },
    {
      id: 2,
      name: "JENNY WILSON",
      country: "USA",
      plan: "Basic Plan",
      billingdate: "Dec 2 2023",
      amount: 10,
      users: "10 Users",
    },
    {
      id: 3,
      name: "RAY HAWKINS",
      country: "USA",
      plan: "Basic Plan",
      billingdate: "Dec 2 2023",
      amount: 10,
      users: "10 Users",
    },
    {
      id: 4,
      name: "RALPH EDWARDS",
      country: "USA",
      plan: "Basic Plan",
      billingdate: "Dec 2 2023",
      amount: 10,
      users: "10 Users",
    },
    {
      id: 5,
      name: "JOHN BAKER",
      country: "USA",
      plan: "Basic Plan",
      billingdate: "Dec 2 2023",
      amount: 10,
      users: "10 Users",
    },
  ];
  const column = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 70,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <AccountCircleIcon fontSize="large" style={{ fontSize: 25, marginRight: "8px" }} />
          <span>{params.row.name}</span>
        </div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      width: 70,
    },
    {
      field: "plan",
      headerName: "Plan",
      flex: 1,
      width: 70,
    },
    {
      field: "billingdate",
      headerName: "Billing Date",
      flex: 1,
      width: 130,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      width: 70,
      renderCell: ({ row }) => "INR " + currencyFormatter.format(row.amount),
    },
    {
      field: "users",
      headerName: "Users",
      flex: 1,
      width: 70,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleOpenCustomerDetailsModal(row)}>
                <Tooltip title="Customer Details">
                  <VisibilityOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <div>
        <div className="content-body">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <h4 className="font-weight-600 my-4">Customers</h4>
            </div>
            <div className="card border px-3 my-3  ">
              <div className="d-flex row my-4 pb-3">
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <h6>Total Customer</h6>

                    <div className="d-flex align-items-baseline justify-content-between mt-4">
                      <h1 className="text-primary mb-0">2,240</h1>
                      <span className="d-flex align-items-center text-success p-2 bg-grey rounded-pill ml-3">
                        <FontAwesomeIcon icon={faArrowUp} className="mr-1" />
                        20%
                      </span>
                    </div>
                  </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />

                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <h6>Members</h6>

                    <div className="d-flex align-items-baseline justify-content-between mt-3">
                      <h1 className="text-primary mb-0">1,120</h1>
                      <span className="d-flex align-items-center text-success p-2 bg-grey rounded-pill ml-3">
                        <FontAwesomeIcon icon={faArrowUp} className="mr-1" />
                        15%
                      </span>
                    </div>
                  </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />

                <div className="col-md-3 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <h6>Active Now</h6>

                    <div className="d-flex align-items-baseline justify-content-between mt-3">
                      <h1 className="text-primary mb-0">316</h1>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              <div className="d-flex justify-content-between align-items-center   my-2 w-100">
                <div className="d-flex align-items-center   px-3 ">
                  <h5>Customer Search</h5>
                  <div className="row  ">
                    <div className="col-12 mx-2">
                      <div className="d-flex justify-content-between align-items-center  ">
                        <div className="row align-items-center ml-3">
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div>
                              <SelectFilter />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{
                                  position: "relative",
                                }}
                                type="text"
                                placeholder="Search string"
                                autoComplete="off"
                              />
                              <div className="btn bg-primary text-white">
                                <span className="mr-2">
                                  <SearchIcon />
                                </span>
                                SEARCH
                              </div>
                            </div>
                          </div>
                          <div>
                            <a
                              className="btn-sm  border mr-2 cursor"
                              style={{
                                border: "1px solid #872FF7",
                              }}
                            >
                              <Tooltip title="Reset">
                                <CachedIcon />
                              </Tooltip>
                            </a>
                          </div>
                          <div>
                            <Link
                              to="#"
                              className="btn-sm text-black border mr-2"
                              style={{
                                border: "1px solid #872FF7",
                              }}
                            >
                              <span>+</span>
                            </Link>
                          </div>

                          <div>
                            <Link to="#" className="btn-sm border mr-2">
                              <i className="fa fa-trash fa-lg"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  <Select className=" text-primary  " defaultValue={options[0]} options={options} />
                  {/* </div> */}
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center "
                style={{
                  height: 400,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#872ff9",
                      color: "#fff",
                    },
                  }}
                  rows={data}
                  columns={column}
                  checkboxSelection
                  rowsPerPageOptions={[5, 10, 20]}
                  rowCount={100}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      {showModal && <ViewCustomerDetails show={showModal} hideModal={() => setShowModal(false)} data={data} column={column} />}
    </>
  );
};

export default ForAdmin;
