import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PARTS_ONLY, SERVICE_ONLY, WITH_PARTS } from "./CONSTANTS";
import { addToCart } from "../services/portfolioServices";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddEquipments from "./AddEquipments";
import { PartsContext } from "../context/PartsContext";
import { API_SUCCESS } from "../services/ResponseCode";
import { currencyFormatter } from "./common/utils/currencyFormatter";

export default function PortfolioConfig(props) {
  const { cartItems, setCartItems, portfolioCarts, setPortfolioCarts } = useContext(PartsContext);
  const [selectedMisc, setSelectedMisc] = useState([]);
  const history = useNavigate();
  const [selectedOpServices, setSelectedOpServices] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const { selectedPortfolio, setSelectedPortfolio, family, model, prefix, handleSnack, showBackbutton } = props;
  const { portfolioCart } = selectedPortfolio;
  const [labourRequired, setLabourRequired] = useState(portfolioCart ? portfolioCart.labourRequired : true);
  let partsChoice =
    portfolioCart?.partsRequired && portfolioCart?.labourRequired ? WITH_PARTS : portfolioCart?.labourRequired ? SERVICE_ONLY : PARTS_ONLY;
  const [choiceOfParts, setChoiceOfParts] = useState(portfolioCart ? partsChoice : WITH_PARTS);
  const [showEquipments, setShowEquipments] = useState(false);
  const [coverages, setCoverages] = useState([]);
  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2);
  };
  const handleMisc = (event) => {
    var updatedList = [...selectedMisc];
    if (event.target.checked) {
      updatedList = [...selectedMisc, event.target.value];
    } else {
      updatedList.splice(selectedMisc.indexOf(event.target.value), 1);
    }
    setSelectedMisc(updatedList);
  };
  const handleChangeChoiceParts = (e) => {
    if (!e.target.checked) {
      handleSnack("warning", "Please select one of the options from choice of spare parts");
    } else {
      setChoiceOfParts(e.target.name);
      if (e.target.name === PARTS_ONLY) setLabourRequired(false);
      else setLabourRequired(true);
    }
  };

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...selectedOpServices];
    if (event.target.checked) {
      updatedList = [...selectedOpServices, event.target.value];
    } else {
      updatedList.splice(selectedOpServices.indexOf(event.target.value), 1);
    }
    setSelectedOpServices(updatedList);
  };
  const addPortfolioToCart = async () => {
    if (cartItems && cartItems?.parts.length > 0) {
      handleSnack("info", "There are some parts left in your cart.");
      return;
    }
    let _portfolioCarts = [...portfolioCarts];

    const exists = _portfolioCarts.some((item) => item.portfolioId === selectedPortfolio.portfolioId);

    if (!exists) {
      _portfolioCarts.push({ ...selectedPortfolio, quantity: quantity });
      setPortfolioCarts(_portfolioCarts);

      handleSnack("info", `Portfolio ${selectedPortfolio?.name} has been added to cart successfully!`);
    } else {
      handleSnack("info", `Portfolio ${selectedPortfolio?.name} already exits into cart!`);
    }

    // let selectedCoverageId = selectedPortfolio.coverages?.length > 0 ? "?coverageId=" + selectedPortfolio?.coverages[0]?.coverageId : "";
    // let config = {
    //   quantity: quantity,
    //   optionalServices: selectedOpServices.toString(),
    //   partsRequired: choiceOfParts === WITH_PARTS || choiceOfParts === PARTS_ONLY ? true : false,
    //   serviceRequired: choiceOfParts === WITH_PARTS || choiceOfParts === SERVICE_ONLY ? true : false,
    //   labourRequired: labourRequired,
    //   miscRequired: selectedMisc?.length > 0 ? true : false,
    // };

    // await addToCart(selectedPortfolio.portfolioId, selectedCoverageId, config)
    //   .then((response) => {
    //     const responseData = response.data;
    //     setPortfolioCarts([...responseData["portfolio"]]);
    //     handleSnack("info", `Portfolio ${selectedPortfolio?.name} has been added to cart successfully!`);
    //     // history("/cart");
    //   })
    //   .catch((err) => {
    //     handleSnack("error", err.message);
    //   });
  };

  const PriceComponent = ({ labourRequired, choiceOfParts, miscRequired }) => (
    <Card sx={{ borderRadius: 4 }}>
      <div className="row bg-primary text-white p-2 py-5">
        <div className="col-md-4 col-sm-4 ">
          <span>Total Price</span>
          <Typography sx={{ fontSize: 18 }}>
            {currencyFormatter.format(
              !labourRequired && choiceOfParts !== SERVICE_ONLY
                ? formatPrice(selectedPortfolio.portfolioPrice?.calculatedPrice - selectedPortfolio.portfolioPrice?.labourPrice)
                : !labourRequired && choiceOfParts === SERVICE_ONLY
                ? formatPrice(
                    selectedPortfolio.portfolioPrice?.calculatedPrice -
                      (selectedPortfolio.portfolioPrice?.labourPrice + selectedPortfolio.portfolioPrice?.sparePartsPrice)
                  )
                : formatPrice(selectedPortfolio.portfolioPrice?.calculatedPrice)
            )}
          </Typography>
        </div>
        <div className="col-md-8 col-sm-8 pl-3">
          <div className=" border-left px-2">
            <div className="row">
              <div className="col-md-7 col-sm-7">
                <Typography fontSize={12}>Spare Parts Price </Typography>
              </div>
              <div className="col-md-5 col-sm-5">
                <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.sparePartsPrice))}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 col-sm-7">
                <Typography fontSize={12}>Labour Price </Typography>
              </div>
              <div className="col-md-5 col-sm-5">
                <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.labourPrice))}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 col-sm-7">
                <Typography fontSize={12}>Misc Price </Typography>
              </div>
              <div className="col-md-5 col-sm-5">
                <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.miscPrice))}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Box sx={{ backgroundColor: "#872ff7", color: "#ffffff", width: "100%", p: 2, py: 6 }}>
        <Grid container>
          <Grid item xs={5}>
            <span>Total Price</span>
            <Typography sx={{ fontSize: 25 }}>
              {currencyFormatter.format(
                !labourRequired && choiceOfParts !== SERVICE_ONLY
                  ? formatPrice(selectedPortfolio.portfolioPrice?.totalPrice - selectedPortfolio.portfolioPrice?.labourPrice)
                  : !labourRequired && choiceOfParts === SERVICE_ONLY
                  ? formatPrice(
                      selectedPortfolio.portfolioPrice?.totalPrice -
                        (selectedPortfolio.portfolioPrice?.labourPrice + selectedPortfolio.portfolioPrice?.sparePartsPrice)
                    )
                  : formatPrice(selectedPortfolio.portfolioPrice?.totalPrice)
              )}
            </Typography>
          </Grid>
          <Grid item container xs={7}>
            <Grid item xs={9}>
              <Typography fontSize={12}>Spare Parts Price </Typography>
            </Grid>
            <Grid item xs={3}>
              <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.sparePartsPrice))}</strong>
            </Grid>
            <Grid item xs={9}>
              <Typography fontSize={12}>Labour Price </Typography>
            </Grid>
            <Grid item xs={3}>
              <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.labourPrice))}</strong>
            </Grid>
            <Grid item xs={9}>
              <Typography fontSize={12}>Misc Price</Typography>
            </Grid>
            <Grid item xs={3}>
              {" "}
              <strong>{currencyFormatter.format(formatPrice(selectedPortfolio.portfolioPrice?.miscPrice))}</strong>
            </Grid>
          </Grid>
        </Grid>
      </Box> */}
    </Card>
  );
  const handleClose = () => {
    setShowEquipments(false);
  };
  return (
    <>
      <AddEquipments show={showEquipments} handleClose={handleClose} coverages={coverages} setCoverages={setCoverages} />
      <Box sx={{ border: 1, borderRadius: 3, borderColor: "lightgrey" }}>
        <Grid container sx={{ marginTop: 2 }}>
          <Grid item container xs={12} md={10}>
            <Typography sx={{ fontWeight: "600", fontSize: 17, color: "#4f505b", pl: 3 }}>{selectedPortfolio.name}</Typography>
          </Grid>
          {showBackbutton && (
            <Grid item container xs>
              <button className="btn border-primary text-primary text-right text-uppercase font-weight-500" onClick={() => setSelectedPortfolio("")}>
                <ArrowBackIcon fontSize="medium" sx={{ mr: 1 }} />
                Search Results
              </button>
              {/* <Button variant="contained" className="search-button text-right" onClick={() => setSelectedPortfolio("")}>
                <ArrowBackIcon fontSize="medium" sx={{ mr: 1 }} />
                Search Results
              </Button> */}
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 1 }} />

        <Grid container>
          <Grid item xs={12} md={8} sx={{ p: 2 }}>
            <Card sx={{ px: 1, pt: 1, color: "grey", height: 600 }} variant="outlined">
              <div className="px-2">
                <Card variant="outlined" sx={{ backgroundColor: "#ebf8ff", border: 2, borderColor: "#86b8ef", py: 2, px: 1, color: "#1d7ae2" }}>
                  <WorkspacePremiumIcon fontSize="large" /> Your Service is backed by our 12 months, 12,000 miles warranty.{" "}
                  <strong>Learn More</strong>
                </Card>
              </div>

              <Card sx={{ p: 4, m: 1 }} elevation={4}>
                <Typography sx={{ fontWeight: "500", fontSize: 17, color: "#4f505b" }}>{selectedPortfolio.description}</Typography>
              </Card>
              <Card className="optional-services-list" sx={{ p: 4, m: 1, overflow: "hidden", overflowY: "scroll", height: 390 }} elevation={4}>
                <Typography sx={{ fontWeight: "500", fontSize: 17, color: "#4f505b" }}>Select Optional Service</Typography>
                <FormControl sx={{ my: 2, mx: 1, width: "100%" }} component="fieldset" variant="standard">
                  <FormGroup>
                    <Grid container>
                      {selectedPortfolio.optionalServices?.split(",").map((optionalService) => (
                        <Grid item xs={12} sm={6}>
                          <Card variant="outlined" sx={{ py: 1, px: 2, m: 2 }}>
                            <FormControlLabel
                              control={<Checkbox onChange={handleCheck} name={optionalService} value={optionalService} />}
                              label={optionalService}
                            />
                          </Card>
                          <Typography sx={{ fontSize: 12, px: 2 }}>
                            The objective description focuses on an impersonal story form and uses a general point of view. On the other hand, the
                            subjective description shows a personal point of view, that is, the author’s thoughts and feelings are involved.
                          </Typography>
                        </Grid>
                      ))}
                      {!selectedPortfolio.optionalServices && (
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" sx={{ py: 1, my: 2, color: "#8d9497" }}>
                            No Optional Services Found
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Card>
            </Card>
          </Grid>

          <Grid item container xs={12} md={4} sx={{ p: 2 }}>
            <Card sx={{ px: 1, pt: 1, color: "grey" }} variant="outlined">
              <Grid item xs={12}>
                <PriceComponent labourRequired={labourRequired} />
              </Grid>
              <Grid
                item
                xs={12}
                className="portfolio-config-service-toggle"
                sx={{
                  height: 400,
                  overflow: "hidden",
                  overflowY: "scroll",
                  p: 2,
                }}
              >
                {/* <Typography fontSize={12}>
                                    {family} &nbsp; {model} &nbsp; {prefix}
                                </Typography> */}
                {/* <FormControl variant="outlined" size="small" margin='dense' sx={{ minWidth: 90, fontSize: 12, padding: 0, margin: 0 }}>
                                <InputLabel>Quantity</InputLabel>
                                 <Select
                                    value={quantity}
                                    displayEmpty
                                    onChange={e => setQuantity(e.target.value)}
                                    label="Quantity"
                                    MenuProps={{ style: { maxHeight: 1 } }}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num =>
                                        <MenuItem value={num} sx={{ fontSize: 13, mx: 1 }}>
                                            {num}
                                        </MenuItem>)}
                                </Select> 
                                
                            </FormControl>*/}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontSize={12}>Customer Name</Typography>
                  <Button onClick={() => setShowEquipments(true)}>Add Equipments</Button>
                </Stack>
                <Card sx={{ borderRadius: 5, mt: 1 }} variant="outlined">
                  <Typography variant="h6" fontSize={17} className="config-labels" marginBottom={2} paddingLeft={1}>
                    Choice Of Spare Parts
                  </Typography>
                  <FormGroup sx={{ fontSize: 12, mx: 3 }}>
                    <FormControlLabel
                      control={
                        <Switch size="small" name={WITH_PARTS} checked={WITH_PARTS === choiceOfParts} onChange={(e) => handleChangeChoiceParts(e)} />
                      }
                      label={<Typography fontSize={14}>With Spare Parts</Typography>}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          name={SERVICE_ONLY}
                          checked={SERVICE_ONLY === choiceOfParts}
                          onChange={(e) => handleChangeChoiceParts(e)}
                        />
                      }
                      label={<Typography fontSize={14}>Without Spare Parts</Typography>}
                    />
                    <FormControlLabel
                      control={
                        <Switch size="small" name={PARTS_ONLY} checked={PARTS_ONLY === choiceOfParts} onChange={(e) => handleChangeChoiceParts(e)} />
                      }
                      label={<Typography fontSize={14}>Only Spare Parts</Typography>}
                    />
                  </FormGroup>
                </Card>
                <Card sx={{ borderRadius: 5, mt: 1 }} variant="outlined">
                  <Typography variant="h6" fontSize={17} className="config-labels" marginBottom={2} paddingLeft={1}>
                    Choice Of Labor
                  </Typography>
                  <FormGroup sx={{ fontSize: 12, mx: 3 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={labourRequired}
                          disabled={choiceOfParts === "partsOnly"}
                          onChange={(e) => setLabourRequired(e.target.checked)}
                        />
                      }
                      label={<Typography fontSize={14}>I need labor</Typography>}
                    />
                  </FormGroup>
                </Card>
                <Card sx={{ borderRadius: 5, mt: 1 }} variant="outlined">
                  <Typography variant="h6" fontSize={17} className="config-labels" marginBottom={2} paddingLeft={1}>
                    Choice Of Misc.
                  </Typography>
                  <FormGroup sx={{ fontSize: 12, mx: 3 }}>
                    <FormControlLabel
                      control={<Switch size="small" name={"consumable"} value="consumable" onChange={handleMisc} />}
                      label={<Typography fontSize={14}>Require consumables</Typography>}
                    />
                    <FormControlLabel
                      control={<Switch size="small" name={"tools"} value="tools" onChange={handleMisc} />}
                      label={<Typography fontSize={14}>Require assistance with tools</Typography>}
                    />
                    <FormControlLabel
                      control={<Switch size="small" name={"movement"} value="movement" onChange={handleMisc} />}
                      label={<Typography fontSize={14}>Require assistance with movement</Typography>}
                    />
                    <FormControlLabel
                      control={<Switch size="small" name={"extWork"} value="extWork" onChange={handleMisc} />}
                      label={<Typography fontSize={14}>External Work</Typography>}
                    />
                  </FormGroup>
                </Card>
                <Card sx={{ borderRadius: 5, mt: 1 }} variant="outlined">
                  <Typography variant="h6" fontSize={17} className="config-labels" marginBottom={2} paddingLeft={1}>
                    Optional Services
                  </Typography>
                  <div className="mx-3">
                    {selectedOpServices?.length > 0 ? (
                      selectedOpServices.map((indOpService) => <Typography sx={{ fontSize: 14, color: "#4f505b", my: 1 }}>{indOpService}</Typography>)
                    ) : (
                      <Typography sx={{ fontSize: 14, color: "#4f505b", my: 1 }}>No Optional Services</Typography>
                    )}
                  </div>
                </Card>
                <Card sx={{ borderRadius: 5, mt: 1 }} variant="outlined">
                  <Typography variant="h6" fontSize={17} className="config-labels" marginBottom={2} paddingLeft={1}>
                    Free Services
                  </Typography>
                  <div className="mx-3">
                    {selectedPortfolio.freeServices && selectedPortfolio.freeServices.length > 0 ? (
                      <>
                        {selectedPortfolio.freeServices?.split(",").map((indFreeService) => (
                          <Typography sx={{ fontSize: 14, color: "#4f505b", my: 1 }}>
                            <span
                              style={{
                                backgroundColor: "#50ba49",
                                color: "#ffffff",
                                padding: 5,
                                marginRight: 7,
                                fontSize: 10,
                                borderRadius: 7,
                              }}
                            >
                              Free
                            </span>
                            {indFreeService}
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <Typography sx={{ fontSize: 14, color: "#4f505b", my: 1 }}>No Free Services</Typography>
                    )}
                  </div>
                </Card>
                <button className="btn bg-primary text-white mt-3" style={{ width: "100%" }} onClick={addPortfolioToCart}>
                  Add To Cart
                </button>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
