import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import { Divider } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
const BulkUploadModal = ({ show, hideModal, handleUploadFile }) => {
  return (
    <div>
      <Modal show={show} onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="container-fluid " style={{ background: "#D0E1EF" }}>
          <div className="card border rounded m-3">
            <div className="d-flex align-items-center justify-content-between   pl-4 mt-4">
              <h4>Bulk Upload Data</h4>
              <CloseIcon sx={{ marginRight: "10px" }} />
            </div>
            <p className="pl-4">Upload Your Data Through csv or xls file</p>
            <div className="d-flex align-items-center justify-content-between   pl-4 mt-4">
              <h4>Step 1 : Download Sample Template</h4>
              <div className="btn text-black bg-grey mr-3 ">
                <span className="pr-2">
                  <PublishIcon sx={{ transform: "rotate(180deg)", fontSize: "30px" }} />
                </span>
                Download Sample
              </div>
            </div>
            <p className="pl-4">
              Download sample template by clicking the button below.
              <br /> You can add the types data according to the template file
            </p>
            <Divider variant="middle" component="li" />

            <div className="d-flex align-items-center justify-content-between   pl-4 mt-4">
              <h4>Step 2 : Upload CSV/XLS</h4>
              <div className="btn text-black bg-grey mr-3 px-5" onClick={handleUploadFile}>
                <span className="pr-2">
                  <AttachFileIcon sx={{ transform: "rotate(45deg)", fontSize: "30px" }} />
                </span>
                Upload File
              </div>
            </div>
            <p className="pl-4">Upload the edited template by clicking the button below</p>
            <Divider variant="middle" component="li" />

            <div className="d-flex justify-content-end my-4">
              <button type="button" className="btn text-black bg-grey btn-md mr-3 rounded" onClick={hideModal}>
                Cancel
              </button>
              <button type="button" className="btn text-white bg-primary btn-md  mr-3">
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BulkUploadModal;
