import React from "react";

import ImageZoom from "react-image-zoom";

const ImageMagnifier = ({ src }) => {
  const props = {
    width: 300,
    height: 300,
    zoomWidth: 300,
    img: src || "../assets/images/partDefaultImage.png",
    zoomPosition: "original",
    zoomLensStyle: {
      // zIndex: 9999999999,
      opacity: "0.7",
      // backgroundColor: "green",
    },
  };
  return (
    <>
      <div style={{ width: "300px", height: "300px", zIndex: 9999999999 }}>
        <ImageZoom {...props} />;
      </div>
    </>
  );
};

export default ImageMagnifier;
