import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT } from "../common/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";

import { deliveryPriorityOptions, shippingCondition, shippingPointOptions } from "./orderConstents";

const OrderShippingDetails = ({ show, hideModal, handleSnack, orderRecord, setOrderRecord, setLabourCharge }) => {
  const [expendAlternateAddress, setExpendAlternateAddress] = useState(false);
  const [addressObj, setAddressObj] = useState({
    deliveryType: orderRecord?.deliveryType,
    deliveryPriority: orderRecord?.deliveryPriority,
    leadTime: orderRecord?.leadTime,
    shippingCondition: orderRecord?.shippingCondition,
    shippingPoint: orderRecord?.shippingPoint,
    pickUpInst: orderRecord?.pickUpInst,
    shippingAddress: orderRecord?.shippingAddress,
    partialShipment: orderRecord?.partialShipment || false,
    machineBreakdown: orderRecord?.machineBreakdown || false,
    needInstallationHelp: orderRecord?.needInstallationHelp || false,
    serialNumber: orderRecord?.serialNumber || "",
    model: orderRecord?.model || "",
  });

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setAddressObj({ ...addressObj, [name]: value });
  };

  // select value change
  const handleSelectChange = (e, keyName) => {
    setAddressObj({ ...addressObj, [keyName]: e });
  };

  // select alternate address
  const handleSelectAlternateAddress = (e) => {
    setAddressObj({ ...addressObj, shippingAddress: e.target.value });
  };

  // change the toggle switch check-uncheck
  const handleChangeToggle = (e) => {
    const { name, checked } = e.target;

    let _serialNumber = addressObj?.serialNumber;
    let _model = addressObj?.model;

    if (name === "machineBreakdown") {
      _serialNumber = "";
      _model = "";
    }
    setAddressObj({ ...addressObj, [name]: checked, serialNumber: _serialNumber, model: _model });
  };

  // save the address details
  const handleSaveChanges = () => {
    if (addressObj?.needInstallationHelp) {
      setLabourCharge(500);
    } else {
      setLabourCharge(0);
    }
    setOrderRecord({ ...orderRecord, ...addressObj });
    hideModal();
  };

  // cancel the change address details
  const handleCancelChanges = () => {
    hideModal();
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Shipping Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row input-fields">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
              <Select
                onChange={(e) => handleSelectChange(e, "deliveryType")}
                value={addressObj?.deliveryType}
                options={[
                  { label: "Standard", value: "STANDARD" },
                  { label: "Express", value: "EXPRESS" },
                ]}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">DELIVERY PRIORITY</label>
              <Select
                onChange={(e) => handleSelectChange(e, "deliveryPriority")}
                value={addressObj?.deliveryPriority}
                options={deliveryPriorityOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SHIPPING CONDITION</label>
              <Select
                onChange={(e) => handleSelectChange(e, "shippingCondition")}
                value={addressObj?.shippingCondition}
                options={shippingCondition}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group date-box">
              <label className="text-light-dark font-size-12 font-weight-500">LEAD TIME</label>
              <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                <input
                  type="text"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="20%"
                  id="leadTime"
                  name="leadTime"
                  onChange={handleInputTextChange}
                  value={addressObj?.leadTime}
                />
                <span className="hours-div text-primary">{"Days"}</span>
              </div>
            </div>
          </div>
          {addressObj?.shippingCondition?.value === "PICKUP" && (
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PICKUP INSTRUCTIONS</label>
                <div className="form-group w-100">
                  <textarea
                    id="serviceRecipentAddress"
                    name="pickUpInst"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleInputTextChange}
                    value={addressObj?.pickUpInst}
                    placeholder="Pickup Instructions"
                    cols="30"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>
        {addressObj?.shippingCondition?.value === "SHIP_TO_ME" && (
          <div className="row input-fields">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SHIPPING POINT</label>
                <Select
                  onChange={(e) => handleSelectChange(e, "shippingPoint")}
                  value={addressObj?.shippingPoint}
                  options={shippingPointOptions}
                  placeholder="Select..."
                  styles={FONT_STYLE_SELECT}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SERVICE RECIPIENT ADDRESS</label>
                <div className="form-group w-100">
                  <input
                    type="text"
                    id="serviceRecipentAddress"
                    name="shippingAddress"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleInputTextChange}
                    value={addressObj?.shippingAddress}
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <Accordion
          // sx={{ backgroundColor: "#f3eafe" }}
          sx={{ marginBottom: 2 }}
          expanded={expendAlternateAddress}
          onChange={() => setExpendAlternateAddress(!expendAlternateAddress)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ marginY: 0 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Select Alternate Address
            </Typography>
          </AccordionSummary>
          <Divider sx={{ marginY: 0 }} />
          <AccordionDetails>
            <FormGroup>
              <RadioGroup>
                <FormControlLabel
                  value="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
                  control={<Radio checked={addressObj?.shippingAddress === "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"} />}
                  label="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="1 Alan St, Rydalmere NSW 2116, Australia"
                  control={<Radio checked={addressObj?.shippingAddress === "1 Alan St, Rydalmere NSW 2116, Australia"} />}
                  label="1 Alan St, Rydalmere NSW 2116, Australia"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="8501 Willow Avenue, Los Angeles, CA 90037"
                  control={<Radio checked={addressObj?.shippingAddress === "8501 Willow Avenue, Los Angeles, CA 90037"} />}
                  label="8501 Willow Avenue, Los Angeles, CA 90037"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="9653 Richardson Circle, Compton, CA 90221"
                  control={<Radio checked={addressObj?.shippingAddress === "9653 Richardson Circle, Compton, CA 90221"} />}
                  label="9653 Richardson Circle, Compton, CA 90221"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
              </RadioGroup>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <div className="hr"></div>
        <h5 className="mb-1">Other Details</h5>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            checked={addressObj?.partialShipment}
            onChange={handleChangeToggle}
            name="partialShipment"
            label={"Partial Delivery"}
          />
          <FormControlLabel
            control={<Switch />}
            checked={addressObj?.needInstallationHelp}
            onChange={handleChangeToggle}
            name="needInstallationHelp"
            label={"Need help with installation"}
          />
          <FormControlLabel
            control={<Switch />}
            checked={addressObj?.machineBreakdown}
            name="machineBreakdown"
            onChange={handleChangeToggle}
            label={"Machine breakdown"}
          />
          {addressObj?.machineBreakdown && (
            <>
              <div className="row input-fields mb-0">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={addressObj?.serialNumber}
                      onChange={handleInputTextChange}
                      name="serialNumber"
                      placeholder="Serial Number"
                      disabled={!addressObj?.machineBreakdown}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={addressObj?.model}
                      onChange={handleInputTextChange}
                      name="model"
                      placeholder="Model"
                      disabled={!addressObj?.machineBreakdown}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="hr mt-0"></div>
        </FormGroup>
        <h5 className="mb-0">Includes</h5>
        <div className="align-items-start mt-3">
          <h6>
            <a href="#" className="btn-sm bg-success border text-white mr-2">
              FREE
            </a>
            Free Shipping
          </h6>
          {addressObj?.needInstallationHelp && (
            <h6 className="mt-2">
              <a href="#" className="btn-sm bg-gray border text-white mr-2">
                CHARGABLE
              </a>
              Installation charge included
            </h6>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <button className="btn bg-primary text-white w-100" onClick={handleSaveChanges}>
              Save
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <button className="btn border-primary text-primary w-100" onClick={handleCancelChanges}>
              Cancel
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderShippingDetails;
