import { Routes, Route, useNavigate } from "react-router-dom";

// Navigations URL import constants
import {
  claimAdministration,
  claimHistory,
  equipment,
  equipmentMasterComp,
  help,
  login,
  manageWarrantyReturns,
  quoteHistory,
  partsSearch,
  warrantyAdministration,
  partsSegment,
  shoppingCartList,
  adminPage,
  transationPage,
  returnParts,
  quickOrder,
} from "./navigation/navigationConst";

import "./App.css";
import HomeScreen from "../src/pages/HomeScreen";
import Cart from "../src/pages/Cart";
import Login from "./pages/Login";
import Dashboard from "./pages/common/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import EquipmentMaster from "./pages/EquipmentMaster";
import OrderHistoryMaster from "./pages/OrderHistoryMaster";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import AuthInit from "./AuthInit";
import Auth from "./pages/Auth";
import SearchCatalogue from "./pages/SearchCatalogue";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import WarrantyAdministration from "./pages/warrantyManagement/warrantyMaster/WarrantyAdministration";
import ClaimAdministration from "./pages/warrantyManagement/claimMaster/ClaimAdministration";
import WarrantyReturnMaster from "./pages/warrantyManagement/returnMaster/WarrantyReturnMaster";
import EquipmentDashboard from "./pages/warrantyManagement/equipment/EquipmentDashboard";
import AccountHelp from "./pages/user/AccountHelp";
import ClaimHistoryMaster from "./pages/ClaimHistoryMaster";
import PartsSearchDashboard from "./pages/warrantyManagement/parts/PartsSearchDashboard";
import PartsCart from "./pages/warrantyManagement/parts/PartsCart";
import PartsSearchMaster from "./pages/warrantyManagement/parts/PartsSearchMaster";
import PartsSegmentMaster from "./pages/warrantyManagement/equipment/PartsSegmentMaster";
import ShoppingCartList from "./pages/shoppingCart/ShoppingCartList";
import ForAdmin from "./pages/ForAdmin";
import MyTransactions from "./pages/MyTransactions";
import CartList from "./pages/shoppingCart/CartList";
import ReturnParts from "./pages/parts/ReturnParts";
import ReviewCartItem from "./pages/orderItem/ReviewCartItem";
import QuickOrder from "./pages/QuickOrder";

function App() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!auth.customerId && !auth.customerName) {
  //     navigate("/login");
  //   }
  // }, [auth]);
  // console.log(auth.didTryAutoLogin);
  return (
    <div id="main-wrapper" className="show">
      {!auth.didTryAutoLogin ? (
        <AuthInit />
      ) : (
        <Routes>
          <Route path={login} element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Dashboard />}>
            <Route index element={<HomeScreen />} />
            {/* <Route path="/cart" element={<CartList />} /> */}
            <Route path="/cart" element={<ReviewCartItem />} />
            {/* <Route path="/cart" element={<PartsCart />} /> */}
            {/* <Route path="/cart" element={<Cart />} /> */}
            <Route path="/search" element={<SearchCatalogue />} />
            <Route path={help} element={<AccountHelp />} />

            <Route path={equipment} element={<EquipmentMaster />} />
            <Route path={partsSearch} element={<PartsSearchMaster />} />
            {/* <Route path={partsSearch} element={<PartsSearchDashboard />} /> */}
            {/* <Route path={quoteHistory} element={<OrderHistoryMaster />} />
            <Route path={claimHistory} element={<ClaimHistoryMaster />} /> */}
            <Route path={partsSegment} element={<PartsSegmentMaster />} />
            <Route path={returnParts} element={<ReturnParts />} />
            <Route path={shoppingCartList} element={<ShoppingCartList />} />
            <Route path={adminPage} element={<ForAdmin />} />
            <Route path={transationPage} element={<MyTransactions />} />
            <Route path={quickOrder} element={<QuickOrder />} />

            <Route
              path={equipmentMasterComp}
              // element={<EquipmentMasterComponent />}
              element={<EquipmentDashboard />}
            />
            <Route path={warrantyAdministration} element={<WarrantyAdministration />} />
            <Route path={claimAdministration} element={<ClaimAdministration />} />
            <Route path={manageWarrantyReturns} element={<WarrantyReturnMaster />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
