import React from "react";
import { Modal } from "react-bootstrap";
import ImageViewer from "../../common/utils/ImageViewer";

const PartImageViewModal = ({ show, hideModal, src = "", fileType = "" }) => {
  return (
    <>
      <Modal show={show} onHide={hideModal} centered>
        <Modal.Body>
          <div className="card border px-2 py-2">
            <div className="d-flex justify-content-center">
              <ImageViewer src={src} />
            </div>
            {/* {fileType === "CERTIFICATE" || fileType === "PROOF" ? (
              <img src="../../assets/images/rheemProof.jpg" class="card-img-top" alt="File" />
            ) : (
              <img src="../../assets/images/faultyPannel.png" class="card-img-top" alt="File" />
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartImageViewModal;
