import React, { useContext, useState } from "react";
import CustomizedSnackbar from "../../../shared/CustomSnackBar";
import { PartsContext } from "../../../context/PartsContext";
import { Grid, Typography } from "@mui/material";
import PartCartItem from "./PartCartItem";
import { shoppingCartList } from "../../../navigation/navigationConst";
import { NavLink } from "react-router-dom";
import PortfolioCart from "../../portfolioCart/PortfolioCart";
import PortfolioCartItem from "../../portfolioCart/PortfolioCartItem";
import { removeCart } from "../../../services/portfolioServices";
import { currencyFormatter } from "../../common/utils/currencyFormatter";

const PartsCart = () => {
  const { cartParts, setCartParts, portfolioCarts, setPortfolioCarts } = useContext(PartsContext);

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [openQuote, setOpenQuote] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  async function removeItem(portfolioId) {
    if (cartParts.length !== 0) {
      const _cartParts = cartParts.filter((obj) => obj.id !== portfolioId);
      setCartParts(_cartParts);
      handleSnack("success", "Cart item has been removed!");
    } else {
      await removeCart(portfolioId)
        .then((data) => {
          setPortfolioCarts(data.data.portfolio);
          handleSnack("success", "Cart item has been removed!");
        })
        .catch((err) => {
          handleSnack("error", "Something went wrong while removing the cart item!");
          console.log(err.message);
        });
    }
  }

  // view cart items card
  const viewCartsItems = () => {
    return (
      <>
        {portfolioCarts.length === 0 && cartParts.length !== 0
          ? cartParts.map((item) => (
              <Grid item xs={12} md={6} sx={{ p: 4 }}>
                <PartCartItem
                  key={item.portfolioId}
                  item={item}
                  setCartItems={setCartParts}
                  removeItem={removeItem}
                  handleSnack={handleSnack}
                  cartItems={cartParts}
                />
              </Grid>
            ))
          : portfolioCarts.map((item) => (
              <Grid item xs={12} md={6} sx={{ p: 4 }}>
                <PortfolioCartItem
                  key={item.portfolioId}
                  item={item}
                  setCartItems={setPortfolioCarts}
                  removeItem={removeItem}
                  handleSnack={handleSnack}
                />
              </Grid>
            ))}
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid ">
          <div className="my-4">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="card p-4">
                  <h5 className="mb-0">Shopping Cart</h5>
                  <Grid container spacing={2}>
                    {cartParts.length === 0 && portfolioCarts.length === 0 ? (
                      <Grid item xs={12} md={12} sx={{ paddingTop: 4 }}>
                        <Typography fontSize={16} fontWeight={500}>
                          No items into cart
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={9}>
                          <div className="card" style={{ overflow: "hidden" }}>
                            <Grid container>{viewCartsItems()}</Grid>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="card" style={{ overflow: "hidden" }}>
                            <div className="d-flex align-items-center px-4 py-2 bg-primary">
                              <h6 className=" text-white mb-0 mr-3">Your Total Price</h6>
                              <h6 className="mb-0 text-white" style={{ fontSize: "25px" }}>
                                {cartParts.length !== 0 &&
                                  currencyFormatter.format(
                                    cartParts.reduce((sum, cartItem) => {
                                      return sum + cartItem.listPrice * cartItem.quantity;
                                    }, 0)
                                  )}
                                {portfolioCarts.length !== 0 &&
                                  currencyFormatter.format(
                                    portfolioCarts.reduce((sum, cartItem) => {
                                      return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                    }, 0)
                                  )}
                              </h6>
                            </div>
                            <div className="px-4 py-2">
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Currency</p>
                                <h6 className="mb-0">
                                  <b>USD</b>
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Spare Parts</p>
                                <h6 className="mb-0">
                                  <b>
                                    {cartParts.length !== 0
                                      ? currencyFormatter.format(
                                          cartParts.reduce((sum, cartItem) => {
                                            return sum + cartItem.listPrice * cartItem.quantity;
                                          }, 0)
                                        )
                                      : currencyFormatter.format(
                                          portfolioCarts.reduce((sum, cartItem) => {
                                            return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                          }, 0)
                                        )}
                                  </b>
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Labor Charge</p>
                                <h6 className="mb-0">
                                  <b>
                                    {portfolioCarts.lenght !== 0
                                      ? currencyFormatter.format(
                                          portfolioCarts.reduce((sum, cartItem) => {
                                            return sum + cartItem.portfolioPrice.labourPrice * cartItem.portfolioCart.quantity;
                                          }, 0)
                                        )
                                      : "$0.00"}
                                  </b>
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Misc.</p>
                                <h6 className="mb-0">
                                  <b>
                                    {portfolioCarts.lenght !== 0
                                      ? currencyFormatter.format(
                                          portfolioCarts.reduce((sum, cartItem) => {
                                            return sum + cartItem.portfolioPrice.miscPrice * cartItem.portfolioCart.quantity;
                                          }, 0)
                                        )
                                      : "$0.00"}
                                  </b>
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Tax</p>
                                <h6 className="mb-0">
                                  <b>$0.00</b>
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Discount</p>
                                <h6 className="mb-0">
                                  {/* <b>- {portfolioCarts.lenght !== 0 ? currencyFormatter.format(
                                                                    portfolioCarts.reduce((sum, cartItem) => {
                                                                        return (
                                                                            sum +
                                                                            cartItem.portfolioPrice.sparePartsPrice *
                                                                            cartItem.portfolioCart.quantity
                                                                        );
                                                                    }, 0)) : "$0.00"}</b> */}
                                  <b> 2% | </b> REP Discount
                                </h6>
                              </div>
                              <div className="hr my-0"></div>
                              <div className="d-flex justify-content-between py-2">
                                <p className="mb-0">Total</p>
                                <h6 className="mb-0">
                                  <b>
                                    {cartParts.length !== 0
                                      ? currencyFormatter.format(
                                          cartParts.reduce((sum, cartItem) => {
                                            return sum + cartItem.listPrice * cartItem.quantity;
                                          }, 0)
                                        )
                                      : currencyFormatter.format(
                                          portfolioCarts.reduce((sum, cartItem) => {
                                            return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                          }, 0)
                                        )}
                                  </b>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {cartParts.length !== 0 ||
                            (portfolioCarts.length !== 0 && (
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <NavLink
                                    to={"/"}
                                    // href="/Order"
                                    className="btn border-primary text-primary pull-right"
                                    style={{ width: "100%" }}
                                  >
                                    CANCEL
                                  </NavLink>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <NavLink
                                    to={`/${shoppingCartList}`}
                                    // href={undefined}
                                    className="btn bg-primary text-white pull-right"
                                    style={{ width: "100%" }}
                                    // onClick={() => setOpenQuote(true)}
                                    // //   cartItems={cartItems}
                                  >
                                    REVIEW ORDER
                                  </NavLink>
                                </div>
                              </div>
                            ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {/* <Grid container>
                                        {portfolioCarts.length === 0 && cartParts.length !== 0 ? (
                                            <>
                                                {cartParts.length !== 0 &&
                                                    cartParts.map((item) => (
                                                        <Grid item xs={12} md={6} sx={{ p: 4 }}>
                                                            <PartCartItem
                                                                key={item.portfolioId}
                                                                item={item}
                                                                setCartItems={setCartParts}
                                                                removeItem={removeItem}
                                                                handleSnack={handleSnack}
                                                                cartItems={cartParts}
                                                            />
                                                        </Grid>
                                                    ))}
                                            </>
                                        ) : (
                                            <>
                                                {portfolioCarts.map((item) => (
                                                    <Grid item xs={12} md={6} sx={{ p: 4 }}>
                                                        <PortfolioCartItem
                                                            key={item.portfolioId}
                                                            item={item}
                                                            setCartItems={setPortfolioCarts}
                                                            removeItem={removeItem}
                                                            handleSnack={handleSnack}
                                                        />
                                                    </Grid>
                                                ))}
                                            </>
                                        )}

                                        {cartParts.length === 0 && portfolioCarts.length === 0 && (
                                            <Grid item xs={12} md={12} sx={{ paddingTop: 4 }}>
                                                <Typography fontSize={16} fontWeight={500}>
                                                    No items into cart
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid> */}
                </div>
              </div>
              {/* {portfolioCarts.length !== 0 && cartParts.length === 0 &&
                                <div className="col-md-12 col-sm-12">
                                    <div
                                        className="card p-4 border"
                                        style={{ border: 1, borderColor: "#000" }}
                                    >
                                        <Grid container xs={12}>
                                            <Grid item xs={3} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>SPARE PARTS</Typography>
                                                    <Typography fontWeight={500}>{currencyFormatter.format(
                                                        portfolioCarts.reduce((sum, cartItem) => {
                                                            return (
                                                                sum +
                                                                cartItem.portfolioPrice.sparePartsPrice *
                                                                cartItem.portfolioCart.quantity
                                                            );
                                                        }, 0))}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>LABOR CHARGE</Typography>
                                                    <Typography>{currencyFormatter.format(
                                                        portfolioCarts.reduce((sum, cartItem) => {
                                                            return (
                                                                sum +
                                                                cartItem.portfolioPrice.labourPrice *
                                                                cartItem.portfolioCart.quantity
                                                            );
                                                        }, 0))}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>MISC.</Typography>
                                                    <Typography>{currencyFormatter.format(
                                                        portfolioCarts.reduce((sum, cartItem) => {
                                                            return (
                                                                sum +
                                                                cartItem.portfolioPrice.miscPrice *
                                                                cartItem.portfolioCart.quantity
                                                            );
                                                        }, 0))}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>DISCOUNT</Typography>
                                                    <Typography>{currencyFormatter.format(
                                                        portfolioCarts.reduce((sum, cartItem) => {
                                                            return (
                                                                sum +
                                                                cartItem.portfolioPrice.sparePartsPrice *
                                                                cartItem.portfolioCart.quantity
                                                            );
                                                        }, 0))}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>TOTAL</Typography>
                                                    <Typography className="text-primary" fontSize={18} fontWeight={500}>{currencyFormatter.format(
                                                        portfolioCarts.reduce((sum, cartItem) => {
                                                            return (
                                                                sum +
                                                                cartItem.portfolioPrice.totalPrice *
                                                                cartItem.portfolioCart.quantity
                                                            );
                                                        }, 0))}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            }
                            {portfolioCarts.length === 0 && cartParts.length !== 0 && (
                                <div className="col-md-12 col-sm-12">
                                    <div
                                        className="card p-4 border"
                                        style={{
                                            border: 1,
                                            borderColor: "#000",
                                        }}
                                    >
                                        <Grid container xs={12}>
                                            <Grid item xs={3} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>SPARE PARTS</Typography>
                                                    <Typography fontWeight={500}>
                                                        {currencyFormatter.format(
                                                            cartParts.reduce((sum, cartItem) => {
                                                                return sum + cartItem.listPrice * cartItem.quantity;
                                                            }, 0)
                                                        )}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>LABOR CHARGE</Typography>
                                                    <Typography>$0</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>MISC.</Typography>
                                                    <Typography>$0</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>DISCOUNT</Typography>
                                                    <Typography>$0</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} container justifyContent={"center"}>
                                                <div>
                                                    <Typography fontSize={12}>TOTAL</Typography>
                                                    <Typography className="text-primary" fontSize={18} fontWeight={500}>
                                                        {currencyFormatter.format(
                                                            cartParts.reduce((sum, cartItem) => {
                                                                return sum + cartItem.listPrice * cartItem.quantity;
                                                            }, 0)
                                                        )}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )} */}
            </div>
            {/* {cartParts.length !== 0 || portfolioCarts.length !== 0 && (
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <NavLink
                                        to={"/"}
                                        // href="/Order"
                                        className="btn border-primary text-primary pull-right"
                                        style={{ width: "100%" }}
                                    >
                                        CANCEL
                                    </NavLink>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <NavLink
                                        to={`/${shoppingCartList}`}
                                        // href={undefined}
                                        className="btn bg-primary text-white pull-right"
                                        style={{ width: "100%" }}
                                    // onClick={() => setOpenQuote(true)}
                                    // //   cartItems={cartItems}
                                    >
                                        REVIEW ORDER
                                    </NavLink>
                                </div>
                            </div>
                        )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartsCart;
