import React from "react";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT } from "../common/constants";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

// Price Tab >> Billing
const paymentTermsOptions = [
  { label: "Immediate", value: "IMMEDIATE" },
  { label: "Credit", value: "CREDIT" },
  // { label: "90 Days", value: "NINTY_DAYS" },
  // { label: "60 Days", value: "SIXTY_DAYS" },
  // { label: "30 Days", value: "THIRTY_DAYS" },
];

const CustomerOrderPayment = ({
  show,
  hideModal,
  currencyOptions,
  billingTypeOptions,
  billingFrequencyOptions,
  billingnDetails,
  setBillingnDetails,
  addressDetails,
  setSelectAddressType,
}) => {
  // change select value
  const handleSelectOptionChange = (e, keyName) => {
    setBillingnDetails({ ...billingnDetails, [keyName]: e });
  };

  // change the input fields text
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setBillingnDetails({ ...billingnDetails, [name]: value });
  };

  // same as shipping address
  const handleCheckSameAsShippingAddress = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectAddressType("shippingaddress");
      let _shippingAddress =
        addressDetails?.shippingCondition?.value === "SHIP_TO_ME" ? addressDetails?.recipientAddress : addressDetails?.pickupInstructions;
      setBillingnDetails({ ...billingnDetails, billingAddress: _shippingAddress, sameAsShippingAddress: true });
    } else {
      setBillingnDetails({ ...billingnDetails, sameAsShippingAddress: false });
      setSelectAddressType("");
    }
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Billing Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row input-fields">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">PAYMENT TERMS</label>
              <Select
                value={billingnDetails?.paymentTerms}
                onChange={(e) => handleSelectOptionChange(e, "paymentTerms")}
                options={paymentTermsOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
              <Select
                value={billingnDetails?.currency}
                onChange={(e) => handleSelectOptionChange(e, "currency")}
                options={currencyOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">BILLING TYPE</label>
              <Select
                value={billingnDetails?.billingType}
                onChange={(e) => handleSelectOptionChange(e, "billingType")}
                options={billingTypeOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">BILLING FREQUENCY</label>
              <Select
                value={billingnDetails?.billingFrequency}
                onChange={(e) => handleSelectOptionChange(e, "billingFrequency")}
                options={billingFrequencyOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="row input-fields mb-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BILLING ADDRESS</label>
                <textarea
                  id="serviceRecipentAddress"
                  name="billingAddress"
                  className="form-control border-radius-10 text-primary"
                  onChange={handleInputTextChange}
                  value={billingnDetails?.billingAddress}
                  placeholder="Billing Address"
                  cols="30"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
          <FormGroup sx={{ marginTop: 0 }}>
            <FormControlLabel
              control={<Checkbox checked={billingnDetails?.sameAsShippingAddress} />}
              sx={{ fontSize: 13 }}
              onChange={handleCheckSameAsShippingAddress}
              label={"Same as Shipping Address"}
            />
          </FormGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary w-100" onClick={hideModal}>
          Save
        </button>
        <button className="btn btn-border-primary w-100" onClick={hideModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerOrderPayment;
