import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { sparePartSearch } from "../../services/searchServices";
import SearchBox from "../common/SearchBox";

const QuickOrderAddPart = ({ show, hideModal, handleSnack, partsRecords, setPartsRecords }) => {
  const [recordObj, setRecordObj] = useState({
    id: "",
    partNumber: "",
    serialNumber: "",
    quantity: "",
    itemNote: "",
    customerPartNumber: "",
  });

  const [searchPartsResult, setSearchPartsResult] = useState([]);
  const [noOptionsParts, setNoOptionsParts] = useState(false);

  // search Parts
  const handlePartSearch = async (searchText) => {
    setSearchPartsResult([]);
    recordObj.id = "";
    recordObj.partNumber = searchText;
    recordObj.serialNumber = "";

    if (searchText) {
      await sparePartSearch(`partNumber~${searchText}`)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchPartsResult(result);
            setNoOptionsParts(false);
          } else {
            setNoOptionsParts(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the job code from search result
  const handlePartSelect = (type, currentItem) => {
    setRecordObj({
      ...recordObj,
      ...currentItem,
      id: currentItem.id,
      partNumber: currentItem.partNumber,
      serialNumber: currentItem.serialNumber || "",
    });
    setSearchPartsResult([]);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setRecordObj({ ...recordObj, [name]: value });
  };

  const handleAddPart = () => {
    if (!recordObj.partNumber) {
      handleSnack("error", "Part number must not be empty!");
      return;
    } else if (recordObj.partNumber && searchPartsResult.length !== 0) {
      handleSnack("info", "Select a Part number from search list!");
      return;
    } else if (recordObj.partNumber && noOptionsParts) {
      handleSnack("info", "Search a valid Part number!");
      return;
    } else if (!recordObj?.quantity) {
      handleSnack("info", "Quantity must not be empty!");
      return;
    } else if (recordObj?.quantity < 0) {
      handleSnack("info", "Quantity must not be negative!");
      return;
    }
    let _partsRecords = [...partsRecords];

    const exists = _partsRecords.some((item) => item.id === recordObj?.id);
    if (exists) {
      handleSnack("info", `Part ${recordObj.partNumber} already exists in the list, search another!`);
      return;
    }
    _partsRecords.push(recordObj);
    setPartsRecords(_partsRecords);
    // setPartsRecords((prevItems) =>
    //   prevItems.map((item) => (item.id === recordObj?.id ? { ...item, quantity: recordObj?.quantity } : { ...recordObj }))
    // );
    hideModal();
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <h5>Add New Part</h5>
        <div className="card border px-3">
          <div className="row input-fields mt-2">
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ITEM NUMBER</label>
                <SearchBox
                  value={recordObj.partNumber}
                  onChange={(e) => handlePartSearch(e.target.value)}
                  type="partsSearch"
                  result={searchPartsResult}
                  onSelect={handlePartSelect}
                  noOptions={noOptionsParts}
                  placeholder="Search Part Number"
                />
                <div className="css-w8dmq8">*Mandatory</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="serialNumber"
                  value={recordObj?.serialNumber}
                  disabled
                  placeholder="Serial Number"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Quantity</label>
                <input
                  type="number"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  value={recordObj?.quantity}
                  placeholder="Quantity"
                  onChange={handleTextChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER PART NUMBER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="customerPartNumber"
                  value={recordObj?.customerPartNumber}
                  placeholder="Customer Part Number"
                  onChange={handleTextChange}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">LINE ITEM NOTE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="itemNote"
                  value={recordObj?.itemNote}
                  placeholder="Line Item Note"
                  onChange={handleTextChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6 col-sm-6">
            <button className="btn border-primary text-primary w-100" onClick={hideModal}>
              CANCEL
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button className="btn bg-primary text-white w-100" onClick={handleAddPart}>
              SAVE
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuickOrderAddPart;
