import React, { useState, useCallback } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import { Box, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";
import { GRID_STYLE } from "../../common/constants";

const PartImageFilesModal = ({ show, hideModal, handleSnack, filesRecords, setFilesRecords, handleShowFileUploadModal, handleViewFileModal }) => {
  // view file upload
  const handleViewFileImage = (row) => {
    handleViewFileModal(row);
  };

  // upload new Row
  const handleUploadNewRow = () => {
    // const _claimFilesRecords = [...filesRecords];
    // if(!(filesRecords[_claimFilesRecords.length - 1].imgSrc)){
    //   handleSnack("info", "Please upload image for last ")
    // }
    // _claimFilesRecords.push({
    //   id: _claimFilesRecords.length + 1,
    //   fileName: `image${_claimFilesRecords.length + 1}.png`,
    //   uploadDate: "01/01/2024",
    //   author: "Makino",
    //   fileType: "IMAGE",
    //   category: "IMAGE",
    //   imgSrc: "",
    // });
    // setFilesRecords(_claimFilesRecords);
  };

  const filesColumns = [
    {
      field: "fileType",
      headerName: "File Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Certificate", value: "CERTIFICATE" },
        { label: "Proof", value: "PROOF" },
        { label: "Faulty", value: "FAULTY" },
        { label: "Image", value: "IMAGE" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Certificate", value: "CERTIFICATE" },
        { label: "Proof", value: "PROOF" },
        { label: "Faulty", value: "FAULTY" },
        { label: "Image", value: "IMAGE" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
    },
    {
      field: "uploadDate",
      headerName: "Uplaod Date",
      flex: 1,
    },
    {
      field: "author",
      headerName: "Upload By",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        // const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        // if (isInEditMode) {
        //   return [
        //     <GridActionsCellItem
        //       icon={
        //         <Tooltip title="Save">
        //           <SaveIcon />
        //         </Tooltip>
        //       }
        //       label="Save"
        //       onClick={handleSaveClick(row.id)}
        //     />,
        //     <GridActionsCellItem
        //       icon={
        //         <Tooltip title="Cancel">
        //           <CancelIcon />
        //         </Tooltip>
        //       }
        //       label="Cancel"
        //       className="textPrimary"
        //       onClick={handleCancelClick(row.id)}
        //       color="inherit"
        //     />,
        //   ];
        // }
        return [
          // <GridActionsCellItem
          //   icon={
          //     <div className="cursor">
          //       <Tooltip title="Edit">
          //         <EditOutlinedIcon />
          //       </Tooltip>
          //     </div>
          //   }
          //   label="Edit"
          //   className="textPrimary"
          //   color="inherit"
          //   onClick={handleEditClick(row.id)}
          // />,
          // <GridActionsCellItem
          //   icon={
          //     <div className="cursor">
          //       <Tooltip title="Upload">
          //         <img
          //           className="mx-1 cursor"
          //           style={{ filter: "grayscale(100%) brightness(0%)" }}
          //           src="../../assets/images/fileUploadIcon.png"
          //           alt="File Upload icon"
          //         />
          //       </Tooltip>
          //     </div>
          //   }
          //   label="Upload"
          //   className="textPrimary"
          //   color="inherit"
          //   onClick={handleShowFileUploadModal}
          // />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="View File">
                  <InsertPhotoIcon />
                </Tooltip>
              </div>
            }
            label="View File"
            className="textPrimary"
            color="inherit"
            onClick={() => handleViewFileImage(row)}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" centered>
        <Modal.Body>
          <div className="card px-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Document Upload</h4>
              <div className="row" style={{ justifyContent: "right" }}>
                <button type="button" className="btn bg-primary text-white mx-3 mb-2" onClick={handleShowFileUploadModal}>
                  + Upload New
                </button>
              </div>
            </div>
            <DataGrid
              rows={filesRecords}
              columns={filesColumns}
              sx={GRID_STYLE}
              pageSizeOptions={[5]}
              autoHeight
              editMode="row"
              // rowModesModel={rowModesModel}
              // onRowModesModelChange={handleRowModesModelChange}
              // onRowEditStart={handleRowEditStart}
              // onRowEditStop={handleRowEditStop}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={(error) => console.log(error)}
              // processRowUpdate={processRowUpdate}
              // disableRowSelectionOnClick
            />
          </div>
          <div className="row" style={{ justifyContent: "right" }}>
            <button type="button" className="btn btn-light bg-primary text-white mx-3" onClick={hideModal}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartImageFilesModal;
