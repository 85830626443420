import React from "react";

const CartPriceCard = ({ cartItems, labourCharge, portfolioCarts, currencyFormatter, orderRecord }) => {
  return (
    <>
      <div className="card border" style={{ overflow: "hidden" }}>
        <div className="d-flex align-items-center px-4 py-2 bg-primary">
          <h6 className=" text-white mb-0 mr-3">Your Total Price</h6>
          <h6 className="mb-0 text-white" style={{ fontSize: "25px" }}>
            {cartItems &&
              cartItems["parts"].length !== 0 &&
              currencyFormatter.format(
                cartItems["parts"].reduce((sum, cartItem) => {
                  return sum + cartItem?.listPrice * cartItem?.quantity;
                }, 0) +
                  (cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) *
                    10) /
                    100 -
                  (cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) *
                    2) /
                    100 +
                  labourCharge
              )}
            {/* cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge) */}
            {portfolioCarts.length !== 0 &&
              currencyFormatter.format(
                portfolioCarts.reduce((sum, cartItem) => {
                  return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                }, 0) +
                  (portfolioCarts.reduce((sum, cartItem) => {
                    return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                  }, 0) *
                    10) /
                    100 -
                  (portfolioCarts.reduce((sum, cartItem) => {
                    return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                  }, 0) *
                    2) /
                    100
              )}
          </h6>
        </div>
        <div className="px-2 py-2">
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Currency</p>
            <h6 className="mb-0">
              <b>{orderRecord?.currency?.label || "USD"}</b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Spare Parts</p>
            <h6 className="mb-0">
              <b>
                {cartItems && cartItems["parts"].length !== 0
                  ? // ? currencyFormatter.format(cartItems?.cartPrice)
                    currencyFormatter.format(
                      cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0)
                    )
                  : currencyFormatter.format(
                      portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.sparePartsPrice * cartItem?.quantity;
                      }, 0)
                    )}
              </b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Labor Charge</p>
            <h6 className="mb-0">
              <b>
                {portfolioCarts.length !== 0
                  ? currencyFormatter.format(
                      portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.labourPrice * cartItem?.quantity;
                      }, 0)
                    )
                  : currencyFormatter.format(labourCharge)}
              </b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Misc.</p>
            <h6 className="mb-0">
              <b>
                {portfolioCarts.length !== 0
                  ? currencyFormatter.format(
                      portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.miscPrice * cartItem?.quantity;
                      }, 0)
                    )
                  : currencyFormatter.format(0)}
              </b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Tax</p>
            <h6 className="mb-0">
              <b>
                {cartItems && cartItems["parts"].length !== 0
                  ? // ? currencyFormatter.format((cartItems?.cartPrice * 10) / 100)
                    currencyFormatter.format(
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        10) /
                        100
                    )
                  : currencyFormatter.format(
                      (portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                      }, 0) *
                        10) /
                        100
                    )}
              </b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <div className="d-flex align-items-center mb-0">
              <p className="mb-0">Discount</p>
              <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
            </div>
            <h6 className="mb-0">
              <b>
                -{" "}
                {cartItems && cartItems["parts"].length !== 0
                  ? // ? currencyFormatter.format((cartItems?.cartPrice * 2) / 100)
                    currencyFormatter.format(
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        2) /
                        100
                    )
                  : currencyFormatter.format(
                      (portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                      }, 0) *
                        2) /
                        100
                    )}
              </b>
            </h6>
          </div>
          <div className="hr my-0"></div>
          <div className="d-flex justify-content-between py-2">
            <p className="mb-0">Total</p>
            <h6 className="mb-0">
              <b>
                {cartItems &&
                  cartItems["parts"].length !== 0 &&
                  currencyFormatter.format(
                    cartItems["parts"].reduce((sum, cartItem) => {
                      return sum + cartItem?.listPrice * cartItem?.quantity;
                    }, 0) +
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        10) /
                        100 -
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        2) /
                        100 +
                      labourCharge
                  )}
                {/* currencyFormatter.format(
                    cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge
                  )} */}
                {portfolioCarts.length !== 0 &&
                  currencyFormatter.format(
                    portfolioCarts.reduce((sum, cartItem) => {
                      return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                    }, 0) +
                      (portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                      }, 0) *
                        10) /
                        100 -
                      (portfolioCarts.reduce((sum, cartItem) => {
                        return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                      }, 0) *
                        2) /
                        100
                  )}
              </b>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPriceCard;
