import React, { useContext, useState } from "react";

import { Card, Typography } from "@mui/material";

import { RenderConfirmDialog } from "../../../components/ConfirmationBox";
import CartItemEditModal from "../../CartItemEditModal";
import PartsNumberSpinner from "./PartsNumberSpinner";
import { currencyFormatter } from "../../common/utils/currencyFormatter";
import ImageViewer from "../../common/utils/ImageViewer";

const PartCartItem = (props) => {
  const { removeItem, cartItems, setCartItems, item, handleSnack } = props;

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };


  return (
    <div>
      <RenderConfirmDialog
        confimationOpen={confirmationOpen}
        message={`Pressing 'Yes' will remove the cart item!`}
        handleNo={() => setConfirmationOpen(false)}
        handleYes={() => {
          removeItem(item.id);
          setConfirmationOpen(false);
        }}
      />
      <CartItemEditModal show={show} handleClose={handleClose} item={item} setItem={setCartItems} handleSnack={handleSnack} />
      <Card elevation={2} sx={{ p: 2, backgroundColor: "#f5f5f5", borderRadius: 3, borderColor: " #872ff7 " }} variant="outlined">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-md-3 col-sm-3">
            <div className="w-100">
              <ImageViewer
                src={item?.sparepartImages && item?.sparepartImages.length > 0 ? item?.sparepartImages[0] : ""}
                style={{ objectFit: "fill" }}
              />
              {/* <img src="./assets/images/partCartImage.png" width={100} height={121} style={{ objectFit: "cover" }} /> */}
            </div>
          </div>
          <div className="row col-md-9 ml-2">
            <div className="col-md-7">
              <div className="d-flex justify-content-between px-2">
                <div>
                  <Typography fontSize={16} fontWeight={500}>
                    {item?.partNumber}
                  </Typography>
                  <Typography fontSize={13} className="mb-0 mt-2">
                    {item?.partDescription}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <Typography variant="p" sx={{ fontWeight: 600, fontSize: "16px" }}>
                {currencyFormatter.format(item?.listPrice * item?.quantity)}
              </Typography>
            </div>
            <div className="d-flex align-items-center px-3 mt-4">
              <PartsNumberSpinner
                portfolioCartId={item.partsId}
                item={item}
                qnt={item.quantity}
                setCartItems={setCartItems}
                cartItems={cartItems}
                steps={1}
                setConfirmationOpen={setConfirmationOpen}
                handleSnack={handleSnack}
              />
              <button
                onClick={() => removeItem(item)}
                // onClick={() => setConfirmationOpen(true)}
                className="btn bg-primary text-white remove-button mx-2"
                style={{ fontSize: 12, height: 26 }}
              >
                Remove
              </button>
              {props.showViewBtn && (
                <button
                  onClick={() => props.handleViewPartItemDetailsModal(item)}
                  className="btn border-primary text-primary remove-button mx-2"
                  style={{ fontSize: 12, height: 26 }}
                >
                  View
                </button>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PartCartItem;
