export const login = "/login";
export const equipment = "/equipment";
export const quoteHistory = "/quote-history";
export const claimHistory = "/claim-history";
export const equipmentMasterComp = "/eqipment-master";
export const warrantyAdministration = "/warranty-master";
export const claimAdministration = "/claim-master";
export const manageWarrantyReturns = "/warranty-return";
export const help = "/help";
export const partsSearch = "/parts-search";
export const partsSegment = "/parts-segment";
export const returnParts = "/return-parts";
export const shoppingCartList = "shopping-cart-list";
export const adminPage = "/admin";
export const transationPage = "/transactions";
export const quickOrder = "/quick-order";
