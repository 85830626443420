import React, { useState } from "react";
import { callDeleteApi, callPostApi } from "../../../services/ApiCaller";
import { API_SUCCESS } from "../../../services/ResponseCode";
import { PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, PARTS_DELETE_PARTS_FROM_CART_DELETE } from "../../../services/CONSTANTS";

const PartsNumberSpinner = ({ portfolioCartId, item, qnt, steps, setCartItems, cartItems, setConfirmationOpen, handleSnack }) => {
  async function updateCartValue(value) {
    const partsCartRes = cartItems["parts"];
    let row = partsCartRes.find((obj) => obj?.partNumber === item?.partNumber);

    const partExists = !!row;
    if (partExists) {
      row = { ...row, quantity: value };
      const rObj = {
        // partsCartId: cartItems?.cartId,
        cartId: cartItems?.cartId,
        partsQuantity: {
          [String(row["partsId"])]: value,
        },
      };

      if (value == 0) {
        let dltRUrl = `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${row["partsId"]}`;
        callDeleteApi(null, dltRUrl, (response) => {
          if (response.status === API_SUCCESS) {
            handleSnack("info", `Part Number ${row?.partNumber} removed from cart`);
            let _cartItems = [...cartItems["parts"]];
            _cartItems = _cartItems.filter((obj) => obj.partsId !== item.partsId);
            setCartItems({ ...cartItems, parts: [..._cartItems] });
            // setRowData({ ...rowData, quantity: value });
            // setStaper((pre) => pre + 1);
          }
        });
      } else {
        callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            // setRowData({ ...rowData, quantity: value });
            let _cartItems = [...cartItems["parts"]];

            _cartItems = _cartItems.map((obj) => (obj.partsId === item.partsId ? row : obj));
            setCartItems({ ...cartItems, parts: [..._cartItems] });
            handleSnack("success", `Part Number ${row?.partNumber} updated`);
            // setStaper((pre) => pre + 1);
          }
        });
      }
    }

    // let _cartItems = [...cartItems];

    // let row = _cartItems.find((obj) => obj.id === portfolioCartId);
    // const exists = !!row;
    // if (exists) {
    //   row = { ...row, quantity: value };
    // }

    // _cartItems = _cartItems.map((obj) => (obj.id === row.id ? row : obj));
    // setCartItems(_cartItems);
  }
  const [value, setValue] = useState(item?.quantity);
  const [mouseDownDirection, setMouseDownDirection] = useState(null);
  const max = (num) => (num < 0 ? 4 : 3);

  // useInterval(() => handleButtonChange(mouseDownDirection), mouseDownDirection ? 100 : null);

  const handleChange = ({ currentTarget: { value } }) => {
    console.log(value);
    setValue((curr) => {
      if (!Boolean(value)) {
        return 0;
      }
      const numeric = parseInt(value, 10);
      const maxLength = max(numeric);

      if (value.length > maxLength) {
        return curr;
      }

      return value.length <= maxLength ? numeric : curr;
    });
  };

  const handleButtonChange = (direction) => {
    let next;

    switch (direction) {
      case "up":
        next = item?.quantity + (steps || 1);
        break;
      case "down":
        next = item?.quantity - (steps || 1) >= 0 ? item?.quantity - (steps || 1) : 0;
        break;
      default:
        next = item?.quantity;
        break;
    }
    if (next !== 0) {
      updateCartValue(next);
    }

    setValue((curr) => {
      return `${next}`.length <= max(curr) ? next : curr;
    });
  };

  return (
    <div className="input-number">
      <button
        onClick={() => handleButtonChange("down")}
        onMouseDown={() => setMouseDownDirection("down")}
        onMouseOut={() => setMouseDownDirection(null)}
        onMouseUp={() => setMouseDownDirection(null)}
        disabled={value === 1}
      >
        -
      </button>
      {/* <input type="number" step={steps} value={value} onChange={handleChange} /> */}
      <input type="number" step={steps} value={item?.quantity} disabled onChange={handleChange} />
      <button
        onClick={() => handleButtonChange("up")}
        onMouseDown={() => setMouseDownDirection("up")}
        onMouseUp={() => setMouseDownDirection(null)}
        onMouseOut={() => setMouseDownDirection(null)}
      >
        +
      </button>
    </div>
  );
};

export default PartsNumberSpinner;
