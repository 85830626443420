import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import CustomizedSnackbar from "../../shared/CustomSnackBar";
import { Box, Grid, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_STYLE } from "../common/constants";
import { callGetApi } from "../../services/ApiCaller";
import { API_SUCCESS } from "../../services/ResponseCode";
import { SHIPMENT_HEADER_MASTER_URL } from "../../services/CONSTANTS";
import PartsReturnDetails from "./PartsReturnDetails";

const DataGridContainer = (props) => (
  <Box
    margin={"auto"}
    sx={{
      backgroundColor: "#ffffff",
      height: 500,
      //   marginBlock: 1,
      borderRadius: 5,
      width: "95%",
      display: "flex",
      justifyContent: "center",
      //   padding: 2,
    }}
  >
    {props.children}
  </Box>
);

const ReturnParts = () => {
  const [loading, setLoading] = useState(false);
  const [requestRecords, setRequestRecords] = useState([]);

  const [shipmentHeaderId, setShipmentHeaderId] = useState(null);
  const [warrantyReturnId, setWarrantyReturnId] = useState(null);
  const [requestCreation, setRequestCreation] = useState(false);
  const [openReturnRequsterModal, setOpenReturnRequsterModal] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (!openReturnRequsterModal) {
      setShipmentHeaderId(null);
      setWarrantyReturnId(null);
      setRequestCreation(false);
    }
  }, [openReturnRequsterModal]);

  useEffect(() => {
    getWarrrantyRequestRecordList();
  }, []);

  // get warranty reuqest records list
  const getWarrrantyRequestRecordList = () => {
    setLoading(true);
    let rUrl = SHIPMENT_HEADER_MASTER_URL + `?pageNumber=${0}&pageSize=${10}`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRequestRecords(responseData);
          setLoading(false);
        } else {
          setRequestRecords([]);
          setLoading(false);
        }
      },
      (error) => {
        setRequestRecords([]);
        setLoading(false);
      }
    );
  };

  const handleCreateNew = () => {
    setRequestCreation(true);
    setOpenReturnRequsterModal(true);
  };

  // edit return parts(Shipment Data)
  const handleEditReturnData = (row) => {
    setShipmentHeaderId(row.shipmentHeaderId);
    setWarrantyReturnId(row.warrantyReturnId);
    setRequestCreation(false);
    setOpenReturnRequsterModal(true);
  };

  const columns = [
    {
      field: "returnNumber",
      headerName: "Return Number",
      flex: 1,
      // renderCell: ({ row }) => <>{row.shipmentHeaderModel?.returnNumber}</>,
    },
    {
      field: "returnType",
      headerName: "Return Type",
      flex: 1,
      // renderCell: ({ row }) => <>{row.shipmentHeaderModel?.returnType}</>,
    },
    {
      field: "rmaNumber",
      headerName: "RMA Number",
      // width: 150,
      flex: 1,
      // renderCell: ({ row }) => <>{row.shipmentHeaderModel?.rmaNumber}</>,
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      flex: 1,
      // renderCell: ({ row }) => <>{row.shipmentHeaderModel?.trackingNumber}</>,
    },
    {
      field: "shippedOn",
      headerName: "Shiped On",
      flex: 1,
      // renderCell: ({ row }) => <>{row.shipmentHeaderModel?.shippedOn}</>,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      //   width: 150,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleEditReturnData(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-item-center mt-4 mb-3">
              <h4 className="font-weight-600 mt-4 mb-0 ">Return Dashboard</h4>
              <button className="btn bg-primary text-white" onClick={handleCreateNew}>
                + Create New
              </button>
            </div>
            <Grid
              container
              sx={{
                width: "100%",
                backgroundColor: "#f3eafe",
                borderRadius: 5,
                marginBlock: 2,
              }}
            >
              <Grid item xs={12}>
                <DataGridContainer>
                  <DataGrid
                    // loading={partClassALoading}
                    sx={GRID_STYLE}
                    getRowId={(row) => row.warrantyReturnId}
                    rows={requestRecords}
                    columns={columns}
                    autoHeight
                    rowsPerPageOptions={[10, 20, 50]}
                    // checkboxSelection={true}
                    keepNonExistentRowsSelected
                    loading={loading}
                  />
                </DataGridContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      {openReturnRequsterModal && (
        <PartsReturnDetails
          show={openReturnRequsterModal}
          hideModal={() => setOpenReturnRequsterModal(false)}
          handleSnack={handleSnack} // setWarrantyReturnId={setWarrantyReturnId}
          shipmentHeaderId={shipmentHeaderId}
          setShipmentHeaderId={setShipmentHeaderId}
          requestCreation={requestCreation}
          setRequestCreation={setRequestCreation}
        />
      )}
    </>
  );
};

export default ReturnParts;
