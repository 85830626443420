import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const AddedToCartItemInfo = ({ show, hideModal, handleSnack, recordId, partsRecord }) => {
  const [rowData, setRowData] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (recordId) {
      let partsRow = partsRecord.find((obj) => obj?.id === recordId);
      setRowData(partsRow);
    }
  }, [recordId, count]);

  // go to cart screen
  const handleGoToCart = () => {
    hideModal();
  };

  return (
    <Modal show={show} size="md" centered>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-basline">
          <h5>ITEMS HAVE BEEN SUCCESSFULLY ADDED TO YOUR CART</h5>
          <span className="cursor" onClick={hideModal}>
            X
          </span>
        </div>
        <div className="d-flex align-items-center">
          <img src="../assets/images/cartMagnifier.jpg" alt="" width={80} />
          <div className="d-block">
            <h6 className="font-size-16 font-weight-600">
              {rowData?.partNumber}: {rowData?.partDescription}
            </h6>
            <span>Quantity: {rowData?.quantity}</span>
          </div>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          <button className="btn bg-primary text-white" onClick={handleGoToCart}>
            GO TO CART
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddedToCartItemInfo;
