import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ADDRESS_MASTER_URL } from "../../services/CONSTANTS";
import { callPostApi } from "../../services/ApiCaller";
import { API_SUCCESS } from "../../services/ResponseCode";

const addressObj = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  zipCode: "",
};

const AddNewAddress = ({
  show,
  hideModal,
  handleSnack,
  billingAddresses = [],
  setBillingAddresses,
  shippingAddresses = [],
  setShippingAddresses,
  addressType,
}) => {
  const [addressRecord, setAddressRecord] = useState({ ...addressObj });

  // change the text value of the address modal input fiels
  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setAddressRecord({ ...addressRecord, [name]: value });
  };

  // Save the Address Add Modal
  const hadleSaveNewAddress = async () => {
    if (!addressRecord.fullAddress) {
      handleSnack("info", "Full address must not be empty");
      return;
    }

    let _billingAddresses = [...billingAddresses];
    let _shippingAdresses = [...shippingAddresses];

    const rUrl = ADDRESS_MASTER_URL;

    callPostApi(
      null,
      rUrl,
      addressRecord,
      (response) => {
        if (response.status === API_SUCCESS) {
          _billingAddresses.unshift({ label: addressRecord.fullAddress, value: addressRecord.fullAddress });
          setBillingAddresses([..._billingAddresses]);

          _shippingAdresses.unshift({ label: addressRecord.fullAddress, value: addressRecord.fullAddress });
          setShippingAddresses([..._shippingAdresses]);

          hideModal();
        } else {
          handleSnack("info", "Something went wrong.");
        }
      },
      (error) => {
        console.log("error");
        handleSnack("info", "Something went wrong.");
      }
    );

    // let _billingAddresses = [...billingAddresses];
    // let _shippingAdresses = [...shippingAddresses];
    // if (addressType === "billingAddress") {
    //   _billingAddresses.unshift({ label: addressRecord.fullAddress, value: addressRecord.fullAddress });
    //   setBillingAddresses([..._billingAddresses]);
    // } else {
    //   _shippingAdresses.unshift({ label: addressRecord.fullAddress, value: addressRecord.fullAddress });
    //   setBillingAddresses([..._shippingAdresses]);
    // }

    //handleOpenCloseAddressAddModal();
  };

  return (
    <Modal show={show} onHide={hideModal} centered size="xl">
      <Modal.Body>
        <h4>Add New Address</h4>
        {/* <h4>Add New {addressType === "billingAddress" ? "Billing " : "Shipping "} Address</h4> */}
        <div className="card border px-3">
          <div className="row input-fields py-3">
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="country"
                  placeholder="Country"
                  value={addressRecord?.country}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="regionOrState"
                  placeholder="State"
                  value={addressRecord?.regionOrState}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DISTRICT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="district"
                  placeholder="District"
                  value={addressRecord?.district}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ZIP Code</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="zipCode"
                  placeholder="Zip Code"
                  value={addressRecord?.zipCode}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS LINE 1</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  name="addressLine1"
                  placeholder="Address Line 1"
                  cols={3}
                  rows={2}
                  value={addressRecord?.addressLine1}
                  onChange={handleAddressInputChange}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS LINE 2</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  name="addressLine2"
                  placeholder="Address Line 2"
                  cols={3}
                  rows={2}
                  value={addressRecord?.addressLine2}
                  onChange={handleAddressInputChange}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS LINE 3</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  name="addressLine3"
                  placeholder="Address Line 3"
                  cols={3}
                  rows={2}
                  value={addressRecord?.addressLine3}
                  onChange={handleAddressInputChange}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">FULL ADDRESS</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  name="fullAddress"
                  placeholder="Full Address"
                  cols={3}
                  rows={2}
                  value={addressRecord?.fullAddress}
                  onChange={handleAddressInputChange}
                ></textarea>
                <div className="css-w8dmq8">*Mandatory</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-3">
          <div className="col-md-6 col-sm-6">
            <button className="btn border-primary text-primary w-100" onClick={hideModal}>
              Cancel
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button className="btn bg-primary text-white w-100" onClick={hadleSaveNewAddress}>
              Save & Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewAddress;
