import React, { useEffect, useState } from "react";
import { Box, Grid, Tab, Tooltip, Radio, RadioGroup, Divider, FormControl, FormControlLabel } from "@mui/material";
// import Grid from "@mui/material";
// import { DatePicker } from '@mui/lab';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { DatePicker } from "@mui/x-date-pickers";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { FONT_STYLE_SELECT, GRID_STYLE, FONT_STYLE } from "../../common/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TextField } from "@mui/material";
import { Row, Col, Form, FormLabel } from "react-bootstrap";
import Select from "react-select";
import CustomizedSnackbar from "../../../shared/CustomSnackBar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormGroup, Switch } from "@mui/material";
import { currencyFormatter } from "../../common/utils/currencyFormatter";

const countryCodeOptions = [
  {
    value: "USD",
    label: "USD ",
  },
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "EUR",
    label: "EUR ",
  },
  {
    value: "GBP",
    label: "GBP ",
  },
];
const unitOptions = [
  {
    value: "EA",
    label: "EA ",
  },
  {
    value: "PC",
    label: "PC",
  },
  {
    value: "DZ",
    label: "DZ ",
  },
  {
    value: "CT",
    label: "CT ",
  },
];

const data = [
  {
    partId: "10",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Nuts",
    price: 120.0,
  },
  {
    partId: "20",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 890.0,
  },
  {
    partId: "30",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 498.25,
  },
  {
    partId: "40",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 836.9,
  },
  {
    partId: "50",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 568.0,
  },
  {
    partId: "60",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 322.9,
  },
  {
    partId: "70",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 190.0,
  },
  {
    partId: "80",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 560.0,
  },
  {
    partId: "90",
    pricetype: "PF01-List Price",
    partNo: "PX12345",
    sapTable: "TAB101",
    sapCondition: "Conditions",
    partDescription: "Calc Rule1",
    price: 440.0,
  },
];
const PartsSegmentMaster = () => {
  const [tabValue, setTabValue] = useState("priceMethod");
  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const handleErpInputTextChange = (e) => {
    const { name, value } = e.target;
  };

  const columns = [
    {
      field: "partId",
      headerName: "Part ID",
      // flex: 1,
      width: "100",
    },
    {
      field: "partNo",
      headerName: "Part Number",
      flex: 1,
    },
    {
      field: "partDescription",
      headerName: "Part Description",
      flex: 1,
    },
    {
      field: "pricetype",
      headerName: "Price Type",
      flex: 1,
    },
    {
      field: "sapTable",
      headerName: "SAP Table",
      flex: 1,
    },
    {
      field: "sapCondition",
      headerName: "SAP Condition",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => (currencyFormatter.format(row.price)),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      //   width: 150,
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="View"
            color="inherit"
          />,

          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="View">
                  <VisibilityIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const PriceAgreementDataTable = () => {
    return (
      <>
        <h5 className="my-4">Parts Price</h5>
        <div className="card border mt-3">
          <Box
            sx={{
              width: "100%",
              height: 700,
              // marginInline: 2,
              padding: 2.5,
              // marginY: 2,
              // paddingTop: 1,
            }}
          >
            <DataGrid
              getRowId={(row) => row.partId}
              sx={GRID_STYLE}
              rows={data}
              columns={columns}
              keepNonExistentRowsSelected
              pageSizeOptions={[5]}
              rowsPerPageOptions={[5, 10, 20, 50]}
            />
          </Box>
          {/* </Grid> */}
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid mt-3">
          <h4 className="font-weight-600 mb-3 mt-5">Price Agreement</h4>
          <div className="card border px-2 py-1 mb-0">
            <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList className="custom-tabs-div" onChange={(e, value) => setTabValue(value)}>
                    <Tab label="Price Method" value="priceMethod" />
                    <Tab label="Validity" value="validity" />
                    <Tab label="ERP Details" value="erpDetails" />
                  </TabList>
                </Box>
                <TabPanel value="priceMethod">
                  <div className="row m-2 input-fields">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">AGREEMENT TYPE</label>
                        <Select options={[]} isClearable={true} placeholder="Special Price" styles={FONT_STYLE_SELECT} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZATION CODE</label>
                        <p>
                          <strong>992K17980</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                        <Select placeholder="Customer ID" options={[]} styles={FONT_STYLE_SELECT} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                        <Select placeholder="Customer Name" options={[]} styles={FONT_STYLE_SELECT} />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="validity">
                  {/* Content for Validity tab */}
                  <Box
                    className="mt-0"
                    sx={{
                      width: "100%",
                      typography: "body1",
                    }}
                  >
                    <div className="row input-fields">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EFFECTIVE ON</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    inputProps={{
                                      ...params.inputProps,
                                      style: FONT_STYLE,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                            VALID FROM
                          </label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    inputProps={{
                                      ...params.inputProps,
                                      style: FONT_STYLE,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                            VALID TO
                          </label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    inputProps={{
                                      ...params.inputProps,
                                      style: FONT_STYLE,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-12">
                        <div className="form-group">
                          <FormGroup>
                            <FormControlLabel
                              style={{
                                alignItems: "start",
                                marginLeft: 0,
                              }}
                              control={<Switch />}
                              labelPlacement="top"
                              label={<span className="text-light-dark font-size-12 font-weight-500">ACTIVE</span>}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
                <TabPanel value="erpDetails">
                  {/* Content for ERP Details tab */}
                  <Box
                    className="mt-0"
                    sx={{
                      width: "100%",
                      typography: "body1",
                    }}
                  >
                    <div className="row input-fields">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ERP CONDITION</label>

                          <Select isClearable={true} placeholder="6FGIVN" options={[]} styles={FONT_STYLE_SELECT} />
                        </div>
                      </div>
                    </div>
                    <p> DETAILS</p>
                    <div className="row input-fields">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group date-box">
                          <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                          <div className=" d-flex form-control-date">
                            <input
                              type="number"
                              className="form-control text-primary rounded-top-left-0 rounded-bottom-left-0"
                              placeholder="Amount"
                              name="priceAdditionalInput"
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (isNaN(inputValue)) {
                                  e.target.value = 0;
                                } else {
                                  e.target.value = Math.max(inputValue, 0);
                                }
                              }}
                            />
                            <div className="">
                              <Select className="text-primary" placeholder="Select" options={countryCodeOptions} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group date-box">
                          <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                          <div className=" d-flex form-control-date">
                            <input
                              type="number"
                              className="form-control text-primary rounded-top-left-0 rounded-bottom-left-0"
                              placeholder="Amount"
                              name="priceAdditionalInput"
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (isNaN(inputValue)) {
                                  e.target.value = 0;
                                } else {
                                  e.target.value = Math.max(inputValue, 0);
                                }
                              }}
                            />
                            <div className="">
                              <Select className="text-primary" placeholder="Select" options={unitOptions} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                            CONDITION NUMBER
                          </label>

                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control border-right-0 rounded-left text-primary"
                              placeholder="Merchant Details"
                              name="startUsage"
                            />
                            <span className="input-group-text border-start-0  rounded-top-left-0 rounded-bottom-left-0 ">Details</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          {tabValue === "priceMethod" && PriceAgreementDataTable()}
        </div>
      </div>
    </>
  );
};

export default PartsSegmentMaster;
