import { createContext, useState } from "react";

export const PartsContext = createContext();

const PartsProvider = ({ children }) => {
  const [cartParts, setCartParts] = useState([]);
  const [portfolioCarts, setPortfolioCarts] = useState([]);
  const [cartItems, setCartItems] = useState(null);
  return (
    <PartsContext.Provider value={{ cartParts, setCartParts, portfolioCarts, setPortfolioCarts, cartItems, setCartItems }}>
      {children}
    </PartsContext.Provider>
  );
};
export default PartsProvider;
