import React, { useState } from "react";
import DataTable from "react-data-table-component";

import { Box, FormControl, MenuItem, Select } from "@mui/material";

import PartsCartItemsModal from "./PartsCartItemsModal";
import RectangularLoader from "./common/RectangularLoader";
import { fetchComponents, fetchSubComponents, fetchParts, fetchPartsByIds } from "../services/EquipmentService";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import { Dropdown } from "react-bootstrap";
import SelectFilter from "react-select";
import { FONT_STYLE_SELECT } from "./common/constants";
import EqupComponentOverviewModal from "./EqupComponentOverviewModal";
import LoadingProgress from "./common/Loader";
import Moment from "react-moment";

const dataTableCustomStyle = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#872ff7",
      color: "#fff",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};

const SelectComponent = ({ row, onChange }) => {
  const [selectedAction, setSelectedAction] = useState("");

  const handleOpenPartItemsModal = (event) => {
    const { value } = event.target;
    setSelectedAction(value);
    if (onChange) {
      onChange(row, value);
    }
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        onChange={handleOpenPartItemsModal}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        value={selectedAction}
        placeholder="Action"
        className="border-primary text-primary py-0"
        sx={{
          height: 30,
          textAlign: "center", // Center the placeholder text
          ".MuiSelect-icon": {
            right: 10, // Adjust as needed to center the icon
            top: 4,
          },
        }}
        renderValue={(selected) => {
          if (!selected) {
            return <em>Select</em>;
          }
          return selected;
        }}
      >
        <MenuItem value={"Buy Parts"}>Buy Parts</MenuItem>
        <MenuItem value={"Request For Repair"}>Request For Repair</MenuItem>
      </Select>
    </FormControl>
  );
};

const EquipmentMachinesTableList = ({
  columns,
  rowsData,
  equipmentPage,
  setEquipmentPage,
  equipmentPerPage,
  setEquipmentPerPage,
  totalEquipmentRows,
  handleOpenRepairRequestModal,
  equpLoading,
}) => {
  const [openPartModal, setOpenPartModal] = useState(false);
  const [partsData, setPartsData] = useState([]);
  const [componentPage, setComponentPage] = useState(1);
  const [componentPerPage, setComponentPerPage] = useState(5);
  const [subComponentPage, setSubComponentPage] = useState(1);
  const [subComponentPerPage, setSubComponentPerPage] = useState(5);

  const [expendedEquipmentRow, setExpendedEquipmentRow] = useState(null);
  const [selectedComponentRow, setSelectedComponentRow] = useState(null);

  const [openCompOverviewModal, setOpenCompOverviewModal] = useState(false);
  const [componentRow, setComponentRow] = useState(null);

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  // view component row details
  const handleViewComponetDtls = (row) => {
    setComponentRow(row);
    setOpenCompOverviewModal(true);
  };

  // component columns new
  const componentsColumns = [
    {
      id: "componentNumber",
      name: <div>Component Number</div>,
      selector: (row) => (
        <span className="cursor font-weight-500" style={{ textDecoration: "underline" }} onClick={() => handleViewComponetDtls(row)}>
          {row.componentCode}
        </span>
      ),
      wrap: true,
      sortable: false,
      // cell: (row) => (
      //   <span className="cursor font-weight-500 " style={{ textDecoration: "underline" }}>
      //     {row.componentCode}
      //   </span>
      // ),
    },
    {
      id: "serialNumber",
      name: <div>Serial Number</div>,
      selector: (row) => row.serialNumber,
      wrap: true,
      sortable: false,
    },
    {
      id: "warrentyCategory",
      name: <div>Warranty Category</div>,
      selector: (row) => row.warrantyCategory,
      wrap: true,
      sortable: false,
      // cell: (row) => row.warrantyCategory || "Supplier Warranty",
    },
    {
      id: "warrentyStartDate",
      name: <div>Warranty Start Date</div>,
      selector: (row) => row.warrantyStartDate,
      wrap: true,
      sortable: false,
      cell: (row) => (row.warrantyStartDate ? <Moment format="DD-MM-YYYY">{row.warrantyStartDate}</Moment> : ""),
    },
    {
      id: "warrentyEndDate",
      name: <div>Warranty End Date</div>,
      selector: (row) => row.warrantyEndDate,
      wrap: true,
      sortable: false,
      cell: (row) => (row.warrantyEndDate ? <Moment format="DD-MM-YYYY">{row.warrantyEndDate}</Moment> : ""),
    },
    {
      id: "supplierId",
      name: <div>Supplier ID</div>,
      selector: (row) => row?.supplierId,
      wrap: true,
      sortable: false,
      cell: (row) => row.supplierId || "",
    },
    {
      id: "supplierName",
      name: <div>Supplier Name</div>,
      selector: (row) => row?.supplierName,
      wrap: true,
      sortable: false,
      cell: (row) => row.supplierName || "",
    },
    {
      id: "ComponentsActions",
      name: <div>Actions</div>,
      wrap: true,
      sortable: true,
      minWidth: 200,
      cell: (row) => <SelectComponent row={row} onChange={handleRowSelect} />,
    },
  ];

  const handleRowSelect = (row, value) => {
    if (value === "Buy Parts") {
      hadnleOpenPartItemsModal(row);
    } else if (value === "Request For Repair") {
      handleOpenRepairRequestModal(row);
    }
  };

  // sub component columns new
  const subComponentsColumns = [
    {
      id: "subComponentId",
      name: <div>Sub-Component #</div>,
      selector: (row) => (
        <span className="cursor font-weight-500" style={{ textDecoration: "underline" }} onClick={() => handleViewComponetDtls(row)}>
          {row.componentCode}
        </span>
      ),
      wrap: true,
      sortable: false,
    },
    {
      id: "subComponentSerialNumber",
      name: <div>Serial Number</div>,
      selector: (row) => row.serialNumber,
      wrap: true,
      sortable: false,
      // cell: (row) => row.serialNumber || "ZMX00507",
    },
    {
      id: "subComponentWarrentyCategory",
      name: <div>Warranty Category</div>,
      selector: (row) => row.warrantyCategory,
      wrap: true,
      sortable: false,
      // cell: (row) => row.warrantyCategory || "Supplier Warranty",
    },
    {
      id: "subComponentWarrentyStartDate",
      name: <div>Warranty Start Date</div>,
      selector: (row) => row.warrantyStartDate,
      wrap: true,
      sortable: false,
      cell: (row) => (row.warrantyStartDate ? <Moment format="DD-MM-YYYY">{row.warrantyStartDate}</Moment> : ""),
    },
    {
      id: "subComponentWarrentyEndDate",
      name: <div>Warranty End Date</div>,
      selector: (row) => row.warrantyEndDate,
      wrap: true,
      sortable: false,
      cell: (row) => (row.warrantyEndDate ? <Moment format="DD-MM-YYYY">{row.warrantyEndDate}</Moment> : ""),
    },
    {
      id: "subComponentSupplierId",
      name: <div>Supplier ID</div>,
      selector: (row) => row.supplierId,
      wrap: true,
      sortable: false,
      // cell: (row) => row.supplierId || "PS235",
    },
    {
      id: "subComponentSupplierId",
      name: <div>Supplier Name</div>,
      selector: (row) => row.supplierName,
      wrap: true,
      sortable: false,
      // cell: (row) => row.supplierName || "Caterpillar",
    },
    {
      id: "ComponentsActions",
      name: <div>Actions</div>,
      wrap: true,
      sortable: true,
      minWidth: 200,
      cell: (row) => <SelectComponent row={row} onChange={handleRowSelect} />,
    },
  ];

  const [componentLoading, setComponentLoading] = useState(false);
  const [subComponentLoading, setSubComponentLoading] = useState(false);
  const [components, setComponents] = useState(null);
  const [subComponents, setSubComponents] = useState(null);
  const [expandedComponentRowId, setExpandedComponentRowId] = useState(null);
  const [expandedSubComponentRowId, setExpandedSubComponentRowId] = useState(null);

  const fetchComponentDetails = async (isExpanded, row) => {
    if (isExpanded) {
      try {
        setComponentLoading(true);
        setExpandedComponentRowId(null);
        setExpandedSubComponentRowId(null);
        const comp = await fetchComponents(row.componentCodeIds);
        setComponents(comp);
        setExpandedComponentRowId(row.id);
        // console.log(comp)
      } catch (error) {
        console.error("Error fetching components:", error);
      } finally {
        setComponentLoading(false);
      }
    } else {
      setExpandedComponentRowId(null);
      setExpandedSubComponentRowId(null);
    }
  };

  const fetchSubComponentsDetails = async (isExpanded, row) => {
    if (isExpanded) {
      try {
        setSubComponentLoading(true);
        setExpandedSubComponentRowId(null);
        const subComp = await fetchSubComponents([row.id]);
        setSubComponents(subComp);
        setExpandedSubComponentRowId(row.id);
        // console.log(subComp);
      } catch (error) {
        console.error("Error fetching Subcomponents:", error);
      } finally {
        setSubComponentLoading(false);
      }
    } else {
      setExpandedSubComponentRowId(null);
    }
  };

  const hadnleOpenPartItemsModal = async (row) => {
    const rowData = rowsData.find((data) => data.id === expandedComponentRowId);
    if (rowData) {
      setExpendedEquipmentRow(rowData);
    }
    setSelectedComponentRow(row);
    await fetchPartsDetails(row["sparePartsQuantities"]); //row.groupNumber
    setOpenPartModal(true);
  };

  const fetchPartsDetails = async (partIds) => {
    try {
      if (partIds && Object.keys(partIds).length > 0) {
        let partIdsUrl = Object.keys(partIds)
          .map((key) => `ids=${key}`)
          .join("&");
        const partRes = await fetchPartsByIds(partIdsUrl);
        console.log("partRes :::: ", partRes);
        partRes.forEach((part) => {
          const id = part.id;
          if (partIds[id]) {
            // part.quantity = 0;
            part.quantity = partIds[id];
            part.required = partIds[id];
            // part.availableItems = Math.floor(Math.random() * 5) + 10;
          }
        });
        setPartsData(partRes);
        // const partsres = await fetchParts([2276044]);
        // for (let i = 0; i < partRes.length; i++) {
        //   partRes[i].quantity = 0;
        //   partRes[i].availableItems = Math.floor(Math.random() * 5) + 10;
        // }
      }
    } catch (error) {
      console.error("Error fetching Parts:", error);
    } finally {
      setSubComponentLoading(false);
    }
  };

  // expendable component row
  const expendableComponentRow = (row) => {
    return (
      <div className="p-3 border-bottom">
        <div className="border border-radius-10">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex align-items-center">
              <h6 className="mb-0 font-weight-600 font-size-14 mr-3">Components</h6>
            </div>
          </div>
          {componentLoading ? (
            <RectangularLoader />
          ) : (
            <>
              {components.length !== 0 ? (
                <>
                  <DataTable
                    data={components} //row.components
                    columns={componentsColumns}
                    customStyles={dataTableCustomStyle}
                    expandableRows={true}
                    expandableRowsComponent={(itemData) => expendedableSubComponentRow(itemData.data, true)}
                    onRowExpandToggled={fetchSubComponentsDetails}
                    expandableRowExpanded={(row) => row.id === expandedSubComponentRowId}
                    pagination={true}
                    paginationTotalRows={components.length}
                    paginationPerPage={componentPerPage}
                    paginationRowsPerPageOptions={[5, 10]}
                    onChangeRowsPerPage={(e) => {
                      setComponentPage(1);
                      setComponentPerPage(e);
                    }}
                    paginationDefaultPage={componentPage}
                  />
                </>
              ) : (
                <>
                  <div className="text-center pb-2">
                    <h4 className="text-muted">No Components Available</h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  // expendable sub component row
  const expendedableSubComponentRow = (row) => {
    return (
      <div className="p-4 border-bottom">
        <div className="border border-radius-10">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex align-items-center">
              <h6 className="mb-0 font-weight-600 font-size-14 mr-3">Sub Components</h6>
            </div>
          </div>
          {subComponentLoading ? (
            <RectangularLoader />
          ) : (
            <>
              {subComponents.length !== 0 ? (
                <>
                  <DataTable
                    customStyles={dataTableCustomStyle}
                    data={subComponents} //row.subcomponents
                    columns={subComponentsColumns}
                    pagination={true}
                    paginationTotalRows={subComponents.length}
                    paginationPerPage={subComponentPerPage}
                    paginationRowsPerPageOptions={[5, 10]}
                    onChangeRowsPerPage={(e) => {
                      setSubComponentPage(1);
                      setSubComponentPerPage(e);
                    }}
                    paginationDefaultPage={subComponentPage}
                  />
                </>
              ) : (
                <>
                  <div className="text-center pb-2">
                    <h4 className="text-muted">No Subcomponents Available</h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="mb-4 mt-2">
        <DataTable
          data={rowsData}
          columns={columns}
          customStyles={dataTableCustomStyle}
          expandableRows={true}
          expandableRowsComponent={(itemData) => expendableComponentRow(itemData.data, true)}
          onRowExpandToggled={fetchComponentDetails}
          expandableRowExpanded={(row) => row.id === expandedComponentRowId}
          pagination
          paginationServer
          paginationPerPage={equipmentPerPage}
          onChangePage={(e) => setEquipmentPage(e)}
          onChangeRowsPerPage={(e) => {
            setEquipmentPage(1);
            setEquipmentPerPage(e);
          }}
          paginationTotalRows={totalEquipmentRows}
          paginationRowsPerPageOptions={[5, 10]}
          paginationDefaultPage={equipmentPage}
          progressPending={equpLoading}
          progressComponent={<LoadingProgress />}
        />
      </div>
      {/* </Box> */}

      {openPartModal && (
        <PartsCartItemsModal
          show={openPartModal}
          hideModal={() => setOpenPartModal(false)}
          partsData={partsData}
          handleSnack={handleSnack}
          expendedEquipmentRow={expendedEquipmentRow}
          selectedComponentRow={selectedComponentRow}
        />
      )}

      {openCompOverviewModal && (
        <EqupComponentOverviewModal
          show={openCompOverviewModal}
          hideModal={() => setOpenCompOverviewModal(false)}
          componentRow={componentRow}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default EquipmentMachinesTableList;
