import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import { PARTS_GET_ACTIVE_CART_GET } from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

//Get Cart Parts
export const getCartParts = (id) => {
  console.log("service commerce > getCartParts called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${PARTS_GET_ACTIVE_CART_GET}?user_id=${id}`, getAccessToken())
        .then((res) => {
          console.log("commmerce -> getCartParts response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("getCartParts > axios err=", err);
          reject("Error in getCartParts axios!");
        });
    } catch (error) {
      console.error("getCartParts general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};
