import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import SearchBox from "./common/SearchBox";
import { callPutApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { DATA_SVC_COMPONENT_DATA_BY_ID_GET } from "../services/CONSTANTS";
import { sparePartSearch } from "../services/searchServices";
import { fetchPartsByIds } from "../services/EquipmentService";

const EqupComponentPartsAdd = ({
  show,
  hideModal,
  handleSnack,
  partLists,
  setPartLists,
  componentRecord,
  setPartsLoading,
  setComponentRecord,
  setUpdatePartsTable,
}) => {
  const [recordObj, setRecordObj] = useState({
    id: "",
    partNumber: "",
    partDescription: "",
    quantity: "",
    status: "",
  });

  const [searchPartsResult, setSearchPartsResult] = useState([]);
  const [noOptionsParts, setNoOptionsParts] = useState(false);

  // search Parts
  const handlePartSearch = async (searchText) => {
    setSearchPartsResult([]);
    recordObj.id = "";
    recordObj.partNumber = searchText;
    recordObj.partDescription = "";
    recordObj.status = "";

    if (searchText) {
      await sparePartSearch(`partNumber~${searchText}`)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchPartsResult(result);
            setNoOptionsParts(false);
          } else {
            setNoOptionsParts(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the job code from search result
  const handlePartSelect = (type, currentItem) => {
    setRecordObj({
      ...recordObj,
      ...currentItem,
      id: currentItem.id,
      partNumber: currentItem.partNumber,
      partDescription: currentItem.partDescription,
      status: currentItem.status,
    });
    setSearchPartsResult([]);
  };

  // save the changes
  const handleSaveChanges = () => {
    if (!recordObj.partNumber) {
      handleSnack("error", "Part number must not be empty!");
      return;
    } else if (recordObj.partNumber && searchPartsResult.length !== 0) {
      handleSnack("info", "Select a Part number from search list!");
      return;
    } else if (recordObj.partNumber && noOptionsParts) {
      handleSnack("info", "Search a valid Part number!");
      return;
    } else if (!recordObj?.quantity) {
      handleSnack("info", "Quantity must not be empty!");
      return;
    } else if (recordObj?.quantity < 0) {
      handleSnack("info", "Quantity must not be negative!");
      return;
    }
    let _partsIds = {};
    if (componentRecord["sparePartsQuantities"]) {
      _partsIds = componentRecord["sparePartsQuantities"];
    }
    _partsIds[recordObj.id] = Number(recordObj?.quantity);
    // _partsIds.push(recordObj.id);
    const rObj = { ...componentRecord, sparePartsQuantities: _partsIds };
    const rUrl = `${DATA_SVC_COMPONENT_DATA_BY_ID_GET}${componentRecord?.id}`;
    callPutApi(
      null,
      rUrl,
      rObj,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          const _partLists = [...partLists];
          console.log("_partLists :::: ", responseData);

          setComponentRecord({ ...responseData });
          const exists = _partLists.some((item) => item.id === recordObj?.id);
          if (exists) {
          }

          // setPartLists(partRes);
          // setPartLists((prevItems) => prevItems.map((item) => (item.id === recordObj?.id ? { ...item, quantity: recordObj?.quantity } : item)));
          // setUpdatePartsTable((pre) => pre + 1);
          fetchPartsDetails(_partsIds);
          // hideModal();
        } else {
          handleSnack("error", "Somthing went wrong.");
        }
      },
      (error) => {
        handleSnack("error", "Somthing went wrong.");
      }
    );
  };

  // fetch
  const fetchPartsDetails = async (partIds) => {
    try {
      if (partIds && Object.keys(partIds).length > 0) {
        setPartsLoading(true);
        let partIdsUrl = Object.keys(partIds)
          .map((key) => `ids=${key}`)
          .join("&");
        const partRes = await fetchPartsByIds(partIdsUrl);

        partRes.forEach((part) => {
          const id = part.id;
          if (partIds[id]) {
            part.quantity = partIds[id];
            // part.required = partIds[id];
            // part.availableItems = Math.floor(Math.random() * 5) + 10;
          }
        });
        setPartLists(partRes);
        setPartsLoading(false);
        hideModal();
      }
    } catch (error) {
      setPartsLoading(false);
      hideModal();
    } finally {
      setPartsLoading(false);
      hideModal();
    }
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <h5>Add Parts</h5>
        <div className="card border mt-1 px-3 py-4">
          <div className="row input-fields">
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PART NUMBER</label>
                <SearchBox
                  value={recordObj.partNumber}
                  onChange={(e) => handlePartSearch(e.target.value)}
                  type="partNumber"
                  result={searchPartsResult}
                  onSelect={handlePartSelect}
                  noOptions={noOptionsParts}
                />
                <div className="css-w8dmq8">*Mandatory</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PART NAME</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="partDescription"
                  value={recordObj?.partDescription}
                  disabled
                  placeholder="Part Name"
                  //   onChange={handleGenDtlsTextChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Status</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="status"
                  value={recordObj?.status}
                  disabled
                  placeholder="Status"
                  //   onChange={handleGenDtlsTextChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Quantity</label>
                <input
                  type="number"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  value={recordObj?.quantity}
                  placeholder="Quantity"
                  onChange={(e) => setRecordObj({ ...recordObj, quantity: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6 col-sm-6">
            <button className="btn border-primary text-primary w-100" onClick={hideModal}>
              CANCEL
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button className="btn bg-primary text-white w-100" onClick={handleSaveChanges}>
              SAVE
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EqupComponentPartsAdd;
