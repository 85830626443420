import React, { useContext, useEffect, useState } from "react";

import deleteIcon from "../../assets/icons/svg/delete.svg";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { Accordion, Modal } from "react-bootstrap";
import CustomerOrderAddress from "./CustomerOrderAddress";
import CustomerOrderPayment from "./CustomerOrderPayment";
import "../../shared/numberSpinner.css";
import { AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from "@mui/material";
import { getQuoteCommonConfig } from "../../services/quoteService";
import { PartsContext } from "../../context/PartsContext";
import NumberSpinner from "../../shared/NumberSpinner";
import OrderPayment from "./OrderPayment";
import { de } from "date-fns/locale";
import { currencyFormatter } from "../common/utils/currencyFormatter";

const ShoppingCartList = () => {
  const { cartParts, setCartParts, portfolioCarts, setPortfolioCarts } = useContext(PartsContext);
  const [activeStep, setActiveStep] = useState(1);
  const [count, setCount] = useState(0);
  const [mouseDownDirection, setMouseDownDirection] = useState(null);

  const [age, setAge] = React.useState("5");
  const [age1, setAge1] = React.useState("5");
  const [age2, setAge2] = React.useState("5");

  const [openNewAddressAddModal, setOpenNewAddressAddModal] = useState(false);
  const [openNewPaymentAddModal, setOpenNewPaymentAddModal] = useState(false);
  const [openOrderPaymentModal, setOpenOrderPaymentModal] = useState(false);

  const [expanded, setExpanded] = useState("paypal");

  const [addressDetails, setAddressDetails] = useState({
    deliveryType: { label: "Standard", value: "STANDARD" },
    deliveryPriority: { label: "Normal", value: "NORMAL" },
    leadTime: "20",
    leadTimeUnit: { value: "per Hr", label: "per Hr" },
    recipientAddress: "1 Alan St, Rydalmere NSW 2116, Australia",
  });

  const [billingnDetails, setBillingnDetails] = useState({
    paymentTerms: { label: "Immediate", value: "IMMEDIATE" },
    currency: { label: "USD", value: "USD" },
    billingType: { label: "Fixed Price", value: "FIXED_PRICE" },
    billingFrequency: { label: "One time", value: "ONE_TIME" },
  });

  const [quoteCurrencyOptions, setQuoteCurrencyOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([]);
  const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);

  useEffect(() => {
    // Billing-Type
    getQuoteCommonConfig("billing-type")
      .then((res) => {
        const options = res.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setBillingTypeOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // quote-Currency
    getQuoteCommonConfig("currency")
      .then((res) => {
        const options = res.map((d) => ({
          value: d,
          label: d,
        }));
        setQuoteCurrencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // Billing-Frequency
    getQuoteCommonConfig("billing-frequency")
      .then((res) => {
        const options = res.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setBillingFrequencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const handleChangedrop = (event) => {
    setAge(event.target.value);
  };
  const handleChangedrop1 = (event) => {
    setAge1(event.target.value);
  };
  const handleChangedrop2 = (event) => {
    setAge2(event.target.value);
  };

  // update cart quantity
  const updateCartValue = async (value, itemrow) => {
    let _cartParts = [...cartParts];
    let row = _cartParts.find((obj) => obj?.id === itemrow?.id);
    const exists = !!row;
    if (exists) {
      row = { ...row, quantity: value };
    }
    _cartParts = _cartParts.map((obj) => (obj?.id === row?.id ? row : obj));
    setCartParts(_cartParts);
    setCount(count + 1);
  };

  // cart increase-decrease
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row.quantity + (1 || 1);
        break;
      case "down":
        next = row.quantity - (1 || 1) >= 0 ? row.quantity - (1 || 1) : 0;
        break;
      default:
        next = row.quantity;
        break;
    }
    updateCartValue(next, row);
    if (next !== 0) {
    }
  };

  const steps = ["Login", "Find solutions", "Add to cart", "Review", "Order"];

  const cartOrderSteps = ["Shopping Cart", "Shipping & Billing", "Payment"];

  // order now
  const handleOrderPayment = () => {
    // setOpenOrderPaymentModal(true);
    setActiveStep(2);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const orderPayment = () => {
    return (
      <div className="card border px-2 mt-3">
        <Grid container spacing={2} sx={{ padding: 2, marginTop: 3 }}>
          <Grid xs={7}>
            {/* <div className="card border px-2 pt-3"> */}
            <h5>Payment Method</h5>
            <Accordion square expanded={expanded === "paypal"} onChange={() => handleChange("paypal")}>
              <AccordionSummary
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                className="accordion-summary-root"
                // aria-controls="panel1d-content"
                // id="panel1d-header"
              >
                <Typography>PayPal</Typography>
                <img src="https://logos-world.net/wp-content/uploads/2020/08/PayPal-Logo.jpg" width="80" />
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: 0 }}>
                <input type="text" className="form-control text-primary" placeholder="Email or Mobile Number" />
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === "creditCard"} onChange={() => handleChange("creditCard")}>
              <AccordionSummary className="accordion-summary-root">
                <Typography>Credit card</Typography>
                <div className="icons">
                  <img src="https://i.imgur.com/2ISgYja.png" width="50" />
                  <img src="https://i.imgur.com/W1vtnOV.png" width="50" />
                  <img src="https://i.imgur.com/35tC99g.png" width="50" />
                  <img src="https://i.imgur.com/2ISgYja.png" width="50" />
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: 0 }} className="px-2 mt-0">
                <div className="card-body payment-card-body px-1 mt-0">
                  <span className="font-weight-normal card-text">Card Number</span>
                  <div className="card-payment-input">
                    <i className="fa fa-credit-card"></i>
                    <input type="text" className="form-control" placeholder="0000 0000 0000 0000" />
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-md-6">
                      <span className="font-weight-normal card-text">Expiry Date</span>
                      <div className="card-payment-input">
                        <i className="fa fa-calendar"></i>
                        <input type="text" className="form-control" placeholder="MM/YY" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <span className="font-weight-normal card-text">CVC/CVV</span>
                      <div className="card-payment-input">
                        <i className="fa fa-lock"></i>
                        <input type="text" className="form-control" placeholder="000" />
                      </div>
                    </div>
                  </div>

                  <span className="text-muted certificate-text">
                    <i className="fa fa-lock"></i> Your transaction is secured with ssl certificate
                  </span>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* </div> */}
          </Grid>
          <Grid xs={5}>
            <h5>Summary</h5>
            <div className="card">
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex flex-column">
                  <span>
                    Pro(Billed Monthly) <i className="fa fa-caret-down"></i>
                  </span>
                  {/* <a href="#" className="billing text-primary">
                    Save 20% with annual billing
                  </a> */}
                </div>

                <div className="mt-1">
                  <sup className="super-price">
                    {cartParts.length !== 0 &&
                      currencyFormatter.format(
                        cartParts.reduce((sum, cartItem) => {
                          return sum + cartItem.listPrice * cartItem.quantity;
                        }, 0)
                      )}
                    {portfolioCarts.length !== 0 &&
                      currencyFormatter.format(
                        portfolioCarts.reduce((sum, cartItem) => {
                          return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                        }, 0)
                      )}
                  </sup>
                  <span className="super-month">/Month</span>
                </div>
              </div>
              <hr className="mt-0 line" />
              <div className="p-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>Refferal Bonouses</span>
                  <span>
                    -
                    {/* {portfolioCarts.lenght !== 0
                              ? currencyFormatter.format(
                                  portfolioCarts.reduce((sum, cartItem) => {
                                    return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                  }, 0)
                                )
                              : "$0.00"} */}
                    <> 2% | </> REP Discount
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <span>Vat {/* <i className="fa fa-clock-o"></i> */}</span>
                  <span>-$0.00</span>
                </div>
              </div>
              <hr className="mt-0 line" />
              <div className="p-3 d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span>Address</span>
                  <span>{addressDetails?.recipientAddress}</span>
                  {/* <small>After 30 days $9.59</small> */}
                </div>
                {/* <span>$0</span> */}
              </div>
              <div className="d-flex px-3">
                <a href="#">Have a promo code?</a>
              </div>
              <div className="row m-0 mt-2 py-3">
                <div className="col-md-6 col-sm-6">
                  <button className="btn border w-100 bg-white" onClick={() => setActiveStep(1)}>
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6 col-sm-6">
                  <button className="btn btn-primary w-100 cursor">PAY NOW</button>
                </div>
              </div>
              {/* <div className="d-flex justify-content-between align-item-center p-3 ">
                <button className="btn border-primary btn-block">Pay Now</button>
                <button className="btn btn-primary btn-block free-button">Pay Now</button>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {/* <CommanComponents /> */}
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid">
          <h4 className="font-weight-600 mb-0 mt-4 mb-3">Review Order</h4>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {cartOrderSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 1 && (
            <>
              <div className="padding-custom">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-9 col-sm-9">
                      <div className="card" style={{ overflow: "hidden" }}>
                        <h5 className="mb-0 bg-primary px-4 py-2 text-white">Shopping Cart</h5>
                        <div className="row p-4">
                          {portfolioCarts.lenght !== 0 &&
                            portfolioCarts.map((cartItem, i) => (
                              <div className="col-md-6 col-sm-6 card bg-light p-3" key={cartItem?.partNumber}>
                                <div className="row mb-0">
                                  <div className="col-md-3 col-sm-3">
                                    <div className="w-100">
                                      <img src="./assets/images/gray.png"></img>
                                    </div>
                                  </div>
                                  <div className="col-md-9 col-sm-9">
                                    <div className="d-flex justify-content-between px-2">
                                      <div>
                                        <h6 className="">
                                          <b>{cartItem?.name}</b>
                                        </h6>
                                        <p className="mt-4">{cartItem?.description}</p>
                                        <div className="d-flex align-items-center px-1 mt-4">
                                          <NumberSpinner
                                            portfolioCartId={cartItem.portfolioCart.portfolioCartId}
                                            qnt={cartItem.portfolioCart.quantity}
                                            setCartItems={setPortfolioCarts}
                                            steps={1}
                                            // setConfirmationOpen={setConfirmationOpen}
                                          />
                                          <button
                                            // onClick={() => setConfirmationOpen(true)}
                                            className="btn bg-primary text-white remove-button mx-2"
                                            style={{ fontSize: 12, height: 26 }}
                                          >
                                            Remove
                                          </button>
                                          <button
                                            // onClick={() => setShow(true)}
                                            className="btn bg-primary text-white remove-button"
                                            style={{ fontSize: 12, height: 26 }}
                                          >
                                            Configure
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {cartParts.length !== 0 &&
                            cartParts.map((cartItem, i) => (
                              <div className="col-md-6 col-sm-6 card bg-light p-3" key={cartItem?.partNumber}>
                                <div className="row mb-0">
                                  <div className="col-md-3 col-sm-3">
                                    <div className="w-100">
                                      <img src="./assets/images/spare-parts.png" width={100} height={121} style={{ objectFit: "cover" }} />
                                    </div>
                                  </div>
                                  <div className="col-md-9 col-sm-9">
                                    <div className="d-flex justify-content-between px-2">
                                      <div>
                                        <h6 className="">
                                          <b>{cartItem?.partNumber}</b>
                                        </h6>
                                        <p className="mt-4">{cartItem?.partDescription}</p>
                                        <div className="d-flex align-items-center mt-4">
                                          <div className="input-number mr-2">
                                            <button
                                              onClick={(e) => {
                                                handleButtonChange("down", cartItem);
                                              }}
                                              onMouseDown={() => setMouseDownDirection("down", cartItem)}
                                              onMouseOut={() => setMouseDownDirection(null)}
                                              onMouseUp={() => setMouseDownDirection(null)}
                                              disabled={cartItem.quantity === 1}
                                            >
                                              -
                                            </button>
                                            <input type="number" step={1} value={cartItem.quantity} readOnly />
                                            <button
                                              onClick={(e) => {
                                                handleButtonChange("up", cartItem);
                                              }}
                                              onMouseDown={() => setMouseDownDirection("up", cartItem)}
                                              onMouseUp={() => setMouseDownDirection(null)}
                                              onMouseOut={() => setMouseDownDirection(null)}
                                            >
                                              +
                                            </button>
                                          </div>
                                          <button
                                            // onClick={() => setConfirmationOpen(true)}
                                            className="btn bg-primary text-white remove-button mx-2"
                                            style={{ fontSize: 12, height: 26 }}
                                          >
                                            Remove
                                          </button>
                                          <button
                                            // onClick={() => setConfirmationOpen(true)}
                                            className="btn border-primary text-primary remove-button mx-2"
                                            style={{ fontSize: 12, height: 26 }}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                      <h5 style={{ fontSize: "25px", fontWeight: "600" }}>
                                        {currencyFormatter.format(cartItem?.listPrice * cartItem?.quantity)}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="card" style={{ overflow: "hidden" }}>
                        <div className="d-flex align-items-center px-4 py-2 bg-primary">
                          <h6 className=" text-white mb-0 mr-3">Your Total Price</h6>
                          <h6 className="mb-0 text-white" style={{ fontSize: "25px" }}>
                            {cartParts.length !== 0 &&
                              currencyFormatter.format(
                                cartParts.reduce((sum, cartItem) => {
                                  return sum + cartItem.listPrice * cartItem.quantity;
                                }, 0)
                              )}
                            {portfolioCarts.length !== 0 &&
                              currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                          </h6>
                        </div>
                        <div className="px-4 py-2">
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Currency</p>
                            <h6 className="mb-0">
                              <b>USD</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Spare Parts</p>
                            <h6 className="mb-0">
                              <b>
                                {cartParts.length !== 0
                                  ? currencyFormatter.format(
                                      cartParts.reduce((sum, cartItem) => {
                                        return sum + cartItem.listPrice * cartItem.quantity;
                                      }, 0)
                                    )
                                  : currencyFormatter.format(
                                      portfolioCarts.reduce((sum, cartItem) => {
                                        return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                      }, 0)
                                    )}
                              </b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Labor Charge</p>
                            <h6 className="mb-0">
                              <b>
                                {portfolioCarts.lenght !== 0
                                  ? currencyFormatter.format(
                                      portfolioCarts.reduce((sum, cartItem) => {
                                        return sum + cartItem.portfolioPrice.labourPrice * cartItem.portfolioCart.quantity;
                                      }, 0)
                                    )
                                  : "$0.00"}
                              </b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Misc.</p>
                            <h6 className="mb-0">
                              <b>
                                {portfolioCarts.lenght !== 0
                                  ? currencyFormatter.format(
                                      portfolioCarts.reduce((sum, cartItem) => {
                                        return sum + cartItem.portfolioPrice.miscPrice * cartItem.portfolioCart.quantity;
                                      }, 0)
                                    )
                                  : "$0.00"}
                              </b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Tax</p>
                            <h6 className="mb-0">
                              <b>$0.00</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Discount</p>
                            <h6 className="mb-0">
                              {/* <b>
                            -{" "}
                            {portfolioCarts.lenght !== 0
                              ? currencyFormatter.format(
                                  portfolioCarts.reduce((sum, cartItem) => {
                                    return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                  }, 0)
                                )
                              : "$0.00"}
                          </b> */}
                              <b> 2% | </b> REP Discount
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Total</p>
                            <h6 className="mb-0">
                              <b>
                                {cartParts.length !== 0
                                  ? currencyFormatter.format(
                                      cartParts.reduce((sum, cartItem) => {
                                        return sum + cartItem.listPrice * cartItem.quantity;
                                      }, 0)
                                    )
                                  : currencyFormatter.format(
                                      portfolioCarts.reduce((sum, cartItem) => {
                                        return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                      }, 0)
                                    )}
                              </b>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card" style={{ overflow: "hidden" }}>
                        <div className="d-flex justify-content-between bg-green px-4 py-2 text-white mb-0">
                          <h6 className="bg-green text-white mb-0">Shipping Details</h6>
                          <Tooltip title="Add New Address">
                            <AddLocationAltIcon className="cursor" onClick={() => setOpenNewAddressAddModal(true)} />
                          </Tooltip>
                        </div>
                        <div className="px-4 py-2">
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Delivery Type</p>
                            <h6 className="mb-0">
                              <b>{addressDetails?.deliveryType?.label || "Standard"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Delivery Priority</p>
                            <h6 className="mb-0">
                              <b>{addressDetails?.deliveryPriority?.label || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Lead Time</p>
                            <h6 className="mb-0">
                              <b>{"$" + (addressDetails?.leadTime || 0) + " " + addressDetails?.leadTimeUnit?.label || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-block py-2">
                            <p className="mb-0">Address</p>
                            <h6 className="mb-0">
                              <b>{addressDetails?.recipientAddress}</b>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-2" style={{ overflow: "hidden" }}>
                        <div className="d-flex justify-content-between bg-green px-4 py-2 text-white mb-0">
                          <h6 className="bg-green text-white mb-0">Billing Details</h6>

                          <Tooltip title="Add New Payment Method">
                            <ControlPointIcon className="cursor" onClick={() => setOpenNewPaymentAddModal(true)} />
                          </Tooltip>
                        </div>
                        <div className="px-4 py-2">
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Payment Terms</p>
                            <h6 className="mb-0">
                              <b>{billingnDetails?.paymentTerms?.label || "Immediate"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Currency</p>
                            <h6 className="mb-0">
                              <b>{billingnDetails?.currency?.value || "USD"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Billing Type</p>
                            <h6 className="mb-0">
                              <b>{billingnDetails?.billingType?.label || "Fixed Price"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Billing Frequency</p>
                            <h6 className="mb-0">
                              <b>{billingnDetails?.billingFrequency?.label || "One Time"}</b>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 mt-2">
                        <div className="col-md-6 col-sm-6">
                          <button
                            className="btn border w-100 bg-white"
                            // onClick={hideModal}
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <button className="btn btn-primary cursor" onClick={handleOrderPayment}>
                            ORDER NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer-bottom">
                <div className="card py-2 px-4 mb-0" style={{ borderRadius: "0" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      <h4 className="mb-0">
                        <ErrorOutlineIcon className="mr-3" />
                        Information
                      </h4>
                    </div>
                    {cartParts.length !== 0 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">SPARE PARTS</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                cartParts.reduce((sum, cartItem) => {
                                  return sum + cartItem.listPrice * cartItem.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <AddIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">LABOR CHARGE</p>
                          <h6 className="mb-0">
                            <b>$0.00</b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <AddIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">MISC.</p>
                          <h6 className="mb-0">
                            <b>$0.00</b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <RemoveIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">DISCOUNT</p>
                          <h6 className="mb-0">
                            <b>$0.00</b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <DragHandleIcon />
                        </div>
                        <div className="d-block">
                          <p className="mb-0">TOTAL</p>
                          <h6 className="mb-0 text-primary" style={{ fontSize: "25px" }}>
                            <b>
                              {currencyFormatter.format(
                                cartParts.reduce((sum, cartItem) => {
                                  return sum + cartItem.listPrice * cartItem.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}
                    {portfolioCarts.length !== 0 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">SPARE PARTS</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <AddIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">LABOR CHARGE</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.labourPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <AddIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">MISC.</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.miscPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <RemoveIcon />
                        </div>
                        <div className="d-block" style={{ marginRight: "30px" }}>
                          <p className="mb-0">DISCOUNT</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.sparePartsPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="d-flex" style={{ marginRight: "30px" }}>
                          <DragHandleIcon />
                        </div>
                        <div className="d-block">
                          <p className="mb-0">TOTAL</p>
                          <h6 className="mb-0 text-primary" style={{ fontSize: "25px" }}>
                            <b>
                              {currencyFormatter.format(
                                portfolioCarts.reduce((sum, cartItem) => {
                                  return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                }, 0)
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </footer>
            </>
          )}

          {activeStep === 2 && orderPayment()}
        </div>
      </div>
      {openNewAddressAddModal && (
        <CustomerOrderAddress
          show={openNewAddressAddModal}
          hideModal={() => setOpenNewAddressAddModal(false)}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
        />
      )}
      {openNewPaymentAddModal && (
        <CustomerOrderPayment
          show={openNewPaymentAddModal}
          hideModal={() => setOpenNewPaymentAddModal(false)}
          currencyOptions={quoteCurrencyOptions}
          billingTypeOptions={billingTypeOptions}
          billingFrequencyOptions={billingFrequencyOptions}
          billingnDetails={billingnDetails}
          setBillingnDetails={setBillingnDetails}
        />
      )}

      {openOrderPaymentModal && (
        <OrderPayment
          show={openOrderPaymentModal}
          hideModal={() => setOpenOrderPaymentModal(false)}
          currencyFormatter={currencyFormatter}
          cartParts={cartParts}
          portfolioCarts={portfolioCarts}
        />
      )}
    </>
  );
};

export default ShoppingCartList;
