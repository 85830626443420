import { Modal, Stack } from "react-bootstrap";
import { Autocomplete, Box, Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { fetchCoverageSuggestions, fetchPortfolioCoverageSuggestions, getPortfolioSearch } from "../services/portfolioServices";
import PortfolioConfigModal from "./PortfolioConfigModal";
import { CATEGORIES } from "./CONSTANTS";
import PortfolioSearchResult from "./PortfolioSearchResult";
import searchIcon from "../assets/icons/svg/search.svg";
import PortfolioConfig from "./PortfolioConfig";
import { currencyFormatter } from "./common/utils/currencyFormatter";

export default function SearchHome(props) {
  const { show, handleClose, cartItems, handleSnack } = props;
  // const { note, setNote } = useState("");
  // const handleCreateQuote = () => {
  //     handleSnack("success", "Converted to Quote")
  // }
  const [category, setCategory] = useState("");
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [model, setModel] = useState("");
  const [family, setFamily] = useState("");
  const [prefix, setPrefix] = useState("");
  const [result, setResult] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState({});
  function handleFilterChange(event, newValue) {
    if (event) findSuggestions(event.target.name, newValue);
  }
  const findSuggestions = async (searchCategory, searchText) => {
    const searchCriteriaList = [
      {
        filterKey: "modelNo",
        value: searchText,
        operation: "cn",
      },
    ];

    let searchCriteria = {
      searchCriteriaList: searchCriteriaList,
      dataOption: "all",
      listItem: "modelNo",
    };
    if (searchText && searchCategory) {
      fetchPortfolioCoverageSuggestions(`modelNo~"${searchText}" AND visibleInCommerce:true`).then((result) => {
        // console.log("result :::: ", result);
        if (result && result.length > 0) {
          let _model = new Set();
          result.forEach((item) => {
            if (item.visibleInCommerce) {
              if (item.coverages.length > 0) {
                item.coverages.forEach((coverageItem) => {
                  if (coverageItem.modelNo) {
                    _model.add(coverageItem.modelNo);
                  }
                });
              }
            }
          });

          const uniquePrefixArray = Array.from(_model);
          setModelSuggestions(uniquePrefixArray);
        } else {
          setModelSuggestions([]);
        }
        if (!open) setOpen(true);
      });
      // fetchCoverageSuggestions(searchCriteria).then((results) => {
      //   setModelSuggestions(results.data.map((indModel) => indModel.value));
      // });
    }
  };
  async function handleChangeModel(value) {
    setResult([]);
    setCategory("");
    setModel(value);
    setOpen(false);
    let searchPayload = {
      searchCriteriaList: [
        {
          filterKey: "modelNo",
          value: value,
          operation: "eq",
        },
      ],
      dataOption: "all",
    };
    if (value) {
      fetchPortfolioCoverageSuggestions(`modelNo:"${value}" AND visibleInCommerce:true`)
        .then((data) => {
          if (data.length === 0) {
            props.handleSnack("info", "No matching result found!");
          } else {
            let visiblePortfolio = data.map((item) => item.visibleInCommerce && item);
            if (visiblePortfolio.length === 0) {
              handleSnack("info", "No matching result found!");
            } else {
              setResult(visiblePortfolio);
            }
          }
        })
        .catch((err) => {
          props.handleSnack("error", "Error occurred while searching for portfolios!");
        });

      // await getPortfolioSearch(searchPayload)
      //   .then((data) => {
      //     if (data.data?.length === 0) {
      //       props.handleSnack("info", "No matching result found!");
      //     } else {
      //       setResult(data.data);
      //     }
      //   })
      //   .catch((err) => {
      //     props.handleSnack("error", "Error occurred while searching for portfolios!");
      //   });
    }
  }
  const handleCategory = async (event) => {
    let { value } = event.target;
    setCategory(value);

    setResult([]);
    let searchPayload = {
      searchCriteriaList: [
        {
          filterKey: "usageCategory",
          value: value,
          operation: "eq",
        },
      ],
      dataOption: "all",
    };

    await getPortfolioSearch(searchPayload)
      .then((data) => {
        if (data.data?.length === 0) {
          // handleSnack("info", "No matching result found!")
        } else {
          setResult(data.data);
        }
      })
      .catch((err) => {
        // handleSnack("error", "Error occurred while searching for portfolios!")
      });
  };
  const [open, setOpen] = useState(false);
  const [showDetailConfig, setShowDetailConfig] = useState(false);

  const handleSelectPortfolio = (portfolio) => {
    setSelectedPortfolio(portfolio);
    setShowDetailConfig(true);
  };

  const handleViewPortfolioDetails = (portfolio) => {
    if (portfolio.coverages.length !== 0) {
      setFamily(portfolio.coverages[0]?.family);
      setPrefix(portfolio.coverages[0]?.serialNumberPrefix);
    }

    setSelectedPortfolio(portfolio);
    // setShowDetailConfig(true);
    setModel(model);

    // setModelSuggestions([]);
    // setOpen(false);
  };

  console.log("open :::: ", open);

  return (
    <Modal
      className="tablerowmodal"
      show={show}
      onHide={() => {
        handleClose();
        setResult([]);
        setModel("");
        setModelSuggestions([]);
      }}
      size="xl"
    >
      <Modal.Body className="bg-blue-white-gradient">
        {!(selectedPortfolio && selectedPortfolio.portfolioId) ? (
          <>
            <div className="card border py-2 bg-blue-white-gradient">
              <h4 className="my-2 px-2">Search By Model</h4>
              <Divider />
              <div className="my-2 py-1 px-3 bg-blue-white-gradient">
                <div>
                  <Autocomplete
                    options={modelSuggestions}
                    // loading={loading}
                    open={open}
                    onInputChange={(event, newInputValue) => {
                      if (newInputValue.length === 0) {
                        if (open) setOpen(false);
                      } else {
                        handleFilterChange(event, newInputValue);

                        console.log("open 1111 ::: ", open);
                        // if (!open) setOpen(true);
                      }
                    }}
                    value={model}
                    onChange={(event, values) => handleChangeModel(values)}
                    noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
                    size="small"
                    renderOption={(props, option) => (
                      <li {...props} style={{ ...props.style, fontSize: 12, borderBottom: "1px solid #00000025" }}>
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <div
                          class="input-group icons border-radius-10 border-primary overflow-hidden my-3 p-2"
                          style={{ backgroundColor: "#efefef" }}
                        >
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                              <img src={searchIcon} />
                            </span>
                          </div>
                          <input
                            type="text"
                            placeholder="Model #"
                            {...params.inputProps}
                            // style={{ width: "100%", borderRadius: 5, borderColor: '#00000030', marginBlock: 10, padding: 6 }}
                            name="modelNo"
                            class="form-control search-form-control"
                            style={{ backgroundColor: "#efefef" }}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
                <Grid container columnSpacing={4}>
                  <Grid item xs={3}>
                    <Typography fontWeight={500} m={1}>
                      Filter By Category
                    </Typography>

                    <FormControl>
                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="portfolio" name="radio-buttons-group">
                        <div className="card border px-2 py-3 m-2" style={{ backgroundColor: "#efefef" }}>
                          <FormControlLabel
                            sx={{ mb: 0 }}
                            value="portfolio"
                            control={<Radio defaultChecked sx={{ mb: 0 }} />}
                            label={<div>Portfolio</div>}
                          />
                          <div className="mx-4 mt-0 font-size-12">
                            Our self-service option helps your customers browse products, aftermarket services and solution online
                          </div>
                        </div>
                        <div className="card border px-2 py-3 m-2" style={{ backgroundColor: "#efefef" }}>
                          <FormControlLabel sx={{ mb: 0 }} value="components" control={<Radio sx={{ mb: 0 }} />} label="Components" />
                          <div className="mx-4 mt-0 font-size-12">
                            Our platform is built to create templates and kits. It has twin builder functionality.
                          </div>
                        </div>
                        <div className="card border px-2 py-3 m-2" style={{ backgroundColor: "#efefef" }}>
                          <FormControlLabel sx={{ mb: 0 }} value="service" control={<Radio sx={{ mb: 0 }} />} label="Service Programs" />
                          <div className="mx-4 mt-0 font-size-12">
                            Our inbuilt price engine helps you price spare parts, labor, miscellaneous and consumables.
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item container xs justifyContent={"center"}>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={8}>
                    <div className="row px-1 search-portfolios">
                      {result && result.length > 0 ? (
                        result.map((indPortfolio) => (
                          <div className="col-md-6">
                            <Card sx={{ padding: 2, height: 200, mb: 3, backgroundColor: "#efefef" }} elevation={2}>
                              <div className="row justify-content-between mx-1">
                                <Typography sx={{ fontSize: 13, fontWeight: 500 }}>{indPortfolio?.name}</Typography>
                                <Typography sx={{ fontSize: 13, color: "lightgreen", justifyContent: "end", fontWeight: 500 }}>
                                  {indPortfolio?.supportLevel}
                                </Typography>
                              </div>
                              <Divider />
                              <Typography sx={{ fontSize: 17, fontWeight: 500, mt: 2 }} className="text-primary">
                                {currencyFormatter.format(indPortfolio?.portfolioPrice?.calculatedPrice || 0)}
                              </Typography>
                              <Typography sx={{ fontSize: 13 }}>{indPortfolio?.description}</Typography>
                              <Typography sx={{ fontSize: 13 }}>Validity: {indPortfolio?.offerValidity} Days</Typography>
                              <div
                                className="my-3 cursor"
                                onClick={() => {
                                  handleViewPortfolioDetails(indPortfolio);
                                  // setModelSuggestions([]);
                                }}
                              >
                                <span className="border p-2 border-radius-10 my-3">Details </span>
                              </div>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <Card sx={{ margin: 2, padding: 2 }} variant={"outlined"}>
            <PortfolioConfig
              family={family}
              model={model}
              prefix={prefix}
              selectedPortfolio={selectedPortfolio}
              setSelectedPortfolio={setSelectedPortfolio}
              handleSnack={handleSnack}
              showBackbutton={true}
            />
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
}
