import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import {
  BASE_URL,
  CLAIM_MASTER_URL,
  DATA_SVC_COMPONENT_ID,
  DATA_SVC_EQUIPMENT,
  WARRANTY_URI,
  DATA_SVC_SUBCOMPONENT,
  DATA_SVC_PARTS_BY_GROUP_NO,
  GET_SPARE_PARTS_BY_IDS_GET,
} from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  // console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

//Fetch Equipments
export const fetchEquipments = (searchString) => {
  console.log("service Commerce > Fetch Equipments Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(DATA_SVC_EQUIPMENT() + `/search?search=${searchString}`, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch Equipments response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetchEquipments > axios err=", err);
          reject("Error in fetchEquipments axios!");
        });
    } catch (error) {
      console.error("fetchEquipments general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//fetch Component

export const fetchComponents = (componetIds) => {
  console.log("service Commerce > Fetch components Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(DATA_SVC_COMPONENT_ID(), componetIds, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch Compoents response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetchComponents > axios err=", err);
          reject("Error in fetchcomponents axios!");
        });
    } catch (error) {
      console.error("fetchComponents general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//fetch subcomponent

export const fetchSubComponents = (componetId) => {
  console.log("service Commerce > Fetch subcomponents Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(DATA_SVC_SUBCOMPONENT(), componetId, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch SubCompoents response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetchComponents > axios err=", err);
          reject("Error in fetchSubComponents axios!");
        });
    } catch (error) {
      console.error("fetchSubComponents general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//fetch Parts
export const fetchParts = (groupNumber) => {
  console.log("service Commerce > Fetch Parts Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(DATA_SVC_PARTS_BY_GROUP_NO(), groupNumber, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch Parts response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetch Parts > axios err=", err);
          reject("Error in fetch Parts axios!");
        });
    } catch (error) {
      console.error("fetchSubComponents general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Fetch Usages
export const fetchPartsByIds = (partIds) => {
  console.log("service Commerce > Fetch Usage Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(GET_SPARE_PARTS_BY_IDS_GET + `${partIds}`, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch Equipment usages response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetchEquipments usages > axios err=", err);
          reject("Error in fetchEquipments usages axios!");
        });
    } catch (error) {
      console.error("fetchEquipments usages general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Fetch Usages
export const fetchUsage = (equipmentNumber) => {
  console.log("service Commerce > Fetch Usage Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(DATA_SVC_EQUIPMENT() + `/${equipmentNumber}/usage`, getAccessToken())
        .then((res) => {
          console.log("EquipmentService -> Fetch Equipment usages response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("fetchEquipments usages > axios err=", err);
          reject("Error in fetchEquipments usages axios!");
        });
    } catch (error) {
      console.error("fetchEquipments usages general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Fetch claims
export const fetchClaims = () => {
  console.log("service Commerce > Fetch claims Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(CLAIM_MASTER_URL + "?pageSize=100", getAccessToken())
        .then((res) => {
          console.log("Claim -> Fetch claims  response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("Fetch Claims > axios err=", err);
          reject("Error in fetch claims axios!");
        });
    } catch (error) {
      console.error("fetch claims general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Fetch Warranty
export const fetchWarranty = (warrantyId) => {
  console.log("service Commerce > Fetch warranty Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(WARRANTY_URI + `warranty/${warrantyId}`, getAccessToken())
        .then((res) => {
          console.log("Claim -> Fetch claims  response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("Fetch Claims > axios err=", err);
          reject("Error in fetch claims axios!");
        });
    } catch (error) {
      console.error("fetch claims general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Add claims
export const addClaim = (data) => {
  console.log("service Commerce > Add claims Called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(CLAIM_MASTER_URL, data, getAccessToken())
        .then((res) => {
          console.log("Claim -> post claims  response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("post Claims > axios err=", err);
          reject("Error in post claims axios!");
        });
    } catch (error) {
      console.error("post claims general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};
