import { Modal } from "react-bootstrap";
import PortfolioConfig from "../PortfolioConfig";
// import PortfolioConfig from "./PortfolioConfig";

export default function PortfolioCartItemEditModal(props) {
    const { show, handleClose, item, handleSnack, setItem } = props;
    return (
        <Modal className="tablerowmodal" show={show} onHide={() => handleClose()} size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body className="">
                <PortfolioConfig
                    family={11}
                    model={11}
                    prefix={11}
                    selectedPortfolio={item}
                    setSelectedPortfolio={setItem}
                    handleSnack={handleSnack}
                    showBackbutton={false}
                />
            </Modal.Body>
        </Modal>
    );
}
