import React from "react";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Tooltip } from "@mui/material";

const OrderInfoCard = ({ orderRecord, setOpenOrderInfoModal, showIcon = false, className = "px-4 py-2", style = null }) => {
  return (
    <div className="card border" style={{ overflow: "hidden", ...style }}>
      <div className={`d-flex justify-content-between align-items-center bg-green ${className} text-white`}>
        <h6 className="bg-green text-white mb-0">Order Info </h6>
        {showIcon && (
          <Tooltip title="Add Order info">
            <ControlPointIcon className="cursor" onClick={() => setOpenOrderInfoModal(true)} />
          </Tooltip>
        )}
      </div>
      <div className={className}>
        <div className="row py-2">
          <div className="col-md-3 col-sm-3">
            <p className="mb-0">Account</p>
          </div>
          <div className="col-md-9 col-sm-9 text-right px-3">
            <h6 className="mb-0 ">
              <b>{orderRecord?.accountName || "-"}</b>
            </h6>
          </div>
        </div>
        {/* <div className="d-block py-2">
          <p className="mb-0">Account</p>
          <h6 className="mb-0 text-wrap">
            <b>{orderRecord?.accountName || "-"}</b>
          </h6>
        </div> */}
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Store</p>
          <h6 className="mb-0">
            <b>{orderRecord?.dealerStore?.label || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Order Type</p>
          <h6 className="mb-0">
            <b>{orderRecord?.orderType?.label || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Order By</p>
          <h6 className="mb-0">
            <b>{orderRecord?.orderedBy || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Email</p>
          <h6 className="mb-0">
            <b>{orderRecord?.emailAddress || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Contact</p>
          <h6 className="mb-0">
            <b>{orderRecord?.phoneNumber || "-"}</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default OrderInfoCard;
