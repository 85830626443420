import React from "react";

import PublishIcon from "@mui/icons-material/Publish";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";

import { GRID_STYLE } from "../common/constants";
import { Tooltip } from "@mui/material";

const SavedPartListModal = ({ show, hideModal, handleSnack }) => {
  const columns = [
    {
      field: "orderNumber",
      headerName: "Order Number",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      //   width: 150,
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="view">
                  <VisibilityIcon />
                </Tooltip>
              </div>
            }
            label="View"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Import">
                  <PublishIcon sx={{ transform: "rotate(180deg)" }} />
                </Tooltip>
              </div>
            }
            label="Import"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Modal show={show} onHide={hideModal} size="lg">
      <Modal.Body>
        <h4 className="mb-3">Saved Parts List</h4>
        <DataGrid
          columns={columns}
          rows={[]}
          autoHeight
          sx={GRID_STYLE}
          //   pageSizeOptions={[5]}
          //   rowsPerPageOptions={[5, 10, 20, 50]}
          //   getRowId={(row) => row.warrantyId}
        />
        <div className="row mt-3" style={{ justifyContent: "right" }}>
          <button className="btn bg-primary text-white mx-3" onClick={hideModal}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SavedPartListModal;
