import React, { useState } from "react";

import { Card, Typography } from "@mui/material";

import { currencyFormatter } from "../../common/utils/currencyFormatter";
import PortfolioNumberSpinner from "./PortfolioNumberSpinner";
import PortfolioCartItemEditModal from "../../portfolioCart/PortfolioCartItemEditModal";

const PortfolioCartItems = ({ item, cartItems, setCartItems, removeItem, showViewBtn, handleSnack }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div key={item.portfolioId}>
      <PortfolioCartItemEditModal show={show} handleClose={handleClose} item={item} setItem={setCartItems} handleSnack={handleSnack} />

      <Card elevation={2} sx={{ p: 2, backgroundColor: "#f5f5f5", borderRadius: 3, borderColor: " #872ff7", height: "160px" }} variant="outlined">
        <div className="row" style={{ alignItems: "baseline" }}>
          <div className="col-md-8 col-sm-8">
            <div className="d-flex justify-content-between px-2">
              <div>
                <Typography fontSize={16} fontWeight={500} sx={{ wordBreak: "break-word" }}>
                  {item?.name}
                </Typography>
                <Typography fontSize={13} className="mb-0 mt-2" sx={{ wordBreak: "break-word" }}>
                  {item?.description}
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: "16px" }}>
              {currencyFormatter.format(item?.portfolioPrice?.calculatedPrice * item?.quantity || 0)}
            </Typography>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="d-flex align-items-center px-2 mt-4">
              <PortfolioNumberSpinner
                portfolioCartId={item?.portfolioCart?.portfolioCartId}
                qnt={item?.quantity}
                itemId={item?.portfolioId}
                cartItems={cartItems}
                setCartItems={setCartItems}
                steps={1}
                // setConfirmationOpen={setConfirmationOpen}
              />
              <button onClick={() => removeItem(item)} className="btn bg-primary text-white remove-button mx-2" style={{ fontSize: 12, height: 26 }}>
                Remove
              </button>
              {showViewBtn && (
                <button onClick={() => setShow(true)} className="btn bg-primary text-white remove-button" style={{ fontSize: 12, height: 26 }}>
                  Configure
                </button>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PortfolioCartItems;
