import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";

let fileTypes = ["JPG", "PNG", "GIF"];

const UploadFilesModal = ({ show, hideModal, handleUploadFile, uploadFile = false, fileTypesArr = [] }) => {
  const [uploadFileImage, setUploadFileImage] = useState(null);

  useEffect(() => {
    if (fileTypesArr.length > 0) {
      fileTypes = [...fileTypesArr];
    }
  }, [fileTypesArr]);

  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (file) => {
    if (file) {
      setUploadFileImage(file);
    }
  };

  // upload the file
  const handleUploadFilesModal = () => {
    if (uploadFile) {
      handleUploadFile(uploadFileImage);
      return;
    }
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Import Files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-3">
            <div className="add-new-recod">
              <div>
                <FontAwesomeIcon className="cloudupload" icon={faCloudUploadAlt} />
                <h6 className="font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <FileUploader
                  handleChange={handleImageFileUpload}
                  name="file"
                  types={fileTypes}
                  onClick={(event) => {
                    event.currentTarget.value = null;
                  }}
                />
              </div>
            </div>
            <p className="mt-3">Single upload file should not be more than 10MB. Only the .lgs, .lgsx file types are allowed</p>
          </div>
        </Modal.Body>
        <div className="row m-0 p-3">
          <div className="col-md-6 col-sm-6">
            <button className="btn border w-100 bg-white" onClick={hideModal}>
              Cancel
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button className="btn btn-primary w-100 cursor" onClick={handleUploadFilesModal}>
              <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} /> Upload
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadFilesModal;
