import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT } from "../common/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";

const deliveryPriorityOptions = [
  { label: "Normal", value: "NORMAL" },
  { label: "Urgent", value: "URGENT" },
  { label: "Emergency", value: "EMERGENCY" },
  // { label: "Very Urgent", value: "VERY_URGENT" },
];

const shippingPointOptions = [
  { label: "Shipping Point 1", value: "SHIPPING_POINT_1" },
  { label: "Shipping Point 2", value: "SHIPPING_POINT_2" },
  { label: "Shipping Point 3", value: "SHIPPING_POINT_3" },
  { label: "Shipping Point 4", value: "SHIPPING_POINT_4" },
  { label: "Shipping Point 5", value: "SHIPPING_POINT_5" },
];

const shippingCondition = [
  { label: "Ship to Me", value: "SHIP_TO_ME" },
  { label: "Pickup", value: "PICKUP" },
];

const shippingLeadCountUnit = [
  { value: "per Hr", label: "per Hr" },
  { value: "per Km", label: "per Km" },
  { value: "per Miles", label: "per Miles" },
  { value: "per year", label: "per year" },
  { value: "per month", label: "per month" },
  { value: "per day", label: "per day" },
  { value: "per quarter", label: "per quarter" },
];

const CustomerOrderAddress = ({
  show,
  hideModal,
  handleSnack,
  addressDetails,
  setAddressDetails,
  otherDetails,
  setOtherDetails,
  setLabourCharge,
}) => {
  const [expendAlternateAddress, setExpendAlternateAddress] = useState(false);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  };

  // select value change
  const handleSelectChange = (e, keyName) => {
    setAddressDetails({ ...addressDetails, [keyName]: e });
  };

  // select alternate address
  const handleSelectAlternateAddress = (e) => {
    setAddressDetails({ ...addressDetails, recipientAddress: e.target.value });
  };

  // other detials text change
  const handleChangeOtherDetails = (e) => {
    const { name, checked } = e.target;

    let _serialNumber = otherDetails?.serialNumber;
    let _modelNumber = otherDetails?.modelNumber;

    if (name === "machineBreakDown") {
      _serialNumber = "";
      _modelNumber = "";
    }
    if (name === "needInstallation") {
      if (checked) {
        setLabourCharge(500);
      } else {
        setLabourCharge(0);
      }
    }
    setOtherDetails({ ...otherDetails, [name]: checked, serialNumber: _serialNumber, modelNumber: _modelNumber });
  };

  // other details input text change
  const handleOtherDetailsInputChange = (e) => {
    const { name, value } = e.target;
    setOtherDetails({ ...otherDetails, [name]: value });
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Shipping Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row input-fields">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
              <Select
                onChange={(e) => handleSelectChange(e, "deliveryType")}
                value={addressDetails?.deliveryType}
                options={[
                  { label: "Standard", value: "STANDARD" },
                  { label: "Express", value: "EXPRESS" },
                ]}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">DELIVERY PRIORITY</label>
              <Select
                onChange={(e) => handleSelectChange(e, "deliveryPriority")}
                value={addressDetails?.deliveryPriority}
                options={deliveryPriorityOptions}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SHIPPING CONDITION</label>
              <Select
                onChange={(e) => handleSelectChange(e, "shippingCondition")}
                value={addressDetails?.shippingCondition}
                options={shippingCondition}
                placeholder="Select..."
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group date-box">
              <label className="text-light-dark font-size-12 font-weight-500">LEAD TIME</label>
              <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                <input
                  type="text"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="20%"
                  id="leadTime"
                  name="leadTime"
                  onChange={handleInputTextChange}
                  value={addressDetails?.leadTime}
                />
                <span className="hours-div text-primary">{"Days"}</span>
              </div>
              {/* <div className=" d-flex align-items-center form-control-date">
                <input
                  type="text"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="20%"
                  id="leadTime"
                  name="leadTime"
                  onChange={handleInputTextChange}
                  value={addressDetails?.leadTime}
                />
                <Select
                  className="select-input text-primary"
                  id="priceEscalationSelect"
                  options={shippingLeadCountUnit}
                  onChange={(e) => handleSelectChange(e, "leadTimeUnit")}
                  value={addressDetails?.leadTimeUnit}
                />
              </div> */}
            </div>
          </div>
          {addressDetails?.shippingCondition?.value === "PICKUP" && (
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PICKUP INSTRUCTIONS</label>
                <div className="form-group w-100">
                  <textarea
                    id="serviceRecipentAddress"
                    name="pickupInstructions"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleInputTextChange}
                    value={addressDetails?.pickupInstructions}
                    placeholder="Pickup Instructions"
                    cols="30"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>
        {addressDetails?.shippingCondition?.value === "SHIP_TO_ME" && (
          <div className="row input-fields">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SHIPPING POINT</label>
                <Select
                  onChange={(e) => handleSelectChange(e, "shippingPoint")}
                  value={addressDetails?.shippingPoint}
                  options={shippingPointOptions}
                  placeholder="Select..."
                  styles={FONT_STYLE_SELECT}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SERVICE RECIPIENT ADDRESS</label>
                <div className="form-group w-100">
                  <input
                    type="text"
                    id="serviceRecipentAddress"
                    name="recipientAddress"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleInputTextChange}
                    value={addressDetails?.recipientAddress}
                    placeholder="Address"
                    // cols="30"
                    // rows="2"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="hr"></div> */}
        <Accordion
          // sx={{ backgroundColor: "#f3eafe" }}
          sx={{ marginBottom: 2 }}
          // defaultExpanded
          expanded={expendAlternateAddress}
          onChange={() => setExpendAlternateAddress(!expendAlternateAddress)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ marginY: 0 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Select Alternate Address
            </Typography>
          </AccordionSummary>
          <Divider sx={{ marginY: 0 }} />
          <AccordionDetails>
            <FormGroup>
              <RadioGroup>
                <FormControlLabel
                  value="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
                  control={
                    <Radio checked={addressDetails?.recipientAddress === "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"} />
                  }
                  label="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="1 Alan St, Rydalmere NSW 2116, Australia"
                  control={<Radio checked={addressDetails?.recipientAddress === "1 Alan St, Rydalmere NSW 2116, Australia"} />}
                  label="1 Alan St, Rydalmere NSW 2116, Australia"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="8501 Willow Avenue, Los Angeles, CA 90037"
                  control={<Radio checked={addressDetails?.recipientAddress === "8501 Willow Avenue, Los Angeles, CA 90037"} />}
                  label="8501 Willow Avenue, Los Angeles, CA 90037"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
                <FormControlLabel
                  value="9653 Richardson Circle, Compton, CA 90221"
                  control={<Radio checked={addressDetails?.recipientAddress === "9653 Richardson Circle, Compton, CA 90221"} />}
                  label="9653 Richardson Circle, Compton, CA 90221"
                  className="mb-0"
                  onChange={handleSelectAlternateAddress}
                />
              </RadioGroup>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <div className="hr"></div>
        <h5 className="mb-1">Other Details</h5>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            checked={otherDetails?.completeShipment}
            onChange={handleChangeOtherDetails}
            name="completeShipment"
            label={"Partial Delivery"}
          />
          <FormControlLabel
            control={<Switch />}
            checked={otherDetails?.needInstallation}
            onChange={handleChangeOtherDetails}
            name="needInstallation"
            label={"Need help with installation"}
          />
          <FormControlLabel
            control={<Switch />}
            checked={otherDetails?.machineBreakDown}
            name="machineBreakDown"
            onChange={handleChangeOtherDetails}
            label={"Machine breakdown"}
          />
          {otherDetails?.machineBreakDown && (
            <>
              <div className="row input-fields mb-0">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={otherDetails?.serialNumber}
                      onChange={handleOtherDetailsInputChange}
                      name="serialNumber"
                      placeholder="Serial Number"
                      disabled={!otherDetails?.machineBreakDown}
                      // onChange={handleCustomerFieldsChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={otherDetails?.modelNumber}
                      onChange={handleOtherDetailsInputChange}
                      name="modelNumber"
                      placeholder="Model"
                      disabled={!otherDetails?.machineBreakDown}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="hr mt-0"></div>
        </FormGroup>
        <h5 className="mb-0">Includes</h5>
        <div className="align-items-start mt-3">
          <h6>
            <a href="#" className="btn-sm bg-success border text-white mr-2">
              FREE
            </a>
            Free Shipping
          </h6>
          {otherDetails?.needInstallation && (
            <h6 className="mt-2">
              <a href="#" className="btn-sm bg-gray border text-white mr-2">
                CHARGABLE
              </a>
              Installation charge included
            </h6>
          )}
        </div>
        {/* <h5>Select Alternate Address</h5> */}
        {/* <div className="hr my-0"></div>
        <FormGroup>
          <RadioGroup>
            <FormControlLabel
              value="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
              control={<Radio checked={addressDetails?.recipientAddress === "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"} />}
              label="L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia"
              className="mb-0"
              onChange={handleSelectAlternateAddress}
            />
            <FormControlLabel
              value="1 Alan St, Rydalmere NSW 2116, Australia"
              control={<Radio checked={addressDetails?.recipientAddress === "1 Alan St, Rydalmere NSW 2116, Australia"} />}
              label="1 Alan St, Rydalmere NSW 2116, Australia"
              className="mb-0"
              onChange={handleSelectAlternateAddress}
            />
            <FormControlLabel
              value="8501 Willow Avenue, Los Angeles, CA 90037"
              control={<Radio checked={addressDetails?.recipientAddress === "8501 Willow Avenue, Los Angeles, CA 90037"} />}
              label="8501 Willow Avenue, Los Angeles, CA 90037"
              className="mb-0"
              onChange={handleSelectAlternateAddress}
            />
            <FormControlLabel
              value="9653 Richardson Circle, Compton, CA 90221"
              control={<Radio checked={addressDetails?.recipientAddress === "9653 Richardson Circle, Compton, CA 90221"} />}
              label="9653 Richardson Circle, Compton, CA 90221"
              className="mb-0"
              onChange={handleSelectAlternateAddress}
            />
          </RadioGroup>
        </FormGroup> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary w-100" onClick={hideModal}>
          Save
        </button>
        <button className="btn btn-border-primary w-100" onClick={hideModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerOrderAddress;
