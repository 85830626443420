import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import { QUOTE_COMMON_CONFIG_URL } from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
    let storedCookie = Cookies.get("logincustomerinfo");
    console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
    let getCookiesJsonData;
    if (storedCookie != undefined) {
        getCookiesJsonData = JSON.parse(storedCookie);
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${getCookiesJsonData?.access_token}`,
        },
    };
    return config;
}

export const getQuoteCommonConfig = (endPath) => {
    console.log("QuoteService > getQuoteCommonConfig called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(QUOTE_COMMON_CONFIG_URL + endPath, getAccessToken())
                .then((res) => {
                    console.log("getQuoteCommonConfig > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("getQuoteCommonConfig > axios err=", err);
                    reject("Error in getQuoteCommonConfig axios!");
                });
        } catch (error) {
            console.error(
                "in QuoteService.js > getQuoteCommonConfig, Err===",
                error
            );
            reject(SYSTEM_ERROR);
        }
    });
};
