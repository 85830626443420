import React from "react";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { PARTS_ADD_TO_CART_POST } from "../services/CONSTANTS";
import { callPostApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { Divider, Stack, Tooltip } from "@mui/material";
import { currencyFormatter } from "../pages/common/utils/currencyFormatter";
import ImageLoader from "./imageLoader/ImageLoader";

const AccessoryProductsTile = ({ product, handleSnack, cartItems, setCartItems, portfolioCarts }) => {
  // add part into cart items
  const handleAddToCart = async (row) => {
    if (portfolioCarts.length !== 0) {
      handleSnack("info", "There are some services left in your cart");
      return;
    }

    if (cartItems) {
      const partCartItem = cartItems.parts;
      let partExistsInCart = cartItems["parts"].some((obj) => obj.partNumber === row.partNumber);
      if (partExistsInCart) {
        handleSnack("info", `Part ${row.partNumber} is already exits in your cart`);
        return;
      }
      handleAddPartIntoCart(row);
    } else {
      handleAddPartIntoCart(row);
    }
  };

  const handleAddPartIntoCart = async (row) => {
    let rUrl = `${PARTS_ADD_TO_CART_POST}`;
    let rObj = {
      partsQuantity: {
        [String(row.id)]: 1,
      },
    };
    if (cartItems) {
      rObj["partsCartId"] = cartItems?.cartId;
    }
    await callPostApi(null, `${rUrl}`, rObj, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setCartItems(responseData);
        handleSnack("success", "Part added into Cart successfully.");
      }
    });
  };

  return (
    <>
      <div className="card border p-4" style={{ minHeight: 250 }}>
        <div className="d-flex justify-content-center align-items-center">
          {/* <ImageLoader
            selectedImage={product.sparepartImages && product.sparepartImages.length > 0 ? product.sparepartImages[0] : "./assets/images/partDefaultImage.png"}
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          /> */}
          <img
            src={
              product.sparepartImages && product.sparepartImages.length > 0
                ? product.sparepartImages[0] || "./assets/images/partDefaultImage.png"
                : "./assets/images/partDefaultImage.png"
            }
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
            loading="eager|lazy"
          />
        </div>
        <div className="mt-3">
          <h4 className="text-light">{product.partNumber}</h4>
          <h5 className="my-1">{product.description}</h5>
          <p className="font-size-12 my-2">{product.partGroupDescription}</p>
        </div>
        <Divider sx={{ marginY: 1 }} />
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
          <h4 className="text-red">
            <b>{currencyFormatter.format(product.listPrice)}</b>
          </h4>
          <div className="text-right">
            <Tooltip title={cartItems && cartItems["parts"].some((obj) => obj.partNumber === product.partNumber) ? "Added to Cart" : "Add to Cart"}>
              <button className="service-button">
                <AddShoppingCartIcon className="font-size-30" size="large" onClick={() => handleAddToCart(product)}></AddShoppingCartIcon>
              </button>
            </Tooltip>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default AccessoryProductsTile;
