import React, { useContext, useEffect, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

import { Box, Tooltip, Typography } from "@mui/material";
import DataGridContainer from "./common/DataGridContainer";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { SelfImprovement } from "@mui/icons-material";

import Moment from "react-moment";
import SelectFilter from "react-select";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import EquipmentQuoteAddEdit from "./EquipmentQuoteAddEdit";

import { fetchClaims, fetchEquipments, fetchUsage, fetchWarranty } from "../services/EquipmentService";
import ClaimDetails from "./ClaimDetails";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";
import WarrantyOverView from "./WarrantyOverView";
import { GRID_STYLE } from "./Profile";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import EquipmentMachinesTableList from "./EquipmentMachinesTableList";
import { AuthContext } from "../context/AuthContext";
import { callGetApi } from "../services/ApiCaller";
import { BASE_URL } from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import EquipmentDetailsModal from "./EquipmentDetailsModal";
import RepairRequest from "./machinesPossession/RepairRequest";
import FindServices from "./machinesPossession/FindServices";
import { equipmentSearch } from "../services/searchServices";

import $ from "jquery";
import SearchIcon from "@mui/icons-material/Search";
import EquipmentEnrollModal from "./warrantyManagement/equipment/EquipmentEnrollModal";
import RecommendedServices from "./machinesPossession/RecommendedServices";
import CustomerWarrantyModal from "./CustomerWarrantyModal";

const equipmentComponentRow = [
  {
    partNumber: "N90058041",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    id: 1000,
    subcomponents: [
      {
        subComponentNumber: 293745,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 846573,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "10R4469",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    id: 3138,
    subcomponents: [
      {
        subComponentNumber: 938401,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 384723,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "039631T1",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    id: 3147,
    subcomponents: [
      {
        subComponentNumber: 675865,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 989576,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "039720N2",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    id: 3148,
    subcomponents: [
      {
        subComponentNumber: 238456,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 894763,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "5788987",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    id: 3149,
    subcomponents: [
      {
        subComponentNumber: 893847,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 948676,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
];
const data = [
  {
    id: 0,
    equipmentNumber: "1HL00258",
    maker: "CATERPILLAR",
    makerSerialNumber: "ZMX00507",
    model: "992K",
    purchaseDate: "29-04-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-18",
    warrantyId: "18",
    warrantyType: "STD",
    yearOfManufacture: "2021",
    serviceLetterId: "PS75943",
    componentCodeIds: [],
    components: equipmentComponentRow,
  },
  {
    id: 1,
    equipmentNumber: "1HL00259",
    maker: "CATERPILLAR",
    model: "336D2",
    makerSerialNumber: "ZCT01128",
    purchaseDate: "15-06-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-13",
    warrantyId: "13",
    warrantyType: "STD",
    yearOfManufacture: "2021",
    serviceLetterId: "PS75943",
    componentCodeIds: [317, 3148],
    components: equipmentComponentRow,
  },
  {
    id: 2,
    equipmentNumber: "1HL00271",
    maker: "CATERPILLAR",
    model: "C15",
    makerSerialNumber: "XP800111",
    purchaseDate: "23-08-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-14",
    warrantyId: "14",
    warrantyType: "STD",
    yearOfManufacture: "2021",
    serviceLetterId: "PS75943",
    componentCodeIds: [317, 3148],
    components: equipmentComponentRow,
  },
];

const usageData = [
  {
    id: 0,
    currentUsage: "982991123",
    averageUsage: "8 Hour/day",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "992",
    smuType: "Hour",
  },
  {
    id: 1,
    currentUsage: "982991123",
    averageUsage: "8 Hour/day",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "992",
    smuType: "Hour",
  },
];

// Equipment Master Search Select Options
const equipmentSearchOptions = [
  { label: "Serial No", value: "makerSerialNumber" },
  { label: "Model", value: "model" },
  { label: "Family", value: "family" },
  { label: "Equipment Id", value: "equipmentNumber" },
  { label: "Description", value: "description" },
];

const EquipmentMaster = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showClaimBackModal, setShowClaimBackModal] = useState(false);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [openWarrantySummaryModal, setOpenWarrantySummaryModal] = useState(false);
  const [selectedRowDesc, setSelectedRowDesc] = useState(null);

  const [edit, setEdit] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equpLoading, setEqupLoading] = useState(false);
  const [equipmentUsageData, setEquipmentUsageData] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [warranty, setWarranty] = useState(null);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [showUsageDetailsModal, setShowUsageDetailsModal] = useState(false);

  const [openRepairRequestModal, setOpenRepairRequestModal] = useState(false);
  const [openFindServicesModal, setOpenFindServicesModal] = useState(false);

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);
  const [isSummaryModal, setIsSummaryModal] = useState(false);
  const [openEqupEnrollModal, setOpenEqupEnrollModal] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [openEquEnrollModal, setOpenEquEnrollModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  const [equRecordId, setEquRecordId] = useState(null);
  const [equipmentCreateData, setEquipmentCreateData] = useState(null);

  const [showRecommendedServiceModal, setShowRecommendedServiceModal] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const [selectionModel, setSelectionModel] = React.useState([]);
  const handleEquipmentModal = (modalType, edit) => {
    setShowModal(true);
    setEdit(edit);
    setModalType(modalType);
  };
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showClaimDetailsModal, setShowClaimDetailsModal] = useState(false);
  const [equipmentPage, setEquipmentPage] = useState(1);
  const [equipmentPerPage, setEquipmentPerPage] = useState(5);
  const [totalEquipmentRows, setTotalEquipmentRows] = useState(0);
  const [selectedEquipmentDetails, setSelectedEquipmentDetails] = useState();
  const [equipmentId, setEquipmentId] = useState(null);

  // const handleShowClaimAddEditModal = () => {
  //   setShowOverviewModal(!showOverviewModal);
  //   setShowClaimAddEditModal(!showClaimAddEditModal);
  //   // setShowClaimBackModal(!showClaimAddEditModal);
  // };

  const handleShowClaimDetails = () => {
    setShowOverviewModal(!showOverviewModal);
    setShowClaimDetailsModal(!showClaimDetailsModal);
  };
  useEffect(() => {
    // fetchWarrantyDetails(12);
    // // fetchClaimDetails();
  }, []);

  useEffect(() => {
    if (!showSearchResults) {
      fetchEquipmentDetails();
    }
    // if(querySearchSelector[0].inputSearch.length)
  }, [equipmentPage, equipmentPerPage, showSearchResults]);

  useEffect(() => {
    if (selectionModel?.length > 0) {
      fetchUsage(selectionModel).then((result) => {
        console.log(result);
      });
    }
  }, [selectionModel]);

  const fetchEquipmentDetails = async () => {
    try {
      setEqupLoading(true);
      // // const equipments = await fetchEquipments(`currentClient:${auth ? auth.customerId : 101211}&pageNumber=${equipmentPage-1}&pageSize=${equipmentPerPage}&sortColumn=id&orderBY=ASC`);
      // const equipments = await fetchEquipments(
      //   `currentClient:101211&pageNumber=${equipmentPage - 1}&pageSize=${equipmentPerPage}&sortColumn=id&orderBY=ASC`
      // );
      const equipments = await fetchEquipments(
        `currentClient:${auth ? auth.customerId : 101211}&pageNumber=${equipmentPage - 1}&pageSize=${equipmentPerPage}&sortColumn=id&orderBY=ASC`
      );
      setTotalEquipmentRows(equipments[0].totalRecords);
      setEquipmentData(equipments);
      setEqupLoading(false);
    } catch (e) {
      console.log(e);
      setEqupLoading(false);
    }
  };

  const fetchWarrantyDetails = async (value) => {
    await fetchWarranty(value ? value : 12)
      .then((warranty) => {
        setWarranty(warranty);
      })
      .catch((e) => console.log(e));
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search category
  const handleSearchCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // equipment input search
  const handleInputSearch = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString = tempArray[id].selectCategory.value + "~" + e.target.value;
    if (tempArray[id].selectCategory.value && e.target.value) {
      equipmentSearch(`currentClient:${auth ? auth.customerId : 101211} %26%26 ${searchString}`)
        // equipmentSearch(`currentClient:${101211} %26%26 ${searchString}`)
        // equipmentSearch(`${searchString}`)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[id] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${id}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // equipment input search list click
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete equipment search query list
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  const handleRefreshTable = () => {
    // getRecentWarrantyList();
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
    setShowSearchResults(false);
  };

  // click on search button
  const handleQuerySearchClick = async () => {
    setShowSearchResults(true);
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        setEqupLoading(true);
        // const res = await equipmentSearch(`${searchStr}&pageNumber=0&pageSize=10`);
        const res = await equipmentSearch(`currentClient:${auth ? auth.customerId : 101211} %26%26 ${searchStr}&pageNumber=0&pageSize=10`);
        // const res = await equipmentSearch(`currentClient:101211 %26%26 ${searchStr}&pageNumber=0&pageSize=10`);
        // setMasterData(res);
        if (res.length !== 0) {
          setTotalEquipmentRows(res.length);
          setEquipmentData(res);
          setEqupLoading(false);
        } else {
          handleSnack("info", "No information is found for your search, change the search criteria");
          setTotalEquipmentRows(0);
          setEquipmentData([]);
          setEqupLoading(false);
        }
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  const handleEquipmentDetailsModal = (row) => {
    setSelectedEquipmentDetails(row);
    setEquipmentId(row.id);
    setShowEquipmentModal(true);
  };

  const handleEquipmentData = () => {
    const customerId = auth?.customerId;
    const rUrl = `${BASE_URL}/data-svc/api/v1/equipment/search?search=currentClient:${customerId}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        console.log(responseData);
      }
    });
  };

  // open Repair Request Modal
  const handleOpenRepairRequestModal = (row) => {
    setOpenRepairRequestModal(true);
  };

  // close the Repair Request Modal
  const handleCloseRepairRequestModal = () => {
    setOpenRepairRequestModal(false);
  };

  // open Repair Request Modal
  const handleOpenFindServicesModal = (row) => {
    setOpenFindServicesModal(true);
  };

  // close the Repair Request Modal
  const handleCloseFindServicesModal = () => {
    setOpenFindServicesModal(false);
  };

  // open enroll equipment Modal
  const handleOpenEnrollEquipmentModal = () => {
    setOpenEquEnrollModal(true);
    setEquRecordId(null);
  };

  // open file Upload Modal
  const handleShowEnrollFileUploadModal = () => {
    setOpenFileUploadModal(!openFileUploadModal);
  };

  // show the warranty details select row
  const handleShowWarrantySummary = (row) => {
    if (row.warrantyId) {
      // fetchWarrantyDetails(row.warrantyId);
      setRecordId(row.warrantyId);
      // fetchWarrantyDetails(row.contract === "STD-14" ? setRecordId(14) : row.contract === "STD-13" ? setRecordId(13) : setRecordId(12));
      // setShowWarrantyModal(true);
      setOpenWarrantySummaryModal(true);
      setSelectedRowDesc(row.description);
    } else {
      handleSnack("info", `Equipment ${row.equipmentNumber} not have any warranty.`);
      return;
    }
  };

  // show the warranty details select row
  const handleShowWarrantyDetails = (row) => {
    setOpenWarrantySummaryModal(false);
    setShowWarrantyModal(true);
  };

  const equipmentsColumns = [
    {
      field: "maker",
      headerName: "Manufacturer",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div style={{ fontSize: 12 }}>{params.row.maker}</div>,
    },
    { field: "model", headerName: "Model", flex: 1, minWidth: 120 },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "installationDate", //confirm
      headerName: "Date Of Purchase",
      flex: 1,
      minWidth: 120,
    },
    { field: "address", headerName: "Address", flex: 1, minWidth: 220 }, //not present
    {
      field: "contract",
      headerName: "Contracts/EW",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <>
          <Typography fontSize={12} sx={{ mr: 1 }}>
            {params.value}
          </Typography>
          <GppGoodOutlinedIcon className="text-primary" onClick={() => handleShowWarrantySummary(params.row)} style={{ cursor: "pointer" }} />
        </>
      ),
    },
    {
      field: "yearOfManufacture",
      headerName: "Manufacturing Year",
      flex: 1,
      minWidth: 120,
    },
    { field: "service", headerName: "Service Program", flex: 1, minWidth: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon onClick={() => handleEquipmentModal("equipmentTableModal", true)} />
              </div>
            }
            label="View"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor" title="Claim Back">
                <AddCardOutlinedIcon onClick={() => setShowClaimBackModal(true)} />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const updatedEquipmentColumns = [
    {
      id: "makerSerialNumber",
      name: "Serial Number",
      selector: (row) => row.makerSerialNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.makerSerialNumber || "ZCT01128",
    },
    {
      id: "model",
      name: "Model",
      selector: (row) => row.model,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.model || "336D2",
    },
    {
      id: "maker",
      // name: "Family",
      name: "Manufacturer",
      selector: (row) => <div style={{ fontSize: 12 }}>{row.maker}</div>,
      wrap: true,
      minWidth: 80,
      cell: (row) => row.maker || "CATERPILLAR",
    },
    {
      name: (
        <>
          <div>Product</div>
        </>
      ),
      wrap: true,
      sortable: true,
      minWidth: 120,
      cell: (row) => (
        <div className="d-flex justify-content-center align-items-center" style={{ minWidth: "60px" }}>
          <span
            // onClick={() => setShowUsageDetailsModal(true)}
            onClick={() => handleEquipmentDetailsModal(row)}
            className="cursor font-weight-600 text-light"
          >
            View Details
          </span>
        </div>
      ),
    },
    {
      id: "warranty",
      name: "Contracts/Warranty",
      selector: (row) => (
        <div className="d-flex ">
          <Typography fontSize={12} sx={{ mr: 1 }}>
            {`${row.warrantyType ? row.warrantyType : "STD"}-${row.warrantyId || 18}`}
          </Typography>
          <GppGoodOutlinedIcon className="text-primary" onClick={() => handleShowWarrantySummary(row)} style={{ cursor: "pointer" }} />
        </div>
      ),
      wrap: false,
      minWidth: 120,
    },
    {
      id: "purchaseDate",
      name: "Installed Date",
      selector: (row) => row.installationDate,
      wrap: true,
      minWidth: 300,
      cell: (row) => <Moment format="DD/MM/YYYY">{row.installationDate}</Moment>,
    },
    {
      id: "installationDate",
      name: "Warranty Expiration Date",
      selector: (row) => row.installationDate,
      wrap: true,
      minWidth: 300,
      cell: (row) => (
        <Moment add={{ years: 5 }} format="DD/MM/YYYY">
          {row.installationDate}
        </Moment>
      ),
    },
    {
      name: (
        <>
          <div>Actions</div>
        </>
      ),
      wrap: true,
      sortable: true,
      minWidth: 120,
      cell: (row) => (
        <div className="d-flex justify-content-center align-items-center" style={{ minWidth: "60px" }}>
          <div className="cursor" onClick={() => setShowUsageDetailsModal(true)}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.4062 1.87305C12.3555 1.87305 13.125 2.64256 13.125 3.5918V11.4043C13.125 12.3535 12.3555 13.123 11.4062 13.123H3.59375C2.64451 13.123 1.875 12.3535 1.875 11.4043V3.5918C1.875 2.64256 2.64451 1.87305 3.59375 1.87305H11.4062ZM11.4062 2.81055H3.59375C3.16228 2.81055 2.8125 3.16032 2.8125 3.5918V11.4043C2.8125 11.8358 3.16228 12.1855 3.59375 12.1855H11.4062C11.8377 12.1855 12.1875 11.8358 12.1875 11.4043V3.5918C12.1875 3.16032 11.8377 2.81055 11.4062 2.81055ZM4.84375 5.62511C5.08106 5.62511 5.27718 5.8007 5.30822 6.02852L5.3125 6.09185V10.1584C5.3125 10.4161 5.10263 10.6251 4.84375 10.6251C4.60644 10.6251 4.41032 10.4495 4.37928 10.2217L4.375 10.1584V6.09185C4.375 5.83408 4.58487 5.62511 4.84375 5.62511ZM10.1562 4.37511C10.3936 4.37511 10.5897 4.54662 10.6207 4.76913L10.625 4.83099V10.1692C10.625 10.421 10.4151 10.6251 10.1562 10.6251C9.91894 10.6251 9.72282 10.4536 9.69178 10.2311L9.6875 10.1692V4.83099C9.6875 4.57922 9.89737 4.37511 10.1562 4.37511ZM7.48444 7.49805C7.71615 7.49582 7.90934 7.66472 7.94186 7.88599L7.94665 7.94755L7.96873 10.1646C7.97122 10.4153 7.76833 10.6206 7.51556 10.6231C7.28385 10.6253 7.09066 10.4564 7.05814 10.2351L7.05335 10.1736L7.03127 7.95651C7.02878 7.70581 7.23167 7.50057 7.48444 7.49805Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          </div>
          <div>
            <DropdownButton className="equipment-machine-dropdown ml-2  px-2" id="dropdown-item-button" drop={"up-centered"}>
              {/* <Dropdown.Item className="cursor font-size-14" onClick={() => handleOpenRepairRequestModal(row)}>
                Request For Repair
              </Dropdown.Item>
              <Dropdown.Item className="cursor font-size-14" onClick={() => handleShowWarrantySummary(row)}>
                Request For Warranty
              </Dropdown.Item> */}
              <Dropdown.Item
                className="cursor font-size-14"
                onClick={() => setShowRecommendedServiceModal(true)}
                // onClick={() => navigate("/search")}
              >
                Recommended Services
                {/* <NavLink to={"/search"}>Recommended Services</NavLink> */}
              </Dropdown.Item>
              <Dropdown.Item className="cursor font-size-14" onClick={() => navigate("/transactions")}>
                Service History
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      ),
    },
  ];

  const usageColumns = [
    {
      field: "currentUsage",
      headerName: "Current Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "averageUsage",
      headerName: "Average Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "updateDate",
      headerName: "Last Updated Date",
      flex: 1,
      minWidth: 120,
    },
    { field: "sensorId", headerName: "Sensor ID", flex: 1, minWidth: 120 },

    { field: "smuId", headerName: "SMU ID", flex: 1, minWidth: 120 },
    {
      field: "smuType",
      headerName: "SMU Type",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "maker",
      headerName: "Family",
      // name: "Manufacturer",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => row?.make || "CATERPILLER",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon onClick={() => handleEquipmentModal("usageTableModal", true)} />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4">
            <h4 className="font-weight-600 mt-4">Machines in Possession</h4>
            {/* <a className="btn bg-primary text-white" onClick={() => handleEquipmentModal("equipmentProfile", false)}>
              <span>
                <AddIcon />
              </span>
              Add Equipment
            </a> */}
          </div>
          <div className="bg-primary px-3 mb-3 border-radius-10 mt-3">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{ position: "relative", zIndex: 20 - i }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{ label: "And", value: "AND" }}
                                options={[
                                  { label: "And", value: "AND", id: i },
                                  { label: "OR", value: "OR", id: i },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                // isClearable={true}
                                options={equipmentSearchOptions}
                                onChange={(e) => handleSearchCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{ position: "relative" }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div className="btn bg-primary text-white" onClick={() => handleQuerySearchClick()}>
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions && obj.selectOptions.length > 0 && (
                                <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`} id="style">
                                  {obj.selectOptions.map((currentItem, j) => (
                                    <li className="list-group-item" key={j} onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                      {currentItem[obj.selectCategory.value]}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ))}
                        <div onClick={handleRefreshTable}>
                          <a className="btn-sm text-white border mr-2 cursor" style={{ border: "1px solid #872FF7" }}>
                            <Tooltip title="Reset">
                              <CachedIcon />
                            </Tooltip>
                          </a>
                        </div>
                        <div>
                          <Link to="#" className="btn-sm text-black border mr-2" style={{ border: "1px solid #872FF7" }}>
                            <span style={{ color: "white" }}>+</span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i className="fa fa-trash fa-lg" style={{ color: "white" }}></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn bg-white text-dark font-weight-500 mx-2" onClick={handleOpenEnrollEquipmentModal}>
                    Enroll Equipment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EquipmentMachinesTableList
            columns={updatedEquipmentColumns}
            rowsData={equipmentData} //data
            setEquipmentPage={setEquipmentPage}
            equipmentPage={equipmentPage}
            equipmentPerPage={equipmentPerPage}
            setEquipmentPerPage={setEquipmentPerPage}
            totalEquipmentRows={totalEquipmentRows}
            handleOpenRepairRequestModal={handleOpenRepairRequestModal}
            equpLoading={equpLoading}
          />
        </div>
      </div>
      {showUsageDetailsModal && (
        <Modal
          show={showUsageDetailsModal}
          onHide={() => {
            setShowUsageDetailsModal(false);
          }}
          size="xl"
        >
          <Modal.Body style={{ backgroundColor: "#f6f6f6" }}>
            <div className="d-flex align-items-center justify-content-between ">
              <h4 className="font-weight-600 mb-0">Usage Details</h4>
              <a className="btn bg-primary text-white" onClick={() => handleEquipmentModal("usageProfile", false)}>
                <span>
                  <AddIcon />
                </span>
                Add Usage
              </a>
            </div>
            {/* <DataGridContainer
            // rowsData={equipmentUsageData}
            rowsData={usageData}
            orderData={true}
            columns={usageColumns}
          /> */}

            <div className="mr-3">
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  marginTop: 3,
                  marginBottom: 5,
                  marginInline: 2,
                }}
              >
                <DataGrid rows={usageData} columns={usageColumns} sx={GRID_STYLE} rowsPerPageOptions={[10, 20, 50]} disableRowSelectionOnClick />
              </Box>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button className="btn btn-primary mx-2" onClick={() => setShowUsageDetailsModal(false)}>
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showModal && (
        <EquipmentQuoteAddEdit show={showModal} hideModal={() => setShowModal(false)} modalType={modalType} edit={edit} handleSnack={handleSnack} />
      )}
      {showClaimBackModal && (
        <ClaimDetails
          show={showClaimBackModal}
          hideModal={() => setShowClaimBackModal(false)}
          warranty={warranty}
          handleShowClaimDetails={handleShowClaimDetails}
          handleSnack={handleSnack}
        />
      )}

      {openWarrantySummaryModal && (
        <CustomerWarrantyModal
          show={openWarrantySummaryModal}
          hideModal={() => setOpenWarrantySummaryModal(false)}
          recordId={recordId}
          selectedRowDesc={selectedRowDesc}
          warranty={warranty}
          setWarranty={setWarranty}
          handleSnack={handleSnack}
          handleShowWarrantyDetails={handleShowWarrantyDetails}
        />
      )}

      {showWarrantyModal && (
        <WarrantyOverView
          show={showWarrantyModal}
          hideModal={() => setShowWarrantyModal(false)}
          recordId={recordId}
          warranty={warranty}
          handleSnack={handleSnack}
        />
      )}

      {showEquipmentModal && (
        <EquipmentDetailsModal
          show={showEquipmentModal}
          hideModal={() => setShowEquipmentModal(false)}
          selectedEquipmentDetails={selectedEquipmentDetails}
          recordId={equipmentId}
        />
      )}

      {openRepairRequestModal && <RepairRequest show={openRepairRequestModal} hideModal={handleCloseRepairRequestModal} handleSnack={handleSnack} />}

      {openFindServicesModal && <FindServices show={openFindServicesModal} hideModal={handleCloseFindServicesModal} handleSnack={handleSnack} />}

      {(openEquEnrollModal || openFileUploadModal) && (
        <EquipmentEnrollModal
          show={openEquEnrollModal}
          hideModal={() => setOpenEquEnrollModal(false)}
          handleSnack={handleSnack}
          equRecordId={equRecordId}
          setEquRecordId={setEquRecordId}
          equipmentRecord={equipmentCreateData}
          // openClaimRequestModal={openClaimRequestModal}
          // handleCloseRequestModal={handleOpenCloseEquEnrollModal}
          openFileUploadModal={openFileUploadModal}
          handleShowFileUploadModal={handleShowEnrollFileUploadModal}
          // handleViewGenerateWarrantyOverview={handleViewGenerateWarrantyOverview}
        />
      )}

      {showRecommendedServiceModal && (
        <RecommendedServices show={showRecommendedServiceModal} hideModal={() => setShowRecommendedServiceModal(false)} handleSnack={handleSnack} />
      )}
    </>
  );
};

export default EquipmentMaster;
