import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

import { Modal } from 'react-bootstrap'

const OrderPayment = ({ show, hideModal, formatter, cartParts, portfolioCarts }) => {
    const [expanded, setExpanded] = useState('paypal');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Modal show={show} centered size="lg">
            <Modal.Body>
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid xs={7}>
                        <h5>Payment Method</h5>
                        <Accordion square expanded={expanded === 'paypal'} onChange={handleChange('paypal')}>
                            <AccordionSummary className='d-flex justify-content-between align-items-center' aria-controls="panel1d-content" id="panel1d-header">
                                <Typography>Paypal</Typography>
                                <img src="https://images.crunchbase.com/image/upload/c_pad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1447476896/lcrdrlh1hfa0nbl9ycy3.png" width="30" />
                            </AccordionSummary>
                            <AccordionDetails>
                                <input type="text" className="form-control" placeholder="Paypal email" />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'creditCard'} onChange={handleChange('creditCard')}>
                            <AccordionSummary className='d-flex justify-content-between align-items-center' aria-controls="panel1d-content" id="panel1d-header" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                <Typography>Credit card</Typography>
                                <div className="icons">
                                    <img src="https://i.imgur.com/2ISgYja.png" width="30" />
                                    <img src="https://i.imgur.com/W1vtnOV.png" width="30" />
                                    <img src="https://i.imgur.com/35tC99g.png" width="30" />
                                    <img src="https://i.imgur.com/2ISgYja.png" width="30" />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="card-body payment-card-body">

                                    <span className="font-weight-normal card-text">Card Number</span>
                                    <div className="card-payment-input">

                                        <i className="fa fa-credit-card"></i>
                                        <input type="text" className="form-control" placeholder="0000 0000 0000 0000" />

                                    </div>

                                    <div className="row mt-3 mb-3">

                                        <div className="col-md-6">

                                            <span className="font-weight-normal card-text">Expiry Date</span>
                                            <div className="card-payment-input">

                                                <i className="fa fa-calendar"></i>
                                                <input type="text" className="form-control" placeholder="MM/YY" />

                                            </div>

                                        </div>


                                        <div className="col-md-6">

                                            <span className="font-weight-normal card-text">CVC/CVV</span>
                                            <div className="card-payment-input">

                                                <i className="fa fa-lock"></i>
                                                <input type="text" className="form-control" placeholder="000" />

                                            </div>

                                        </div>


                                    </div>

                                    <span className="text-muted certificate-text"><i className="fa fa-lock"></i> Your transaction is secured with ssl certificate</span>

                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid xs={5}>
                        <h5>Summary</h5>
                        <div className="card">
                            <div className="d-flex justify-content-between p-3">

                                <div className="d-flex flex-column">

                                    <span>Pro(Billed Monthly) <i className="fa fa-caret-down"></i></span>
                                    <a href="#" className="billing text-primary">Save 20% with annual billing</a>

                                </div>

                                <div className="mt-1">
                                    <sup className="super-price">{cartParts.length !== 0 && formatter.format(
                                        cartParts.reduce((sum, cartItem) => {
                                            return sum + cartItem.listPrice * cartItem.quantity;
                                        }, 0)
                                    )}
                                        {portfolioCarts.length !== 0 && formatter.format(
                                            portfolioCarts.reduce((sum, cartItem) => {
                                                return sum + cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
                                            }, 0)
                                        )}</sup>
                                    <span className="super-month">/Month</span>
                                </div>

                            </div>
                            <hr className="mt-0 line" />
                            <div className="p-3">

                                <div className="d-flex justify-content-between mb-2">

                                    <span>Refferal Bonouses</span>
                                    <span>-$2.00</span>

                                </div>

                                <div className="d-flex justify-content-between">

                                    <span>Vat <i className="fa fa-clock-o"></i></span>
                                    <span>-20%</span>

                                </div>


                            </div>
                            <hr className="mt-0 line" />
                            <div className="p-3 d-flex justify-content-between">

                                <div className="d-flex flex-column">

                                    <span>Today you pay(US Dollars)</span>
                                    <small>After 30 days $9.59</small>

                                </div>
                                <span>$0</span>



                            </div>
                            <div className='d-flex px-3'>
                                <a href="#">Have a promo code?</a>
                            </div>
                            <div className="p-3">
                                <button className="btn btn-primary btn-block free-button" onClick={hideModal}>Pay Now</button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Modal.Body>
        </Modal>
    )
}

export default OrderPayment