import React, { useState } from "react";
import { Box, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import searchIcon from "../assets/icons/svg/search.svg";
import { fetchCoverageSuggestions, fetchPortfolioCoverageSuggestions, fetchPortfolioSearch, getPortfolioSearch } from "../services/portfolioServices";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PortfolioConfig from "./PortfolioConfig";
import { CATEGORIES, SUPPORT_PLANS } from "./CONSTANTS";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import SendIcon from "@mui/icons-material/Send";
import PortfolioSearchResult from "./PortfolioSearchResult";
import SelectBox from "./common/SelectBox";
import { SOLUTION_PORTFOLIO_SEARCH_URL } from "../services/CONSTANTS";
import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";

export default function SearchCatalogue() {
  const [category, setCategory] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [supportPlan, setSupportPlan] = useState("");
  const [model, setModel] = useState("");
  const [family, setFamily] = React.useState("");
  const [prefix, setPrefix] = useState("");
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const [result, setResult] = useState([]);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [familySuggestions, setFamilySuggestions] = useState([]);
  const [prefixSuggestions, setPrefixSuggestions] = useState([]);
  const handleCategory = (event) => {
    let { value } = event.target;
    setCategory(value);
    if (value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "family",
      };
      fetchPortfolioCoverageSuggestions(`usageCategory:"${value}" AND visibleInCommerce:true`)
        .then((result) => {
          // console.log("result :::: ", result);
          if (result && result.length > 0) {
            let _family = new Set();
            result.forEach((item) => {
              if (item.visibleInCommerce) {
                if (item.coverages.length > 0) {
                  item.coverages.forEach((familyItem) => {
                    if (familyItem.family) {
                      _family.add(familyItem.family);
                    }
                  });
                }
              }
            });

            const uniqueFamilyArray = Array.from(_family);

            setFamilySuggestions(uniqueFamilyArray);
            setModelSuggestions([]);
            setPrefixSuggestions([]);
          } else {
            setFamilySuggestions([]);
            setModelSuggestions([]);
            setPrefixSuggestions([]);
          }
        })
        .catch((error) => handleSnack("error", "Error occurred while fetching family"));

      // fetchCoverageSuggestions(searchCriteria)
      //   .then((results) => {
      //     setFamilySuggestions(results.data.map((indFamily) => indFamily.value));
      //     setModelSuggestions([]);
      //     setPrefixSuggestions([]);
      //   })
      //   .catch((err) => handleSnack("error", "Error occurred while fetching family"));
    }
    setFamily("");
    setModel("");
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(value, "", "", "", "");
  };

  const handleChangeFamily = (event) => {
    console.log(event.target.value, category);
    let { value } = event.target;
    setFamily(value);
    if (category && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: category,
            operation: "eq",
          },
          {
            filterKey: "family",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "modelNo",
      };
      fetchPortfolioCoverageSuggestions(`usageCategory:"${category}" AND family:"${value}" AND visibleInCommerce:true`)
        .then((result) => {
          // console.log("result :::: ", result);
          if (result && result.length > 0) {
            let _model = new Set();
            result.forEach((item) => {
              if (item.visibleInCommerce) {
                if (item.coverages.length > 0) {
                  item.coverages.forEach((modelItem) => {
                    if (modelItem.modelNo) {
                      _model.add(modelItem.modelNo);
                    }
                  });
                }
              }
            });

            const uniqueModalArray = Array.from(_model);
            console.log("uniqueModalArray ::: ", uniqueModalArray);

            setModelSuggestions(uniqueModalArray);
            setPrefixSuggestions([]);
          } else {
            setModelSuggestions([]);
            setPrefixSuggestions([]);
          }
        })
        .catch((err) => handleSnack("error", "Error occurred while fetching model"));
      // fetchCoverageSuggestions(searchCriteria)
      //   .then((results) => {
      //     setModelSuggestions(results.data.map((indModel) => indModel.value));
      //     setPrefixSuggestions([]);
      //   })
      //   .catch((err) => handleSnack("error", "Error occurred while fetching model"));
    }
    setModel("");
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(category, "", value, "", "");
  };
  const handleChangeModel = (event) => {
    let { value } = event.target;
    setModel(value);
    if (category && family && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: category,
            operation: "eq",
          },
          {
            filterKey: "family",
            value: family,
            operation: "eq",
          },
          {
            filterKey: "modelNo",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "serialNumberPrefix",
      };
      fetchPortfolioCoverageSuggestions(`usageCategory:"${category}" AND family:"${family}" AND modelNo:"${value}" AND visibleInCommerce:true`)
        .then((result) => {
          // console.log("result :::: ", result);
          if (result && result.length > 0) {
            let _prefix = new Set();
            result.forEach((item) => {
              if (item.visibleInCommerce) {
                if (item.coverages.length > 0) {
                  item.coverages.forEach((prefixItem) => {
                    if (prefixItem.serialNumberPrefix) {
                      _prefix.add(prefixItem.serialNumberPrefix);
                    }
                  });
                }
              }
            });

            const uniquePrefixArray = Array.from(_prefix);

            setPrefixSuggestions(uniquePrefixArray);
          } else {
            setPrefixSuggestions([]);
          }
        })
        .catch((err) => handleSnack("error", "Error occurred while fetching prefix"));
      // fetchCoverageSuggestions(searchCriteria)
      //   .then((results) => {
      //     setPrefixSuggestions(results.data.map((indPrefix) => indPrefix.value));
      //   })
      //   .catch((err) => handleSnack("error", "Error occurred while fetching prefix"));
    }
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(category, value, family, "", "");
  };
  const handleChangePrefix = (event) => {
    let { value } = event.target;
    setPrefix(value);
    setSupportPlan("");
    onSearchButtonClick(category, model, family, value, "");
  };
  const handleChangeSupPlan = (event) => {
    let { value } = event.target;
    setSupportPlan(value);
    onSearchButtonClick(category, model, family, prefix, value);
  };

  async function onSearchButtonClick(cat, model, family, prefix, supportLevel) {
    // console.log(2, make, family, model, prefix)
    setResult([]);
    let body = {};
    body.searchCriteriaList = [];
    let rUrlEndPath = "";

    if (cat) {
      body.searchCriteriaList.push({
        filterKey: "usageCategory",
        value: cat,
        operation: "eq",
      });
      rUrlEndPath = rUrlEndPath + `usageCategory:"${cat}"`;
    }
    if (model) {
      body.searchCriteriaList.push({
        filterKey: "modelNo",
        value: model,
        operation: "eq",
      });
      rUrlEndPath = rUrlEndPath + ` AND modelNo:"${model}"`;
    }
    if (family) {
      body.searchCriteriaList.push({
        filterKey: "family",
        value: family,
        operation: "eq",
      });
      rUrlEndPath = rUrlEndPath + ` AND family:"${family}"`;
    }
    if (prefix) {
      body.searchCriteriaList.push({
        filterKey: "serialNumberPrefix",
        value: prefix,
        operation: "eq",
      });
      rUrlEndPath = rUrlEndPath + ` AND serialNumberPrefix:"${prefix}"`;
    }
    if (supportLevel) {
      body.searchCriteriaList.push({
        filterKey: "supportLevel",
        value: supportLevel,
        operation: "eq",
      });
      rUrlEndPath = rUrlEndPath + ` AND supportLevel:"${supportLevel}"`;
    }

    body.dataOption = "all";

    await fetchPortfolioSearch(rUrlEndPath + ` AND visibleInCommerce:true`)
      .then((data) => {
        if (data.length === 0) {
          handleSnack("info", "No matching result found!");
        } else {
          let visiblePortfolio = data.map((item) => item.visibleInCommerce  && item);
          if (visiblePortfolio.length === 0) {
            handleSnack("info", "No matching result found!");
          } else {
            setResult(visiblePortfolio);
          }
          // setTabValue(category);
        }
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while searching for portfolios!");
      });
    // await getPortfolioSearch(body)
    //   .then((data) => {
    //     if (data.data?.length === 0) {
    //       handleSnack("info", "No matching result found!");
    //     } else {
    //       setResult(data.data);
    //       // setTabValue(category);
    //     }
    //   })
    //   .catch((err) => {
    //     handleSnack("error", "Error occurred while searching for portfolios!");
    //   });
  }

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        {/* Recommended part */}
        <div className="container-fluid">
          <div className="card border pt-3 my-4">
            <Box className="px-3">
              <SelectBox label={"Category"} value={category} options={CATEGORIES} handleChange={handleCategory} size={280} searchService={true} />
              <SelectBox
                label={"Family"}
                value={family}
                options={familySuggestions}
                handleChange={handleChangeFamily}
                size={120}
                searchService={false}
              />
              <SelectBox label={"Model"} value={model} options={modelSuggestions} handleChange={handleChangeModel} size={120} searchService={false} />
              <SelectBox
                label={"Prefix"}
                value={prefix}
                options={prefixSuggestions}
                handleChange={handleChangePrefix}
                size={120}
                searchService={false}
              />
              <SelectBox
                label={"Support Plan"}
                value={supportPlan}
                options={SUPPORT_PLANS}
                handleChange={handleChangeSupPlan}
                size={150}
                searchService={true}
              />
            </Box>
            <div className="px-2">
              <Divider className="my-3" />
            </div>
            <div className="bg-blue-white-gradient">
              {!(selectedPortfolio && selectedPortfolio.portfolioId) ? (
                <PortfolioSearchResult result={result} setSelectedPortfolio={setSelectedPortfolio} />
              ) : (
                <PortfolioConfig
                  family={family}
                  model={model}
                  prefix={prefix}
                  selectedPortfolio={selectedPortfolio}
                  setSelectedPortfolio={setSelectedPortfolio}
                  handleSnack={handleSnack}
                  showBackbutton={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
