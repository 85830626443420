import React from "react";

import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CategoryIcon from "@mui/icons-material/Category";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { Modal } from "react-bootstrap";

const RecommendedServices = ({ show, hideModal, handleSnack }) => {
  return (
    <Modal show={show} onHide={hideModal} size="md" centered>
      <Modal.Body>
        <h4>Maintenance Schedule for System AP3000-SM</h4>
        <div className="d-flex py-2">
          <CategoryIcon />
          <div className="ml-2">
            <p className="mb-0">
              <b>Type</b>
            </p>
            <span>Preventive Maintenance</span>
          </div>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex py-2">
          <LocalOfferIcon />
          <div className="ml-2">
            <p className="mb-0">
              <b>TOTAL CONTRACT PRICE</b>
            </p>
            <span>$5685</span>
          </div>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex py-2">
          <CalendarTodayIcon />
          <div className="ml-2">
            <p className="mb-0">
              <b>TOTAL CONTRACT DURACTION</b>
            </p>
            <span>5 Years</span>
          </div>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex py-2">
          <NoteAltIcon />
          <div className="ml-2">
            <p className="mb-0">
              <b>DISCRIPTION</b>
            </p>
            <span>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam delectus minima nisi! Soluta ducimus distinctio eaque ratione, sed
              numquam delectus iure rerum impedit autem fugiat nostrum animi quisquam suscipit aut.
            </span>
          </div>
        </div>
        <button className="btn btn-primary w-100 mt-3" onClick={hideModal}>
          CLOSE
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default RecommendedServices;
