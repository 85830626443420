import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import Moment from "react-moment";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { GRID_STYLE } from "./common/constants";
import OrderProgressModal from "./OrderProgressModal";
import { callGetApi } from "../services/ApiCaller";
import { PARTS_ORDER_MASTER_URL, SEARCH_PARTS_ORDER_GET } from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import { currencyFormatter } from "./common/utils/currencyFormatter";

const OrderHistory = ({ auth = null, handleSnack }) => {
  const [viewPlacedOrder, setViewPlacedOrder] = useState(false);

  const [orderRecords, setOrderRecords] = useState([]);
  const [partsOrderRecordCount, setPartsOrderRecordCount] = useState(10);
  const [partsPageno, setPartsPageno] = useState(0);
  const [partsPageSize, setPartsPageSize] = useState(10);
  const [loading, seLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [cartId, setCartId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (auth) {
      getOrderHistoryRecords();
    }
  }, []);

  // get the parts order history records
  const getOrderHistoryRecords = async () => {
    seLoading(true);
    // const rUrl = `${SEARCH_PARTS_ORDER_GET}?search=customerId:${auth?.customerId} %26%26 status:PAID`;
    const rUrl = PARTS_ORDER_MASTER_URL + "?pageNumber=0&pageSize=20";
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartsOrderRecordCount(responseData.length);
          setOrderRecords(responseData);
          seLoading(false);
        } else {
          setPartsOrderRecordCount(0);
          setOrderRecords([]);
          seLoading(false);
        }
      },
      (error) => {
        setPartsOrderRecordCount(0);
        setOrderRecords([]);
        seLoading(false);
      }
    );
  };

  const orderHistoryColumns = [
    { field: "partsOrderName", headerName: "Order#", flex: 1, minWidth: 120, renderCell: ({ row }) => `#${row?.partsOrderName}` },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <span
        //   className={`dispatched-order-status ${
        //     params.value.toLowerCase() === "reviewed"
        //       ? "dispatch-reviewed-order"
        //       : params.value.toLowerCase() === "in revision"
        //       ? "dispatch-revision-order"
        //       : "dispatch-approved-order"
        //   }`}
        >
          PAID
          {/* {params.value} */}
        </span>
      ),
    },
    // {
    //   field: "cart",
    //   headerName: "Number of Parts",
    //   flex: 1,
    //   minWidth: 220,
    // },
    {
      field: "totalPrice",
      headerName: "Price",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => currencyFormatter.format(row?.totalPrice),
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => <Moment format="DD/MM/YYYY">{row.createdAt}</Moment>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleViewOrderDetails(row)}>
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // view the order details
  const handleViewOrderDetails = (row) => {
    // if (!row.cart) {
    //   handleSnack("error", "Something wrong.");
    //   return;
    // }
    setSelectedRow(row);
    setCartId(row.cart);
    setOrderId(row.partsOrderId);
    setViewPlacedOrder(true);
  };

  // close the Order details Modal
  const handleCloseOrderDetailsModal = () => {
    setViewPlacedOrder(false);
    setOrderId(null);
    setCartId(null);
  };

  return (
    <div style={{ minHeight: "884px" }}>
      <h4 className="font-weight-600 mb-3 mt-4">Orders</h4>
      <div className="my-4">
        <div className="d-flex  justify-content-between">
          <div className=" align-items-center m-0">
            <div className={`transaction-search-box d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
              <div className="transaction-search customize">
                <input
                  className="transaction-input-sleact pr-1"
                  type="text"
                  placeholder="Search Orders"
                  // value={inputRegion}
                  autoComplete="off"
                  style={{ width: "300px" }}
                  // onChange={handleDropDownSearchForRegion}
                />
                <div
                  className="btn bg-primary text-white cursor"
                  // onClick={handleSearchForRegion}
                >
                  <span className="mr-2">
                    <SearchIcon />
                  </span>
                  SEARCH
                </div>
                {
                  // <ul
                  //     className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                  //     id="style"
                  // >
                  //     {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 &&
                  //         (
                  //             <li className="list-group-item">
                  //                 No
                  //                 Result
                  //                 found
                  //             </li>
                  //         )}
                  //     {inputRegion.length !== 0 && searchedDropDownRegion.length !== 0 && searchedDropDownRegion.map((currentItem, j) =>
                  //     (
                  //         <li
                  //             className="list-group-item"
                  //             key={j}
                  //             onClick={() =>
                  //                 handleSelectDropdownItemForRegion(
                  //                     currentItem
                  //                 )
                  //             }
                  //         >
                  //             {
                  //                 currentItem["city"]
                  //             }
                  //         </li>
                  //     )
                  //     )}
                  // </ul>
                }
              </div>
            </div>
          </div>
          <div className="mr-3 my-2">
            <button className="btn transparent">
              <FilterListIcon /> Filters
            </button>
          </div>
        </div>
      </div>
      <DataGrid
        loading={loading}
        rows={orderRecords}
        columns={orderHistoryColumns}
        page={partsPageno}
        autoHeight
        pageSize={partsPageSize}
        sx={GRID_STYLE}
        onPageChange={(newPage) =>
          // handleClaimPaginationChange(newPage, pageSize)
          setPartsPageno(newPage)
        }
        onPageSizeChange={(newPageSize) => setPartsPageSize(newPageSize)}
        rowCount={partsOrderRecordCount}
        disableRowSelectionOnClick
        getRowId={(row) => row.partsOrderId}
        pagination
        rowsPerPageOptions={[5, 10, 15]}
      />
      {viewPlacedOrder && (
        <OrderProgressModal
          show={viewPlacedOrder}
          hideModal={handleCloseOrderDetailsModal}
          handleSnack={handleSnack}
          orderId={orderId}
          cartId={cartId}
          rowData={selectedRow}
          currencyFormatter={currencyFormatter}
        />
      )}
    </div>
  );
};

export default OrderHistory;
