import React, { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import { Select, MenuItem, CircularProgress, TextField, Tooltip, Autocomplete } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes, GridColDef } from "@mui/x-data-grid";

import { FileUploader } from "react-drag-drop-files";

import { GRID_STYLE } from "./common/constants";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import UploadFilesModal from "./warrantyManagement/UploadFilesModal";
import SavedPartListModal from "./quickOrder/SavedPartListModal";
import { sparePartSearch } from "../services/searchServices";
import SearchBox from "./common/SearchBox";
import CustomerWarrantyModal from "./CustomerWarrantyModal";
import BulkUploadModal from "./BulkUploadModal";
import QuickOrderAddPart from "./quickOrder/QuickOrderAddPart";

const fileTypes = ["JPG", "PNG", "GIF"];

const dummyQuickOrder = [
  {
    id: Math.floor(Math.random() * 9000) + 1000,
    partNumber: "J4M011AA001",
    quantity: "10",
    serialNumber: "1214641",
    itemNote: "Spindle Head",
    customerPartNumber: "",
  },
  {
    id: Math.floor(Math.random() * 9000) + 1000,
    partNumber: "Z261A3101001",
    quantity: "5",
    serialNumber: "2456174",
    itemNote: "O ring type 2",
    customerPartNumber: "O37562",
  },
  {
    id: Math.floor(Math.random() * 9000) + 1000,
    partNumber: "J4M011A1006",
    quantity: "1",
    serialNumber: "425416",
    itemNote: "",
    customerPartNumber: "",
  },
  {
    id: Math.floor(Math.random() * 9000) + 1000,
    partNumber: "Z457S1080400",
    quantity: "2",
    serialNumber: "456345",
    itemNote: "",
    customerPartNumber: "P363429",
  },
];

const QuickOrder = () => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [partsRecords, setPartsRecords] = useState([]);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openSavedPartListModal, setOpenSavedPartListModal] = useState(false);

  const [openAddPartModal, setOpenAddPartModal] = useState(false);

  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const handleOpenCustomerWarrantyModal = () => {
    setShowModal(true);
  };

  // close file upload modal
  const handleCloseFileUploadModal = () => {
    setOpenFileUploadModal(false);
  };

  // open saved parts modal
  const handleOpenSavedPartsModal = () => {
    setOpenSavedPartListModal(true);
  };

  // close saved parts modal
  const handleCloseSavedPartsModal = () => {
    setOpenSavedPartListModal(false);
  };

  // ! table functionality

  // change the row mode change edit or not
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // start row edit
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // stop row edit
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // edit row
  const handleEditClick = (partsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [partsId]: { mode: GridRowModes.Edit },
    });
  };

  // save the row changes
  const handleSaveClick = (partsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [partsId]: { mode: GridRowModes.View },
    });
  };

  // delete parts row
  const handleDeleteClick = (partsId) => () => {
    setPartsRecords(partsRecords.filter((row) => row.id !== partsId));
  };

  // cancel row changes
  const handleCancelClick = (partsId) => () => {
    const rowData = partsRecords.find((obj) => obj.id === partsId);
    setRowModesModel({
      ...rowModesModel,
      [partsId]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: true };
        setPartsRecords(partsRecords.map((row) => (row.id === updatedRow.id ? { ...updatedRow, isNew: undefined } : row)));
        resolve(updatedRow);
      }),
    [partsRecords]
  );

  const columns = [
    {
      field: "partNumber",
      headerName: "Item Number",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "itemNote",
      headerName: "Line Item Note",
      flex: 1,
      editable: true,
    },
    {
      field: "customerPartNumber",
      headerName: "Customer Part Number",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDeleteClick(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // open the Bulk order upload modal
  const handleOpenBulkOrderModal = () => {
    setOpenBulkUploadModal(true);
  };

  // open upload files modal
  const handleOpenFileUploadModal = () => {
    setOpenFileUploadModal(true);
    setOpenBulkUploadModal(false);
  };

  // upload Files Modal
  const handleUploadFile = () => {
    setOpenFileUploadModal(false);
    setShowTable(true);
    setPartsRecords([...partsRecords, ...dummyQuickOrder]);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
            <h4 className="font-weight-600 mt-4 mb-2">Quick Order</h4>
          </div>

          <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
            <div className="d-flex align-items-center quick-order-imp-div">
              <PublishIcon sx={{ transform: "rotate(180deg)", fontSize: "30px" }} />
              <a className="mx-2 cursor font-size-16" onClick={handleOpenBulkOrderModal}>
                Import parts from a spreadsheet (.csv, .xls, .xlsx)
              </a>
              <span className="font-size-16">|</span>
              <a className="ml-2 cursor font-size-16" onClick={handleOpenSavedPartsModal}>
                Import parts from saved list
              </a>
            </div>
            {showTable && (
              <button className="btn border-primary text-primary" onClick={() => setOpenAddPartModal(true)}>
                <AddIcon /> Add New Row
              </button>
            )}
          </div>

          {!showTable ? (
            <div className="">
              <div className="add-new-recod">
                <div>
                  <FontAwesomeIcon className="cloudupload" icon={faCloudUploadAlt} />
                  <div className="mt-3">
                    <button className="btn border-primary text-primary font-weight-500 " onClick={handleOpenBulkOrderModal}>
                      + Click Here to Upload Files
                    </button>
                  </div>
                  <p className="mt-3">
                    Single upload file should not be more than <br />
                    10MB. Only the .lgs, .lgsx file types are allowed
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <DataGrid
                sx={GRID_STYLE}
                getRowId={(row) => row.id}
                // getRowId={(row) => row.orderId}
                rows={partsRecords}
                autoHeight
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => console.log(error)}
                processRowUpdate={processRowUpdate}
                // // paginationMode="server"
                // // rowsPerPageOptions={[5, 10, 20]}
                // // pagination
              />
              <div className="row mt-4 align-items-center" style={{ justifyContent: "right" }}>
                <button className="btn border-primary mx-3">CLEAR ALL</button>
                <button className="btn bg-gray text-white border-primary mx-3" onClick={handleOpenCustomerWarrantyModal}>
                  ADD TO CART
                </button>
                <button className="btn bg-primary text-white mr-3">ADD TO CART AND CHECKOUT</button>
              </div>
            </>
          )}
        </div>
      </div>

      {openBulkUploadModal && (
        <BulkUploadModal show={openBulkUploadModal} hideModal={() => setOpenBulkUploadModal(false)} handleUploadFile={handleOpenFileUploadModal} />
      )}

      {openFileUploadModal && (
        <UploadFilesModal show={openFileUploadModal} hideModal={handleCloseFileUploadModal} handleUploadFile={handleUploadFile} uploadFile={true} />
      )}
      {openSavedPartListModal && <SavedPartListModal show={openSavedPartListModal} hideModal={handleCloseSavedPartsModal} />}

      {showModal && <CustomerWarrantyModal show={showModal} hideModal={() => setShowModal(false)} />}

      {openAddPartModal && (
        <QuickOrderAddPart
          show={openAddPartModal}
          hideModal={() => setOpenAddPartModal(false)}
          handleSnack={handleSnack}
          partsRecords={partsRecords}
          setPartsRecords={setPartsRecords}
        />
      )}
    </>
  );
};

export default QuickOrder;
