import React, { useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import { Box, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { Modal } from "react-bootstrap";
import { CATEGORIES, SUPPORT_PLANS } from "../CONSTANTS";
import { fetchCoverageSuggestions, getPortfolioSearch } from "../../services/portfolioServices";
import PortfolioSearchResult from "../PortfolioSearchResult";
import PortfolioConfig from "../PortfolioConfig";

const FindServices = ({ show, hideModal, handleSnack }) => {
  const [category, setCategory] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [supportPlan, setSupportPlan] = useState("");
  const [model, setModel] = useState("");
  const [family, setFamily] = React.useState("");
  const [prefix, setPrefix] = useState("");
  const [result, setResult] = useState([]);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [familySuggestions, setFamilySuggestions] = useState([]);
  const [prefixSuggestions, setPrefixSuggestions] = useState([]);

  // change category
  const handleCategory = (event) => {
    let { value } = event.target;
    setCategory(value);
    if (value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "family",
      };
      fetchCoverageSuggestions(searchCriteria)
        .then((results) => {
          setFamilySuggestions(results.data.map((indFamily) => indFamily.value));
          setModelSuggestions([]);
          setPrefixSuggestions([]);
        })
        .catch((err) => handleSnack("error", "Error occurred while fetching family"));
    }
    setFamily("");
    setModel("");
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(value, "", "", "", "");
  };

  // change family
  const handleChangeFamily = (event) => {
    console.log(event.target.value, category);
    let { value } = event.target;
    setFamily(value);
    if (category && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: category,
            operation: "eq",
          },
          {
            filterKey: "family",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "modelNo",
      };
      fetchCoverageSuggestions(searchCriteria)
        .then((results) => {
          setModelSuggestions(results.data.map((indModel) => indModel.value));
          setPrefixSuggestions([]);
        })
        .catch((err) => handleSnack("error", "Error occurred while fetching model"));
    }
    setModel("");
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(category, "", value, "", "");
  };

  // change model
  const handleChangeModel = (event) => {
    let { value } = event.target;
    setModel(value);
    if (category && family && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            filterKey: "usageCategory",
            value: category,
            operation: "eq",
          },
          {
            filterKey: "family",
            value: family,
            operation: "eq",
          },
          {
            filterKey: "modelNo",
            value: value,
            operation: "eq",
          },
        ],
        dataOption: "all",
        listItem: "serialNumberPrefix",
      };
      fetchCoverageSuggestions(searchCriteria)
        .then((results) => {
          setPrefixSuggestions(results.data.map((indPrefix) => indPrefix.value));
        })
        .catch((err) => handleSnack("error", "Error occurred while fetching prefix"));
    }
    setPrefix("");
    setSupportPlan("");
    onSearchButtonClick(category, value, family, "", "");
  };

  // change prefix
  const handleChangePrefix = (event) => {
    let { value } = event.target;
    setPrefix(value);
    setSupportPlan("");
    onSearchButtonClick(category, model, family, value, "");
  };

  // change support plan
  const handleChangeSupPlan = (event) => {
    let { value } = event.target;
    setSupportPlan(value);
    onSearchButtonClick(category, model, family, prefix, value);
  };

  async function onSearchButtonClick(cat, model, family, prefix, supportLevel) {
    setResult([]);
    let body = {};
    body.searchCriteriaList = [];
    if (cat) {
      body.searchCriteriaList.push({
        filterKey: "usageCategory",
        value: cat,
        operation: "eq",
      });
    }
    if (model) {
      body.searchCriteriaList.push({
        filterKey: "modelNo",
        value: model,
        operation: "eq",
      });
    }
    if (family) {
      body.searchCriteriaList.push({
        filterKey: "family",
        value: family,
        operation: "eq",
      });
    }
    if (prefix) {
      body.searchCriteriaList.push({
        filterKey: "serialNumberPrefix",
        value: prefix,
        operation: "eq",
      });
    }
    if (supportLevel) {
      body.searchCriteriaList.push({
        filterKey: "supportLevel",
        value: supportLevel,
        operation: "eq",
      });
    }

    body.dataOption = "all";

    await getPortfolioSearch(body)
      .then((data) => {
        if (data.data?.length === 0) {
          handleSnack("info", "No matching result found!");
        } else {
          setResult(data.data);
          // setTabValue(category);
        }
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while searching for portfolios!");
      });
  }

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <div className="card border px-3">
          <Card sx={{ my: 4, p: 3, borderRadius: 5 }} variant={"outlined"}>
            <FormControl variant="outlined" sx={{ minWidth: 280 }} size="small">
              <InputLabel>Category</InputLabel>
              <Select value={category} displayEmpty onChange={handleCategory} label="Category">
                {CATEGORIES.map((indCategory) => (
                  <MenuItem value={indCategory.value} sx={{ fontSize: 13, mx: 1 }}>
                    {indCategory.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ mx: 1, minWidth: 120 }} size="small">
              <InputLabel>Family</InputLabel>
              <Select label="Family" value={family} onChange={handleChangeFamily}>
                {familySuggestions && familySuggestions.length > 0 ? (
                  familySuggestions.map((indFamily) => (
                    <MenuItem value={indFamily} sx={{ fontSize: 13, mx: 1 }}>
                      {indFamily}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Options</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ mx: 1, minWidth: 120 }} size="small">
              <InputLabel>Model</InputLabel>
              <Select value={model} label={<div style={{ paddingBlock: "auto" }}>Model</div>} onChange={handleChangeModel}>
                {modelSuggestions && modelSuggestions.length > 0 ? (
                  modelSuggestions.map((indModel) => (
                    <MenuItem value={indModel} sx={{ fontSize: 13, mx: 1 }}>
                      {indModel}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Options</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ mx: 1, minWidth: 120 }} size="small">
              <InputLabel>Prefix</InputLabel>
              <Select value={prefix} label="Prefix" onChange={handleChangePrefix}>
                {prefixSuggestions && prefixSuggestions.length > 0 ? (
                  prefixSuggestions.map((indPrefix) => (
                    <MenuItem value={indPrefix} sx={{ fontSize: 13, mx: 1 }}>
                      {indPrefix}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Options</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ mx: 1, minWidth: 150 }} size="small">
              <InputLabel>Support Plan</InputLabel>
              <Select value={supportPlan} label={<div style={{ paddingBlock: "auto" }}>Support Plan</div>} onChange={handleChangeSupPlan}>
                {SUPPORT_PLANS.map((indSupportPlan) => (
                  <MenuItem value={indSupportPlan.value} sx={{ fontSize: 13, mx: 1 }}>
                    {indSupportPlan.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Card>

          {/* <Card sx={{ mb: 2, p: 3, borderRadius: 5 }} variant={"outlined"}> */}
          {!(selectedPortfolio && selectedPortfolio.portfolioId) ? (
            <PortfolioSearchResult result={result} setSelectedPortfolio={setSelectedPortfolio} />
          ) : (
            <PortfolioConfig
              family={family}
              model={model}
              prefix={prefix}
              selectedPortfolio={selectedPortfolio}
              setSelectedPortfolio={setSelectedPortfolio}
              handleSnack={handleSnack}
              showBackbutton={true}
            />
          )}
          {/* </Card> */}
          <div className="row my-3" style={{ justifyContent: "right" }}>
            <button className="btn btn-primary text-white mx-2" onClick={hideModal}>
              Save & Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FindServices;
