import React, { useContext, useEffect, useState } from "react";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { faSearch, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Grid, Typography, Tooltip } from "@mui/material";

import { PartsContext } from "../context/PartsContext";
import PartItemDetailsModal from "./PartItemDetailsModal";
import SearchBox from "./common/SearchBox";
import AddedToCartItemInfo from "./carts/AddedToCartItemInfo";
import PartCartItemDetails from "./carts/PartCartItemDetails";
import { callDeleteApi, callPostApi } from "../services/ApiCaller";
import { PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, PARTS_ADD_TO_CART_POST, PARTS_DELETE_PARTS_FROM_CART_DELETE } from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import { AuthContext } from "../context/AuthContext";
import { getCartParts } from "../services/partsServices";
import { partsSearch } from "../services/searchServices";
import { currencyFormatter } from "./common/utils/currencyFormatter";

const PartsCartItemsModal = ({ show, hideModal, partsData, handleSnack, expendedEquipmentRow = null, selectedComponentRow = null }) => {
  const { portfolioCarts, cartItems, setCartItems } = useContext(PartsContext);
  const { auth } = useContext(AuthContext);

  const [partsRecord, setPartsRecord] = useState([...partsData]);
  const [mouseDownDirection, setMouseDownDirection] = useState(null);
  const [selectedPartRow, setSelectedPartRow] = useState(null);
  const [openPartRow, setOpenPartRow] = useState(null);
  const [openCartItemDetialsModal, setOpenCartItemDetialsModal] = useState(false);
  const [openAddedCartInfoModal, setOpenAddedCartInfoModal] = useState(false);

  const [partSeachValue, setPartSeachValue] = useState("");
  const [searchPartsResult, setSearchPartsResult] = useState([]);
  const [noOptionsParts, setNoOptionsParts] = useState(false);

  const [searchParts, setSearchParts] = useState("");
  const [searchPartsRecords, setSearchPartsRecords] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (auth) {
      getCartParts(auth?.userId).then((res) => {
        const _partsRecord = partsData.map((row) => {
          if (res) {
            const rowData = res["parts"].find((obj) => obj.partNumber === row.partNumber);
            if (rowData) {
              return { ...row, quantity: rowData.quantity };
            } else {
              // return { ...row, quantity: 0 };
              return { ...row, quantity: row.quantity };
            }
          } else {
            // return { ...row, quantity: 0 };
            return { ...row, quantity: row.quantity };
          }
        });
        setPartsRecord(_partsRecord);
        setCartItems(res);
      });
    }
  }, [count, auth]);

  const DropdownIndicator = (props) => {
    return (
      <div {...props}>
        <FontAwesomeIcon icon={faSearch} size="lg" style={{ marginRight: "6px" }} />
      </div>
    );
  };

  // update cart quantity
  const updateCartValue = async (value, itemrow) => {
    if (cartItems) {
      const partsCartRes = cartItems["parts"];
      let row = partsCartRes.find((obj) => obj?.partNumber === itemrow?.partNumber);

      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
        const rObj = {
          // partsCartId: cartItems?.cartId,
          cartId: cartItems?.cartId,
          partsQuantity: {
            [String(row["partsId"])]: value,
          },
        };
        if (value == 0) {
          let dltRUrl = `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${row["partsId"]}`;
          callDeleteApi(null, dltRUrl, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("info", `Part Number ${itemrow?.partNumber} removed from cart`);
              setCount((pre) => pre + 1);
            }
          });
        } else {
          callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("success", `Part Number ${itemrow?.partNumber} updated into cart`);
              setCount((pre) => pre + 1);
            }
          });
        }
      } else {
        let _partRecords = [...partsRecord];
        let row = _partRecords.find((obj) => obj.id === itemrow.id);
        _partRecords = _partRecords.map((obj) => (obj.id === row.id ? { ...row, quantity: value } : obj));
        setPartsRecord(_partRecords);
      }
    } else {
      let _partRecords = [...partsRecord];
      let row = _partRecords.find((obj) => obj.id === itemrow.id);
      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
      }
      _partRecords = _partRecords.map((obj) => (obj.id === row.id ? { ...row, quantity: value } : obj));
      setPartsRecord(_partRecords);
    }
  };

  // cart increase-decrease
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row.quantity + (1 || 1);
        break;
      case "down":
        next = row.quantity - (1 || 1) >= 0 ? row.quantity - (1 || 1) : 0;
        break;
      default:
        next = row.quantity;
        break;
    }
    updateCartValue(next, row);
  };

  // add item into cart
  const handleAddToCartItem = async (row) => {
    if (!Number(row?.availability)) {
      handleSnack("info", `Part ${row.partNumber} is not available right now.`);
      return;
    }
    if (portfolioCarts.length !== 0) {
      handleSnack("info", "There are some services left in your cart");
      return;
    }

    if (row.quantity < 1) {
      handleSnack("info", "Quantity should be greater than 0");
      return;
    }

    if (cartItems) {
      const partCartItem = cartItems.parts;

      let partExistsInCart = cartItems["parts"].some((obj) => obj.partNumber === row.partNumber);
      if (partExistsInCart) {
        handleSnack("info", `Part ${row.partNumber} is already exits in your cart`);
        return;
      }
      handleAddPartIntoCart(row);
    } else {
      handleAddPartIntoCart(row);
    }
  };

  const handleAddPartIntoCart = async (row) => {
    let rUrl = `${PARTS_ADD_TO_CART_POST}`;
    let rObj = {
      partsQuantity: {
        [String(row.id)]: row?.quantity,
      },
    };
    if (cartItems) {
      rObj["partsCartId"] = cartItems?.cartId;
    }
    // if (cartItems) {
    //   rUrl = `${rUrl}parts_cart_id=${cartItems?.cartId}&`;
    // }
    // rUrl = `${rUrl}ids=${row?.id}`;
    await callPostApi(null, `${rUrl}`, rObj, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        handleSnack("success", "Part added into Cart successfully.");
        setCount(count + 1);
        // handleAddQuantityPart(responseData, row);
      }
    });
  };

  const handleAddQuantityPart = (responseData, row) => {
    const cartParts = responseData["parts"];
    const highestPartsId = Math.max(...cartParts.map((part) => part.partsId));

    const rObj = {
      // partsCartId: responseData.cartId,
      cartId: responseData.cartId,
      partsQuantity: {
        [String(highestPartsId)]: row?.quantity,
      },
    };

    callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
      if (response.status === API_SUCCESS) {
        handleSnack("success", "Part added into Cart successfully.");
        setCount(count + 1);
      }
    });
  };

  const handleOpenCartDetailsModal = (row) => {
    setSelectedPartRow(row.id);
    setOpenPartRow(row);
    setOpenCartItemDetialsModal(true);
  };

  const handleCloseCartDetailsModal = () => {
    setSelectedPartRow(null);
    setOpenCartItemDetialsModal(false);
  };

  // search parts
  const handleSearchParts = async (searchText) => {
    setSearchPartsResult([]);
    setPartSeachValue(searchText);
    if (searchText) {
      await partsSearch(`partNumber~${searchText}`)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchPartsResult(result);
            setNoOptionsParts(false);
          } else {
            setNoOptionsParts(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // select part from seach parts results
  const handleSelectParts = (type, currentItem) => {
    setPartSeachValue(currentItem.partNumber);
    setSearchPartsResult([]);
  };

  // search parts from search list
  const handleFilterPartsBySearch = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setSearchPartsRecords([]);
    }
    if (partsRecord.length !== 0) {
      const _filterPartsRecords = partsRecord.filter((obj) => obj.partNumber.toLowerCase().includes(value.toLowerCase()));
      setSearchPartsRecords(_filterPartsRecords);
    }
    setSearchParts(value);
  };

  // reset the parts search filter
  const handleResetSearchParts = () => {
    setSearchPartsRecords([]);
    setSearchParts("");
  };

  // card list part records
  const viewAllPartsCardRecords = (partRecords = []) => {
    return (
      partRecords.length !== 0 &&
      partRecords.map((row, i) => (
        <div className={`card border mb-2 part-cart-item py-0 mr-1 ${selectedPartRow === row?.id ? "select-part-cart" : ""}`} key={row.partNumber}>
          <div className="row py-0">
            <div className="col-lg-1 col-md-1 col-12 pr-0 py-0">
              <div className="d-flex justify-content-center align-items-center h-100 parts-index" style={{ borderRight: "1px solid #cccccc" }}>
                {i + 1}
              </div>
            </div>
            <div className="col-lg-11 col-md-11 col-12 pl-0 py-0">
              <div className="row py-0">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 cursor py-0 mt-1" onClick={() => handleOpenCartDetailsModal(row)}>
                  <div
                    className="d-flex justify-content-between align-items-center py-0 pl-2 pr-2 parts-info"
                    style={{
                      borderBottom: "1px solid #cccccc",
                    }}
                  >
                    <div className="py-0">
                      <Typography fontSize={13} fontWeight={500} className="part-title mb-0">
                        {row.partDescription}
                      </Typography>
                      <p className="my-0">{row.partNumber}</p>
                    </div>
                    <span className="pr-2" style={{ color: "red" }}>
                      {currencyFormatter.format(row.listPrice)}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-0">
                  <div className="d-flex justify-content-between align-items-center py-0 pl-2 pr-2">
                    <div className="py-0">
                      <Typography
                        fontSize={13}
                        // fontWeight={500}
                        className="mb-0"
                      >
                        {row.model}
                      </Typography>
                      <div className="d-flex align-items-center mt-0 mb-1">
                        <div className="d-flex align-items-center">
                          <span className={`${row.availability ? "green-dot" : "red-dot"} font-size-12`}></span>
                          <p className="mb-0 ml-1 font-size-12 font-weight-500">
                            {Number(row.availability) ? `Available (${row?.availability})` : "Inaccessible"}
                          </p>
                        </div>
                        {/* <div className="d-flex align-items-center mx-2 text-primary">
                          <Tooltip title={`Recommended ${row.required} quantities`}>
                            <InfoIcon />
                          </Tooltip>
                        </div> */}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center mr-1">
                          <Tooltip title={`Recommended ${row.required} quantities`}>
                            <InfoIcon />
                          </Tooltip>
                          {/* <span className={`yellow-dot font-size-12`}></span>
                          <p className="mb-0 ml-1 font-size-12 font-weight-500">Recommended {row.required}</p> */}
                        </div>
                        <div className="input-number mr-2">
                          <button
                            onClick={(e) => {
                              // handleButtonClick(e);
                              handleButtonChange("down", row);
                            }}
                            onMouseDown={() => setMouseDownDirection("down", row)}
                            onMouseOut={() => setMouseDownDirection(null)}
                            onMouseUp={() => setMouseDownDirection(null)}
                            disabled={row.quantity === 0}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            step={1}
                            value={row.quantity}
                            readOnly
                            // onChange={handleChange}
                          />
                          <button
                            onClick={(e) => {
                              // handleButtonClick(e);
                              handleButtonChange("up", row);
                            }}
                            onMouseDown={() => setMouseDownDirection("up", row)}
                            onMouseUp={() => setMouseDownDirection(null)}
                            onMouseOut={() => setMouseDownDirection(null)}
                            disabled={row.quantity === row?.availableItems}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <Tooltip
                        title={`${
                          cartItems && cartItems["parts"].some((obj) => obj.partNumber === row.partNumber) ? "Already Added to Cart" : "Add To Cart"
                        }`}
                      >
                        <AddShoppingCartIcon className="cursor" onClick={() => handleAddToCartItem(row)} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };

  // open added cart info modal
  const handleShowAddedCartModalInfo = () => {
    setOpenAddedCartInfoModal(true);
    setOpenCartItemDetialsModal(false);
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} centered size="xl">
        <Modal.Body>
          <div className="container-fluid py-1" style={{ backgroundColor: " #f8f3ff" }}>
            <h4 className="mt-2 mb-3">Search Parts</h4>
            <div className="row input-fields">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-14 font-weight-500 mb-1">SERIAL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10"
                    value={expendedEquipmentRow?.makerSerialNumber}
                    // value={selectedComponentRow?.makerSerialNumber}
                    // // value={expendedEquipmentRow?.equipmentNumber}
                    disabled
                    placeholder="Add by"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-14 font-weight-500 mb-1">MODEL</label>
                  <input
                    type="text"
                    className="form-control border-radius-10"
                    value={selectedComponentRow?.model}
                    //  value={expendedEquipmentRow?.model}
                    disabled
                    placeholder="Enduro"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-14 font-weight-500 mb-1">COMPONENT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10"
                    value={selectedComponentRow?.componentCode}
                    // value={expendedEquipmentRow?.componentCode}
                    disabled
                    placeholder="Enduro"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-14 font-weight-500 mb-1">SEARCH PARTS</label>
                  <SearchBox
                    value={partSeachValue}
                    onChange={(e) => handleSearchParts(e.target.value)}
                    type="partsSearch"
                    result={searchPartsResult}
                    onSelect={handleSelectParts}
                    noOptions={noOptionsParts}
                    placeholder="New Search"
                    extraStyle={true}
                  />
                  {/* <Select isClearable={true} placeholder="NEW SEARCH" style={{ borderColor: "#872ff7" }} components={{ DropdownIndicator }} /> */}
                </div>
              </div>
            </div>
            <div className="card px-3 py-2">
              <Grid container spacing={3} sx={{ width: "100%", marginBlock: 0 }}>
                <Grid item xs={6} sx={{ paddingX: 4 }}>
                  <Typography fontSize={14} fontWeight={500} sx={{ marginBottom: 2, marginLeft: 1.5 }}>
                    {/* SERVICECPQ@ FE 350 EDITION {new Date().getFullYear()} */}
                    {`${selectedComponentRow?.model}-${selectedComponentRow?.componentCode}-${selectedComponentRow?.description}`}
                  </Typography>
                  <img src="../../assets/images/part_machine.jpg" className="mt-4 px-3" style={{ height: "400px", width: "600px" }} />
                </Grid>
                <Grid item xs={6}>
                  <div class="card border py-2">
                    <div className="form-group pl-3 pr-2 w-100">
                      <div className="d-flex align-item-center">
                        <div className="customselectsearch w-100">
                          <div className="form-control-search ">
                            <span class="fa fa-search fa-lg cursor" />
                          </div>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            value={searchParts}
                            autoComplete="off"
                            onChange={handleFilterPartsBySearch}
                            placeholder={"Search Parts"}
                          />
                        </div>
                        <div className="d-flex align-items-baseline mx-2">
                          <a
                            className="btn text-primary border-gray px-2 cursor"
                            onClick={handleResetSearchParts}
                            style={{ border: "1px solid #872FF7" }}
                          >
                            <Tooltip title="Reset">
                              <CachedIcon />
                            </Tooltip>
                          </a>
                          <a className="btn border-gray text-primary cursor ml-2 px-2">
                            <Tooltip title="Download CSV ">
                              <FontAwesomeIcon className=" font-size-14 mx-1" icon={faFileAlt} />
                              {/* Download CSV */}
                            </Tooltip>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pl-3">
                      <div className="d-flex justify-content-between" style={{ color: "#000000" }}>
                        <div className="d-flex justify-content-between">
                          <Typography
                            fontSize={13}
                            fontWeight={400}
                            // sx={{ paddingLeft: 0.4 }}
                          >
                            S.No.
                          </Typography>
                          <Typography fontSize={13} fontWeight={400} sx={{ paddingLeft: 1.2 }}>
                            PART NAME & NUMBER
                          </Typography>
                        </div>
                        <Typography fontSize={13} fontWeight={400} sx={{ paddingRight: 3 }}>
                          PRICE
                        </Typography>
                      </div>
                      <div className="cart-items mt-2 px-0">
                        {searchParts && searchParts.length !== 0 ? viewAllPartsCardRecords(searchPartsRecords) : viewAllPartsCardRecords(partsRecord)}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          {openCartItemDetialsModal && (
            <PartCartItemDetails
              show={openCartItemDetialsModal}
              hideModal={handleCloseCartDetailsModal}
              partItem={openPartRow}
              cartItems={cartItems}
              setStaper={setCount}
              partsRecord={partsRecord}
              setPartsRecord={setPartsRecord}
              portfolioCarts={portfolioCarts}
              setMouseDownDirection={setMouseDownDirection}
              handleSnack={handleSnack}
              handleShowCartInfoModal={handleShowAddedCartModalInfo}
            />
          )}

          {openAddedCartInfoModal && (
            <AddedToCartItemInfo
              show={openAddedCartInfoModal}
              hideModal={() => setOpenAddedCartInfoModal(false)}
              recordId={selectedPartRow}
              partsRecord={partsRecord}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartsCartItemsModal;
