import { useEffect, useState } from "react";

import { Avatar, Button, Divider } from "@mui/material";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { UpdateAccount, getAccount } from "../services/accountService";
import { ReadOnlyField } from "./common/ReadOnlyField";
import { FONT_STYLE_SELECT } from "./common/constants";
import AddNewAddress from "./user/AddNewAddress";
import LoadingProgress from "./common/Loader";
import { useCallback } from "react";

export const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "#fff",
    fontSize: 12,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
};

const fleetSizeOptions = [
  { label: "Small", value: "SMALL" },
  { label: "Medium", value: "MEDIUM" },
  { label: "Large", value: "LARGE" },
  { label: "Very Large", value: "VERY_LARGE" },
];

const addressOptions = [
  {
    label: "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia",
    value: "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia",
  },
  // { label: "1 Alan St, Rydalmere NSW 2116, Australia", value: "1 Alan St, Rydalmere NSW 2116, Australia" },
  { label: "8501 Willow Avenue, Los Angeles, CA 90037", value: "8501 Willow Avenue, Los Angeles, CA 90037" },
  { label: "9653 Richardson Circle, Compton, CA 90221", value: "9653 Richardson Circle, Compton, CA 90221" },
];

export default function Profile(props) {
  const { auth } = props;
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [shippingAddresses, setShippingAddresses] = useState([...addressOptions]);
  const [billingAddresses, setBillingAddresses] = useState([...addressOptions]);

  const [addressType, setAddressType] = useState("");
  const [profile, setProfile] = useState({
    userId: auth?.userId,
    fullName: auth?.customerName,
    email: auth?.userEmail,
    primaryPhoneNumber: auth?.mobileNo || "",
    secondaryPhoneNumber: "",
    customerType: "",
    industry: "",
    fleetSize: fleetSizeOptions[0],
    billingAddress: "",
    shippingAddress: "",
    userAddressIds: [],
    tenantId: auth?.tenantId,
  });

  useEffect(() => {
    if (auth && auth["role"] && auth["role"].length > 0 && auth["role"].indexOf("End_Customer") !== -1) {
      fetchAccountDetails();
    } else {
      setEditing(true);
    }
  }, []);

  // fetch the user account detials|Account Details
  const fetchAccountDetails = async () => {
    await getAccount()
      .then((accountDetails) => {
        console.log("accountDetails :::::: ", accountDetails);
        const responseData = accountDetails["data"];

        let _billingAdresses = [...billingAddresses];
        let _shippingAdresses = [...shippingAddresses];

        if (responseData.billingAddress) {
          _billingAdresses.unshift({ label: responseData.billingAddress, value: responseData.billingAddress });
        }
        setBillingAddresses([..._billingAdresses]);

        if (responseData.shippingAddress) {
          _shippingAdresses.unshift({ label: responseData.shippingAddress, value: responseData.shippingAddress });
        }
        setShippingAddresses([..._shippingAdresses]);

        const _fleetSize = fleetSizeOptions.find((item) => item?.value === accountDetails["data"]?.fleetSize);

        if (accountDetails["data"]?.userAddressIds && accountDetails["data"]?.userAddressIds.length > 0) {
          getAddressList(accountDetails["data"]?.userAddressIds);
        }

        setProfile({
          ...accountDetails["data"],
          fleetSize: _fleetSize || fleetSizeOptions[0],
          email: auth?.userEmail,
          billingAddress: _billingAdresses[0],
          shippingAddress: _shippingAdresses[0],
        });
        // setProfile(accountDetails.data);
        setLoading(false);
      })
      .catch((err) => props.handleSnack("error", "Error occurred while fetching profile details"));
    setLoading(false);
  };

  // get the Address list by Ids
  const getAddressList = useCallback((addressIds) => {}, []);

  // change the customer|Profile input text change
  const handleCustomerDataChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    setProfile({ ...profile, [name]: value });
  };

  // change the customer|Profile Select value change
  const handleSelectChange = (e, keyName) => {
    setProfile({ ...profile, [keyName]: e });
  };

  // open address modal for Billing|Shipping Address
  const handleOpenNewAddressAddModal = (e) => {
    const { id } = e.target;
    setAddressType(id);
    props.handleOpenCloseAddressAddModal();
  };

  // update the account|Profile details
  const handleUpdateAccount = async () => {
    let data = {
      userId: profile?.userId,
      fullName: profile?.fullName,
      primaryPhoneNumber: profile?.primaryPhoneNumber,
      secondaryPhoneNumber: profile?.secondaryPhoneNumber,
      customerType: profile?.customerType,
      industry: profile?.industry,
      fleetSize: profile?.fleetSize?.value || profile?.fleetSize || "SMALL",
      tenantId: profile?.tenantId,
      shippingAddress: profile?.shippingAddress?.label || profile?.shippingAddress || "",
      billingAddress: profile?.billingAddress?.label || profile?.billingAddress || "",
    };

    await UpdateAccount(data)
      .then((updatedAccount) => {
        // fetchAccountDetails();
        props.handleSnack("success", "Profile details successfully updated!");
        setEditing(false);
      })
      .catch((e) => {
        props.handleSnack("error", "Error occurred while updating profile details");
      });
  };

  return loading ? (
    <LoadingProgress />
  ) : (
    <>
      {props.profileOpen && (
        <Modal show={props.profileOpen} onHide={props.handleProfileClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          {/* <Modal.Header className="h4">Profile</Modal.Header> */}
          <Modal.Body>
            <div className="d-flex justify-content-between align-item-center mb-2">
              <h4>Profile</h4>
              {!editing && (
                <Button className="btn bg-primary text-white" onClick={() => setEditing(true)}>
                  Edit
                </Button>
              )}
            </div>
            <Divider className="my-3 w-100" />
            <div className="card border px-3 pt-3 pb-0">
              {/* <h5>Profile</h5> */}
              {!editing ? (
                <div className="row align-items-end px-2 py-3 mb-3">
                  <div className="col-md-3 col-sm-3 px-5">
                    <Avatar alt="Profile Image" src="/static/images/avatar/1.jpg" sx={{ width: 80, height: 80, marginX: 5 }} />
                  </div>
                  <ReadOnlyField label="USER NAME" value={profile?.fullName} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                  <ReadOnlyField label="EMAIL" value={profile?.email} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                  <ReadOnlyField label="PASSWORD" value={"*******"} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                </div>
              ) : (
                <div className="row input-fields px- py-3 mb-3">
                  <div className="col-md-3 col-sm-3 px-5" style={{ justifyContent: "center" }}>
                    <Avatar alt="Profile Image" src="/static/images/avatar/1.jpg" sx={{ width: 80, height: 80, marginX: 5 }} />
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">USER NAME</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={profile?.fullName}
                        name="fullName"
                        placeholder="User Name"
                        onChange={handleCustomerDataChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={profile?.email}
                        name="email"
                        placeholder="Email"
                        onChange={handleCustomerDataChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">USER NAME</label>
                      <input
                        type="password"
                        className="form-control border-radius-10 text-primary"
                        value={profile?.password}
                        name="password"
                        placeholder="Password"
                        onChange={handleCustomerDataChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="card border px-2"></div> */}

              <h5>Account Settings</h5>
              <div className="card border px-2">
                {!editing ? (
                  <div className="row align-items-end px-3 py-3">
                    <ReadOnlyField label="ACCOUNT NO" value={profile?.accountNumber} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                    <ReadOnlyField label="ACCOUNT NAME" value={profile?.accountName} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                    <ReadOnlyField label="CONTACT NO" value={profile?.primaryPhoneNumber} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                    <ReadOnlyField label="ALTERNATE CONTACT" value={profile?.secondaryPhoneNumber} className="col-lg-3 col-md-3 col-sm-3 col-12" />
                  </div>
                ) : (
                  <div className="row input-fields px-3 py-3">
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ACCOUNT NO</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.accountNumber}
                          name="accountNumber"
                          placeholder="Account Number"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ACCOUNT NAME</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.accountName}
                          name="accountName"
                          placeholder="Account Name"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONTACT NO</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.primaryPhoneNumber}
                          name="primaryPhoneNumber"
                          placeholder="Primary Phone Number"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ALTERNATE CONTACT</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.secondaryPhoneNumber}
                          name="secondaryPhoneNumber"
                          placeholder="Secondary Phone Number"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <h5>Account Details</h5>
              <div className="card border px-2">
                {!editing ? (
                  <div className="row align-items-end px-3 py-3">
                    <ReadOnlyField label="CUSTOMER TYPE" value={profile?.customerType} className="col-lg-4 col-md-4 col-sm-4 col-12" />
                    <ReadOnlyField label="INDUSTRY" value={profile?.industry} className="col-lg-4 col-md-4 col-sm-4 col-12" />
                    <ReadOnlyField
                      label="FLEET SIZE"
                      value={profile?.fleetSize?.label || profile?.fleetSize}
                      className="col-lg-4 col-md-4 col-sm-4 col-12"
                    />
                  </div>
                ) : (
                  <div className="row input-fields px-3 py-3">
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER TYPE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.customerType}
                          name="customerType"
                          placeholder="Customer Type"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">INDUSTRY</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.industry}
                          name="industry"
                          placeholder="Industry"
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">FLEET SIZE</label>
                        <Select
                          onChange={(e) => handleSelectChange(e, "fleetSize")}
                          value={profile?.fleetSize}
                          options={fleetSizeOptions}
                          placeholder="Select..."
                          styles={FONT_STYLE_SELECT}
                        />
                        {/* <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={profile?.fleetSize}
                          name="fleetSize"
                          placeholder="Fleet Size"
                          onChange={handleCustomerDataChange}
                        /> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Shipping & Billing Address</h5>
                {editing && (
                  <button
                    className="btn border-primary text-primary"
                    // style={{ marginTop: "1.7rem" }}
                    id="shippingAddress"
                    onClick={handleOpenNewAddressAddModal}
                  >
                    Add New Address
                  </button>
                )}
              </div>
              <div className="card border px-2">
                {!editing ? (
                  <div className="row align-items-end px-3 py-3">
                    <ReadOnlyField
                      label="SHIPPING ADDRESS"
                      value={profile?.shippingAddress?.label}
                      className="col-lg-12 col-md-12 col-sm-12 col-12"
                    />
                    <ReadOnlyField label="BILLING ADDRESS" value={profile?.billingAddress?.label} className="col-lg-12 col-md-12 col-sm-12 col-12" />
                  </div>
                ) : (
                  <div className="row input-fields px-3 py-3">
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500 align-items-center">
                          SHIPPING ADDRESS
                          {/* <span className="mx-2 cursor font-size-16">
                            <Tooltip title="Add Shipping Address">
                              <AddLocationAltIcon />
                            </Tooltip>
                          </span> */}
                        </label>
                        <Select
                          onChange={(e) => handleSelectChange(e, "shippingAddress")}
                          value={profile?.shippingAddress}
                          options={addressOptions}
                          placeholder="Select..."
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500 align-items-center"></label>
                        <button
                          className="btn border-primary text-primary"
                          style={{ marginTop: "1.7rem" }}
                          id="shippingAddress"
                          onClick={handleOpenNewAddressAddModal}
                        >
                          Add Shipping Address
                        </button>
                      </div>
                    </div> */}
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">BILLING ADDRESS</label>
                        <Select
                          onChange={(e) => handleSelectChange(e, "billingAddress")}
                          value={profile?.billingAddress}
                          options={addressOptions}
                          placeholder="Select..."
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500 align-items-center"></label>
                        <button
                          className="btn border-primary text-primary"
                          style={{ marginTop: "1.7rem" }}
                          id="billingAddress"
                          onClick={handleOpenNewAddressAddModal}
                        >
                          Add Billing Address
                        </button>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="row px-3" style={{ justifyContent: "right" }}>
              {!editing ? (
                <Button className="btn bg-primary text-white" onClick={props.handleProfileClose}>
                  Close
                </Button>
              ) : (
                <Button className="btn bg-primary text-white" onClick={handleUpdateAccount}>
                  Save
                </Button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {props.openAddressAddModal && (
        <AddNewAddress
          show={props.openAddressAddModal}
          hideModal={props.handleOpenCloseAddressAddModal}
          handleSnack={props.handleSnack}
          billingAddresses={billingAddresses}
          setBillingAddresses={setBillingAddresses}
          shippingAddresses={shippingAddresses}
          setShippingAddresses={setShippingAddresses}
          addressType={addressType}
        />
      )}
      {/* {props.openAddressAddModal && addNewAddress()} */}
    </>
  );
}
